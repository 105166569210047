<template>
<button v-if="routeName" @click="$router.push(routeName)" class="btn">
	<span class="btn-span">{{ text }}</span>
	<RegisterIcon v-if="icon === 'RegisterIcon'" class="icon" />
	<LoginIcon v-if="icon === 'LoginIcon'" class="icon" />
	<FacebookIcon v-if="icon === 'FacebookIcon'" class="icon" />
	<GoogleIcon v-if="icon === 'GoogleIcon'" class="icon" />
	<EmailIcon v-if="icon === 'EmailIcon'" class="icon" />
	<ArrowRightIcon v-if="icon === 'ArrowRightIcon'" class="icon" />
	<HomeIcon v-if="icon === 'HomeIcon'" class="icon" />
	<StarEmptyIcon v-if="icon === 'StarEmptyIcon'" class="icon" />
	<StarIcon v-if="icon === 'StarIcon'" class="icon" />
	<TickIcon v-if="icon === 'TickIcon'" class="icon" />
	<AnchorIcon v-if="icon === 'AnchorIcon'" class="icon" />
	<CheckInIcon v-if="icon === 'CheckInIcon'" class="icon" />
	<ChatIcon v-if="icon === 'ChatIcon'" class="icon" />
	<SendIcon v-if="icon === 'SendIcon'" class="icon" />
	<ContactIcon v-if="icon === 'ContactIcon'" class="icon" />
	<DownloadIcon v-if="icon === 'DownloadIcon'" class="icon" />
	<ClockIcon v-if="icon === 'ClockIcon'" class="icon" />
	<LoadingIcon v-if="icon === 'LoadingIcon'" class="icon" />
	<KeyIcon v-if="icon === 'KeyIcon'" class="icon" />
	<ProfileIcon v-if="icon === 'ProfileIcon'" class="icon" />
	<PencilIcon v-if="icon === 'PencilIcon'" class="icon" />
	<PlusIcon v-if="icon === 'PlusIcon'" class="icon" />
	<PhoneIcon v-if="icon === 'PhoneIcon'" class="icon" />
	<CrossIcon v-if="icon === 'CrossIcon'" class="icon" style="width: 18px; margin-right: 3px;" />
</button>
<a v-else-if="href" :href="href" :target="newtab" class="btn">
    <span class="btn-span">{{ text }}</span>
    <RegisterIcon v-if="icon === 'RegisterIcon'" class="icon" />
    <LoginIcon v-if="icon === 'LoginIcon'" class="icon" />
    <FacebookIcon v-if="icon === 'FacebookIcon'" class="icon" />
    <GoogleIcon v-if="icon === 'GoogleIcon'" class="icon" />
    <EmailIcon v-if="icon === 'EmailIcon'" class="icon" />
    <ArrowRightIcon v-if="icon === 'ArrowRightIcon'" class="icon" />
    <StarEmptyIcon v-if="icon === 'StarEmptyIcon'" class="icon" />
    <StarIcon v-if="icon === 'StarIcon'" class="icon" />
    <TickIcon v-if="icon === 'TickIcon'" class="icon" />
    <AnchorIcon v-if="icon === 'AnchorIcon'" class="icon" />
    <CheckInIcon v-if="icon === 'CheckInIcon'" class="icon" />
    <ChatIcon v-if="icon === 'ChatIcon'" class="icon" />
    <SendIcon v-if="icon === 'SendIcon'" class="icon" />
    <ContactIcon v-if="icon === 'ContactIcon'" class="icon" />
    <DownloadIcon v-if="icon === 'DownloadIcon'" class="icon" />
	<ClockIcon v-if="icon === 'ClockIcon'" class="icon" />
	<LoadingIcon v-if="icon === 'LoadingIcon'" class="icon" />
	<KeyIcon v-if="icon === 'KeyIcon'" class="icon" />
	<ProfileIcon v-if="icon === 'ProfileIcon'" class="icon" />
	<PencilIcon v-if="icon === 'PencilIcon'" class="icon" />
	<PlusIcon v-if="icon === 'PlusIcon'" class="icon" />
	<PhoneIcon v-if="icon === 'PhoneIcon'" class="icon" />
	<CrossIcon v-if="icon === 'CrossIcon'" class="icon" style="width: 18px; margin-right: 3px;" />
</a>
<button v-else class="btn">
	<span class="btn-span">{{ text }}</span>
	<RegisterIcon v-if="icon === 'RegisterIcon'" class="icon" />
	<LoginIcon v-if="icon === 'LoginIcon'" class="icon" />
	<FacebookIcon v-if="icon === 'FacebookIcon'" class="icon" />
	<GoogleIcon v-if="icon === 'GoogleIcon'" class="icon" />
	<EmailIcon v-if="icon === 'EmailIcon'" class="icon" />
	<ArrowRightIcon v-if="icon === 'ArrowRightIcon'" class="icon" />
	<HomeIcon v-if="icon === 'HomeIcon'" class="icon" />
	<StarIcon v-if="icon === 'StarIcon'" class="icon" />
	<StarEmptyIcon v-if="icon === 'StarEmptyIcon'" class="icon" />
	<TickIcon v-if="icon === 'TickIcon'" class="icon" />
	<AnchorIcon v-if="icon === 'AnchorIcon'" class="icon" />
	<CheckInIcon v-if="icon === 'CheckInIcon'" class="icon" />
	<ChatIcon v-if="icon === 'ChatIcon'" class="icon" />
	<SendIcon v-if="icon === 'SendIcon'" class="icon" />
	<ContactIcon v-if="icon === 'ContactIcon'" class="icon" />
	<DownloadIcon v-if="icon === 'DownloadIcon'" class="icon" />
	<ClockIcon v-if="icon === 'ClockIcon'" class="icon" />
	<LoadingIcon v-if="icon === 'LoadingIcon'" class="icon" />
	<KeyIcon v-if="icon === 'KeyIcon'" class="icon" />
	<ProfileIcon v-if="icon === 'ProfileIcon'" class="icon" />
    <AppleIcon v-if="icon === 'AppleIcon'" class="icon icon-apple" />
    <PencilIcon v-if="icon === 'PencilIcon'" class="icon" />
    <PlusIcon v-if="icon === 'PlusIcon'" class="icon" />
    <PhoneIcon v-if="icon === 'PhoneIcon'" class="icon" />
    <CrossIcon v-if="icon === 'CrossIcon'" class="icon" style="width: 18px; margin-right: 3px;" />
</button>
</template>

<script>
import RegisterIcon from '@/assets/register.svg';
import LoginIcon from '@/assets/login.svg';
import FacebookIcon from '@/assets/facebook.svg';
import GoogleIcon from '@/assets/google.svg';
import EmailIcon from '@/assets/email.svg';
import ArrowRightIcon from '@/assets/arrow-right.svg';
import HomeIcon from '@/assets/home.svg';
import StarIcon from '@/assets/star-filled.svg';
import StarEmptyIcon from '@/assets/star.svg';
import TickIcon from '@/assets/tick.svg';
import AnchorIcon from '@/assets/anchor-white.svg';
import CheckInIcon from '@/assets/check-in.svg';
import ChatIcon from '@/assets/chat.svg';
import SendIcon from '@/assets/send.svg';
import ContactIcon from '@/assets/contact.svg';
import DownloadIcon from '@/assets/download.svg';
import ClockIcon from '@/assets/clock.svg';
import LoadingIcon from '@/assets/loading.svg';
import KeyIcon from '@/assets/key.svg';
import ProfileIcon from '@/assets/profile.svg';
import AppleIcon from '@/assets/apple.svg';
import PencilIcon from '@/assets/pencil.svg';
import PlusIcon from '@/assets/add.svg';
import PhoneIcon from '@/assets/phone.svg';
import CrossIcon from '@/assets/cross.svg';
export default {
	name: 'Button',
	props: {
		text: String,
		routeName: String,
		icon: String,
        href: String,
        newtab: String
	},
	components: {
		RegisterIcon,
		LoginIcon,
		FacebookIcon,
		GoogleIcon,
		EmailIcon,
		StarIcon,
		TickIcon,
		HomeIcon,
		KeyIcon,
		ArrowRightIcon,
		AnchorIcon,
		ChatIcon,
		SendIcon,
		ContactIcon,
		CheckInIcon,
		DownloadIcon,
		ClockIcon,
		LoadingIcon,
		ProfileIcon,
		StarEmptyIcon,
        AppleIcon,
        PencilIcon,
        PlusIcon,
        PhoneIcon,
        CrossIcon
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.btn {
		display: flex;
		cursor: pointer;
		box-shadow: none;
		height: 50px;
		width: 100%;
		border-radius: 12px;
		border: none;
		padding: 0 15px;
		line-height: 50px;
		font-size: 17px;
		font-weight: 600;
		color: $dark;
		margin-bottom: 15px;
		text-align: left;
		transition: 0.3s ease;
		.icon {
			overflow: visible;
		}
        .icon-apple {
            fill: white;
        }
		.btn-span {
			overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
			// line-height: 1.3em;
		}
		&.btn-icon {
			align-items: center;
			justify-content: space-between;
		}
		&.btn-icon-left {
			justify-content: flex-end;
			flex-direction: row-reverse;
			svg {
				margin-right: 12px;
			}
		}
		&.btn-icon-small {
			svg {
				width: 20px;
				height: 20px;
			}
		}
		&.icon-white {
			svg {
				g {
					stroke: $white;
					stroke-width: 3;
				}
			}
		}
		&.btn-icon-white {
			svg {
				path {
					stroke: $white;
					&.fill {
						stroke: none;
					}
					&.circle {
						fill: $white;
						stroke: none;
					}
				}
			}
		}
		&.btn-icon-right {
			text-align: left;
			overflow: hidden;
			text-overflow: ellipsis;
    		white-space: nowrap;
    		svg {
    			margin-left: 10px;
    		}
		}
		&.btn-square {
			height: 50px;
			line-height: 50px;
			border-radius: 4px;
		}
		&.btn-med {
			height: 54px;
			border-radius: 6px;
			line-height: 54px;
		}
		&.btn-large {
			height: 60px;
			line-height: 60px;
			border-radius: 33px;
			text-align: center;
			span {
				width: 100%;
			}
		}
		&.btn-last {
			margin-bottom: 0;
		}
		&.btn-green {
			background: $green_base;
			color: $white;
		}
		&.btn-light-green {
			background: $green_light;
			color: $green_dark;
			svg {
				path, g {
					stroke: $green_dark;
					stroke-width: 3;
				}
			}
		}
		&.btn-grey {
			background: $grey;
			color: $white;
		}
		&.btn-snow {
			background: $snow;
			color: $slate;
			svg {
				path {
					stroke: $slate;
				}
			}
		}
		&.btn-slate {
			background: $slate;
			color: $white;
		}
		&.btn-orange {
			background: $orange_base;
			color: $white;
		}
		&.btn-purple {
			background: $purple_base;
			color: $white;
		}
		&.btn-blue {
			background: $blue_base;
			color: $white;
			&.with-white {
				svg {
					path {
						stroke: $white;
						stroke-width: 3;
					}
				}
			}
		}
		&.btn-light-blue {
			background: $blue_light;
			color: $blue_dark;
			svg {
				path {
					stroke: $blue_dark;
					stroke-width: 3;
				}
			}
		}
        &.btn-red {
            background: $red_base;
            color: $white;
        }
		&.btn-facebook {
			background: $fb_blue;
			color: $white;
		}
		&.btn-google {
			background: $google_red;
			color: $white;
		}
        &.btn-apple {
            background: $apple_black;
            color: $white;
        }
		&.btn-home-white {
			svg {
				width: 19px;
				height: auto;
				path {
					stroke-width: 4;
					stroke: $white;
				}
			}
		}
		&.btn-register, &.btn-login, &.btn-facebook, &.btn-google, &.btn-email {
			svg {
				width: 22px;
				height: 21px;
			}
		}
		&.btn-tick {
			svg {
				path, circle {
					stroke-width: 3;
					stroke: $white;
				}
			}
		}
		&.btn-yellow-light {
			background: $yellow_light;
			color: $yellow_dark;
			svg {
				path, circle {
					stroke-width: 3;
					stroke: $yellow_dark;
				}
			}
		}
		&.btn-green-light {
			background: $green_light;
			color: $green_dark;
			svg {
				path, circle {
					stroke-width: 3;
					stroke: $green_dark;
				}
			}
		}
		&.btn-red-light {
			background: $red_light;
			color: $red_dark;
			svg {
				path, circle {
					stroke-width: 3;
					stroke: $red_dark;
				}
			}
		}
		&.btn-arrow {
			svg {
				width: 24px;
			}
		}
		&.btn-login {

		}
	}
</style>
