<template>
	<div class="home container container-full-height min">
		<span class="overlay" :class="{ active: showModal || showLoginModal || showForgotPasswordModal }"></span>
		<span class="gradient"></span>
		<div class="content-container">
			<div class="welcome-text">
				<img class="mission-logo" alt="The Mission to Seafarers" src="../assets/tmts.png" />
				<img class="happy-logo" alt="The Mission to Seafarers" src="../assets/happy-at-sea-logo.png" />
				<p>Free Port Welfare and Wellbeing Services from The Mission to Seafarers</p>
			</div><!-- welcome text -->
			<div class="welcome-funnels">
				<img class="seafarers-img" alt="The Mission to Seafarers" src="../assets/seafarers.png" />
				<div class="button-wrap">
					<Button routeName="/create-profile" icon="RegisterIcon" class="btn-green btn-icon btn-icon-right btn-register" text="Create a profile"></Button>
					<Button @click.native="modalActive" icon="LoginIcon" class="btn-icon btn-icon-right btn-login" text="I already have a profile"></Button>
					<router-link :to="{ name: 'Dashboard' }" class="text-link text-link-blue">Use the app without a profile &raquo;</router-link>
				</div>
			</div><!-- welcome funnels -->
		</div><!-- content container -->
		<div ref="modalLogin" class="phone-modal" :style="{ bottom: modalLogin + 'px'}" :class="{active: showModal}">
			<span class="modal-line"></span>
			<div class="modal-title-bar">
				<div class="icon-wrap">
					<LoginIcon style="width: 28px; height: 28px;" class="icon login-icon" />
					<span>Login</span>
				</div><!-- icon wrap -->
				<GreyCloseIcon @click="modalActive" style="width: 30px; height: 30px;" class="icon close" />
			</div><!-- modal title bar -->
			<Button @click.native="loginModalActive" icon="EmailIcon" class="btn-grey btn-med btn-icon btn-icon-left btn-email" text="Sign in with Email"></Button>
			<a @click="modalActive" class="text-link text-link-grey">I don't have a profile &raquo;</a>
		</div><!-- modal -->

		<div ref="modalEmail" class="phone-modal modal-email" :style="{ bottom: modalLoginHeight + 'px'}" :class="{active: showLoginModal}">
			<span class="modal-line"></span>
			<div class="modal-title-bar">
				<div class="icon-wrap">
					<EmailIcon style="width: 31px; height: 31px;" class="icon email-icon" />
					<span>Login with my Email Address</span>
				</div><!-- icon wrap -->
				<GreyCloseIcon @click="loginModalActive" style="width: 31px; height: 31px;" class="icon close" />
			</div><!-- modal title bar -->
			<form style="padding-top: 10px;" class="form" @submit.prevent>
				<div class="field field-icon" :class="{ 'field-error': $v.form.email.$error || errorStyling == true }">
					<label for="email-input" class="label">Email Address</label>
					<div class="control">
						<input id="email-input" class="input" type="email" placeholder="Enter your email address" v-model="form.email" enterkeyhint="next" inputmode="email" @keyup.enter="$refs.password_input.focus()" @keydown.enter.prevent>
						<EmailIcon style="width: 21px; height: 22px;" class="icon icon-email" />
					</div>
				</div>
				<div class="field field-gap field-icon" :class="{ 'field-error':$v.form.password.$error || errorStyling == true }">
					<label for="password-input" class="label">Password</label>
					<div class="control">
						<input id="password-input" ref="password_input" class="input" type="password" placeholder="Enter your password" v-model="form.password" enterkeyhint="done" @keyup.enter="loginUser">
						<KeyIcon style="width: 20px; height: 21px;" class="icon icon-key" />
					</div>
					<span id="forgot-password" @click="forgotPasswordModalActive" class="field-sub field-sub-red">Forgotten your password?</span>
				</div>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Incorrect email address or password.</span>
				</div>
				<Button icon="ArrowRightIcon" class="btn-med btn-green btn-icon btn-icon-right btn-arrow" text="Login with my Email Address" @click.native="loginUser"></Button>
			</form>
			<a @click="modalActive" class="text-link text-link-grey">I don't want to login with my email address &raquo;</a>
		</div><!-- modal -->

        <!-- Forgot password modal -->
        <div ref="modalForgotPassword" class="phone-modal modal-email" :style="{ bottom: modalForgotPasswordHeight + 'px' }" :class="{ active: showForgotPasswordModal }">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <EmailIcon style="width: 31px; height: 31px;" class="icon email-icon" />
                    <span>Enter Email Address</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="forgotPasswordModalActive" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <form style="padding-top: 10px;" class="form" @submit.prevent>
                <div class="field field-icon" :class="{ 'field-error': $v.resetForm.email.$error || errorStylingForgot == true }">
                    <label for="email-input" class="label">Email Address</label>
                    <div class="control">
                        <input id="email-input" class="input" type="email" placeholder="Enter your email address" v-model="resetForm.email" enterkeyhint="done" inputmode="email" @keydown.enter="forgotPassword">
                        <EmailIcon style="width: 21px; height: 22px;" class="icon icon-email" />
                    </div>
                </div>
                <div class="field error" v-if="errorMessageForgot">
                    <span class="field-error-message">{{ errorMessageForgot }}</span>
                </div>
                <div class="field success" v-if="successMessageForgot">
                    <span class="field-success-message">{{ successMessageForgot }}</span>
                </div>
                <Button v-if="forgotPasswordButton" icon="ArrowRightIcon" class="btn-med btn-green btn-icon btn-icon-right btn-arrow" text="Send Password Reset Email" @click.native="forgotPassword"></Button>
            </form>
            <a @click="forgotPasswordModalActive" class="text-link text-link-grey">Cancel &raquo;</a>
        </div><!-- modal -->
        <!-- /Forgot password modal -->
        <!-- 2 Factor modal -->
        <div ref="modalTwoFactor" class="phone-modal modal-email" style="bottom: -494px;" :class="{ active: show2faPrompt }">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <span>Two-step authentication</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="close2faPrompt" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="two-step-wrap">
            	<h3>Enter code</h3>
            	<p>Please check your email inbox for your two-factor authentication code and enter this below.</p>
            </div>
            <div class="otp-container">
			    <input
			        v-for="(digit, index) in otp"
			        :key="index"
			        type="text"
			        class="otp-input"
			        maxlength="1"
			        v-model="otp[index]"
			        inputmode="numeric"
			        @input="moveToNext($event, index)"
			        @keypress="isNumber($event)"
			        @paste="handlePaste($event)"
			        ref="otpInput"
			      />
			 </div>
			<Button icon="ArrowRightIcon" class="btn-med btn-green btn-icon btn-icon-right btn-arrow" text="Login" @click.native="submitOtp"></Button>
            <a @click="twoFactorModalActive" class="text-link text-link-grey">Didn't get the code? Click to resend &raquo;</a>
        </div><!-- modal -->
        <!-- 2 Factor -->
	</div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import LoginIcon from '@/assets/login.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import EmailIcon from '@/assets/email.svg';
import KeyIcon from '@/assets/key.svg';
import Button from '@/components/Button.vue';
// import WelcomeText from '@/components/WelcomeText.vue';
// import BaseInput from '@/components/BaseInput.vue';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
// import { SignInWithApple } from '@capacitor-community/apple-sign-in';

export default {
	name: 'Home',
	components: {
    	Button,
    	// WelcomeText,
    	// BaseInput,
    	GreyCloseIcon,
    	KeyIcon,
    	LoginIcon,
    	EmailIcon
	},
	data () {
		return {
			modalLogin: null,
			modalLoginHeight: null,
			showModal: false,
			showLoginModal: false,
			form: {
				email: '',
				password: ''
			},
            resetForm: {
                email: ''
            },
			errorMessage: null,
            errorMessageForgot: null,
            successMessageForgot: null,
			blankPassword: null,
			errorStyling: null,
            errorStylingForgot: false,
            showForgotPasswordModal: false,
            showTwoFactorModal: false,
            modalForgotPasswordHeight: null,
            forgotPasswordButton: true,
            otp: ['', '', '', '', '', '']
		};
	},
	validations: {
		form: {
			email: {
				required,
				email
			},
			password: {
				required
			}
		},
        resetForm: {
            email: {
                required,
                email
            }
        }
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalLogin.clientHeight;
		},
		matchHeightModalEmail () {
			return '-' + this.$refs.modalEmail.clientHeight;
		},
        matchHeightModalForgotPassword () {
            return '-' + this.$refs.modalForgotPassword.clientHeight;
        },
		show2faPrompt () {
			return this.$store.state.show2faPrompt;
		},
		get2faEmail () {
			return this.$store.state.email;
		}
	},
	watch: {
		show2faPrompt (newValue) {
			if (newValue) {
				this.showModal = false;
				this.showLoginModal = false;
			}
		}
	},
	methods: {
		modalActive () {
	    	this.showModal = !this.showModal;
	    	this.showLoginModal = false;
	    },
	    loginModalActive () {
	    	this.modalLoginHeight = '-' + this.$refs.modalEmail.clientHeight;
	    	this.showLoginModal = !this.showLoginModal;
	    	this.showModal = false;
	    },
	    close2faPrompt () {
	    	this.$store.commit('CLEAR_2FA');
	    },
        forgotPasswordModalActive () {
            this.showForgotPasswordModal = !this.showForgotPasswordModal;
            this.showLoginModal = false;
        },
        twoFactorModalActive () {
            this.showForgotPasswordModal = !this.showForgotPasswordModal;
            this.showLoginModal = false;
        },
        forgotPassword () {
            this.forgotPasswordButton = false;
            this.errorMessageForgot = null;
            this.errorStylingForgot = false;
            this.successMessageForgot = false;
            this.$v.resetForm.$touch();
            if (!this.$v.resetForm.$error) {
                AuthService.forgotPassword({
                    email: this.resetForm.email
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.errorMessageForgot = error.response.data.message;
                    }
                    if (error.response.data.email) {
                        this.errorMessageForgot = error.response.data.email;
                    }
                }).then((response) => {
                    if (response.data.status) {
                        this.successMessageForgot = response.data.status;
                    }
                }).finally(() => {
                    this.forgotPasswordButton = true;
                });
            } else {
                if (!this.$v.resetForm.email.$error) {
                    this.errorStylingForgot = true;
                }
                this.forgotPasswordButton = true;
            }
        },
        moveToNext (event, index) {
	      if (event.target.value.length === 1 && index < this.otp.length - 1) {
	        this.$refs.otpInput[index + 1].focus();
	      }
	    },
	    handlePaste (event) {
			event.preventDefault();
			const pasteData = event.clipboardData.getData('Text');
			if (/^\d+$/.test(pasteData) && pasteData.length <= this.otp.length) {
				pasteData.split('').forEach((char, idx) => {
					if (idx < this.otp.length) {
						this.$set(this.otp, idx, char);
					}
				});
				this.$refs.otpInput[this.otp.length - 1].focus();
			}
		},
	    isNumber (event) {
	      const charCode = event.which ? event.which : event.keyCode;
	      if (charCode < 48 || charCode > 57) {
	        event.preventDefault();
	      }
	    },
	    async submitOtp () {
	      	const otpCode = this.otp.join('');
	      	console.log('OTP Code Entered:', otpCode);
	      	this.errorMessage = false;
			this.errorStyling = false;
			const data = {
				email: this.get2faEmail,
				two_fa_code: otpCode
			};
			this.$v.form.$touch();
			try {
				await AuthService.login(data).then(() => {
					this.$store.commit('CLEAR_2FA');
					this.$router.push({ name: 'Dashboard' });
				});
			} catch (error) {
				this.errorMessage = true;
				this.errorStyling = true;
			}
	    },
	    async loginUser () {
			this.errorMessage = false;
			this.errorStyling = false;
			const data = {
            	email: this.form.email,
            	password: this.form.password
            };
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				if (this.$v.form.email.$error) {
				} else {
					this.errorMessage = true;
				}
			} else {
                try {
                    await AuthService.login(data).then((response) => {
						console.log(response);
						if (!response.includes('2FA')) {
                        	this.$router.push({ name: 'Dashboard' });
						}
                    });
                } catch (error) {
					console.log(error);
                    this.errorMessage = true;
                    this.errorStyling = true;
                }
			}
	    },
        oauth (provider) {
            return `${process.env.VUE_APP_API_URL}/auth/${provider}`;
        },
        async oauthCallback (provider, code) {
            await AuthService.oauth(provider, code).then(() => {
                this.$router.push({ name: 'Dashboard' });
            });
        }
        // async apple () {
        //     let token;
        //     let name;
        //     await SignInWithApple.authorize({
        //         clientId: 'org.missiontoseafarers.app',
        //         redirectURI: process.env.VUE_APP_URL,
        //         scopes: 'email name'
        //     }).then((response) => {
        //         token = response.response.identityToken;
        //         name = response.response.givenName + ' ' + response.response.familyName;
        //     }).catch((error) => {
        //         console.log(error);
        //     });
        //     if (token) {
        //         AuthService.apple({
        //             token: token,
        //             name: name
        //         }).then(() => {
        //             this.$router.push({ name: 'Dashboard' });
        //         }).catch((error) => {
        //             console.log(error);
        //         });
        //     }
        // }
	},
	mounted () {
        if (this.$route.query.provider && this.$route.query.code) {
            this.oauthCallback(this.$route.query.provider, this.$route.query.code);
        }
		if (this.$route.params.showLoginModal) {
			setTimeout(() => {
            	this.showLoginModal = true;
         	}, 100);
		}
		this.modalLogin = this.matchHeightModal;
		this.modalLoginHeight = this.matchHeightModalEmail;
        this.modalForgotPasswordHeight = this.matchHeightModalForgotPassword;
        if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
            Keyboard.setAccessoryBarVisible({
                isVisible: true
            });
            Keyboard.setResizeMode({
                mode: KeyboardResize.None
            });
            Keyboard.addListener('keyboardWillShow', (info) => {
                if (this.showLoginModal) {
                    this.$refs.modalEmail.style.marginBottom = `${info.keyboardHeight}px`;
                }
                if (this.showForgotPasswordModal) {
                    this.$refs.modalForgotPassword.style.marginBottom = `${info.keyboardHeight}px`;
                }
            });
            Keyboard.addListener('keyboardWillHide', () => {
                this.$refs.modalEmail.style.marginBottom = '0';
                this.$refs.modalForgotPassword.style.marginBottom = '0';
            });
        }
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_global.scss";
.gradient {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 30%;
    background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,145,255,1) 100%);
}
.two-step-wrap {
	margin-bottom: 0 0 50px;
	text-align: left;
	h3 {
		font-size: 20px;
		margin-bottom: 5px;
	}
}
/* OTP Input styling */
.otp-container {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin: 50px 0;
}

.otp-input {
	width: 50px;
	height: 50px;
	border: 2px solid #e2e8f0;
	border-radius: 6px;
	text-align: center;
	font-size: 24px;
	transition: border-color 0.3s ease;
	    font-family: "Inter", sans-serif;
    font-weight: 400;
    letter-spacing: -0.5px;
    font-size: 17px;
    line-height: 1.6em;
    color: #6D7A8B;
	@include max_width(400px) {
		width: 40px;
		height: 40px;
	}
}

.otp-input:focus {
	border-color: #6772e5;
	outline: none;
	box-shadow: 0 0 0 2px rgba(103, 114, 229, 0.3);
}
.welcome-text {
	position: relative;
	max-width: 320px;
	margin: 0 auto;
	padding-top: 40px;
	width: 100%;
	span.sub {
		font-size: 17px;
		font-weight: 600;
		color: #0A203D;
		display: block;
		margin-bottom: 18px;
	}
	.mission-logo {
		width: 100px;
    	display: block;
    	margin: 0 auto 35px;
	}
	.happy-logo {
		width: 200px;
		margin-bottom: 25px
	}
	h1 {
		margin: 0 0 10px;
		color: $white;
		font-size: 26px;
		line-height: 31px;
	}
	p {
		margin: 0;
		color: #0A203D;
		font-weight: 400;
		font-size: 17px;
	}
}
.content-wrap {
	position: relative;
	border-radius: 35px 35px 0 0;
	padding: 40px 28px 28px 28px;
	background: $white;
	width: 100%;
	transition: 0.5s ease;
	z-index: 100;
	flex: auto;
}
.container {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.container-full-height {
	height: 100vh;
	&.min {
		min-height: 100vh;
		height: auto;
	}
}
.content-container {
	display: flex;
	flex: auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px;
}
.welcome-funnels {
	position: relative;
	margin-top: 50px;
	.seafarers-img {
		position: relative;
	    width: 180px;
	    height: auto;
	    top: 30px;
	    z-index: 0;
	}
	.button-wrap {
		z-index: 5;
		position: relative;
	}
	.text {
		margin-bottom: 30px;
	}
	p {
		color: $white;
		margin: 0;
		strong {
			color: $white;
		}
	}
}
#forgot-password {
    cursor: pointer;
}
</style>
