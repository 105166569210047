<template>
	<div class="port-edit-staff">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isPortAdminModalOpen || isPortAdminNewModalOpen || isPortAdminConfirmationModalOpen }"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div>
		<div class="header-image blue"></div>
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="ProfileIcon"
				subtitle="Edit Port"
				title="Staff"
				colour="profile profile-blue"
			/>
			<div class="profile-blocks section-gap">
				<div v-for="portAdmin in portAdmins" :key="portAdmin.id" class="profile-block" @click="openPortAdminModal(portAdmin)">
					<div class="profile-block-info">
						<h3>{{ portAdmin.name }}</h3>
						<span class="sub">{{ portAdmin.email }}</span>
					</div>
					<ArrowRightIcon class="profile-block-arrow" />
				</div>
			</div>
			<Button @click.native="openPortAdminNewModal" icon="PlusIcon" class="none-bottom btn btn-med btn-orange btn-tick btn-square btn-icon btn-icon-right" text="Request a new port admin"></Button>
			<p class="align-left">Admins can be requested at any time but must be approved before they can make changes and view requests</p>
		</div>
		<PortAdminOverlay :portAdmin="selectedPortAdmin" />
		<PortAdminNewOverlay />
		<PortAdminConfirmationOverlay />
		<Menu />
	</div>
</template>

<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Button from '@/components/Button.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import PortService from '@/services/PortService';
    import ArrowRightIcon from '@/assets/arrow-right.svg';
    import PortAdminOverlay from '@/components/PortAdminOverlay.vue';
    import PortAdminNewOverlay from '@/components/PortAdminNewOverlay.vue';
    import PortAdminConfirmationOverlay from '@/components/PortAdminConfirmationOverlay.vue';
	export default {
		name: 'MyProfile',
		components: {
			Button,
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			ArrowRightIcon,
			PortAdminOverlay,
			PortAdminNewOverlay,
			PortAdminConfirmationOverlay
		},
		data () {
	        return {
	        	portAdmins: [],
	        	selectedPortAdmin: null
	        };
	    },
	    methods: {
            openPortAdminModal (portAdmin) {
	            this.selectedPortAdmin = portAdmin;
	            this.$store.commit('PORT_ADMIN_TOGGLE');
	        },
	        openPortAdminNewModal () {
	            this.$store.commit('PORT_ADMIN_NEW_TOGGLE');
	        },
			getPortAdmins () {
				PortService.getPortContactDetails(this.$route.params.id).then((response) => {
					this.portAdmins = response.data.port_admins;
				});
			}
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
	        isPortAdminModalOpen () {
	            return this.$store.state.isPortAdminModalOpen;
	        }
		},
		mounted () {
			this.getPortAdmins();
		}
	};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.profile-block-arrow {
		width: 20px;
		flex-shrink: 0;
		g {
			stroke: $black;
		}
	}
	.profile-blocks {
		.profile-block {
			display: flex;
			justify-content: space-between;
			text-align: left;
			border-radius: 12px;
			border: 1px solid $snow;
			box-shadow: 5px 15px 15px 5px $snow;
			padding: 20px;
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			h1, h3 {
				font-size: 17px;
				letter-spacing: -0.43px;
				line-height: 17px;
				color: $dark;
				margin-bottom: 5px;
			}
			.sub {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 16px;
				color: $slate;
				display: block;
			}
		}
		.profile-block-info {
			margin-right: 20px;
			overflow: hidden;
		}
		.profile-block-img {
			flex-shrink: 0;
			display: block;
			margin-right: 16px;
		}
	}
	.align-left {
		text-align: left;
	}
	.message-text {
		background: $sky_light;
		margin-top: 20px;
	    padding: 18px 17px 18px;
	    border-radius: 12px;
	    color: #445d72;
	    text-align: left;
	    p {
	    	color: $slate;
	    	font-size: 17px;
	    }
		.sub {
			font-weight: 600;
		}
		.text {

		}
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
</style>
