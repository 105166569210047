import Vue from 'vue';
import store from '@/store/index.js';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { App } from '@capacitor/app';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { hideWhenAuthed: true }
  },
  {
    path: '/create-profile',
    name: 'CreateProfile',
    component: () => import('../views/create-profile/CreateProfile.vue')
  },
  {
    path: '/create-profile/get-started',
    name: 'CreateProfileGetStarted',
    component: () => import('../views/create-profile/CreateProfileGetStarted.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('../views/resources/Resources.vue')
  },
  {
    path: '/resources/latest-resources',
    name: 'ResourcesLatest',
    component: () => import('../views/resources/ResourcesLatest.vue')
  },
  {
    path: '/resources/my-resources',
    name: 'ResourcesMy',
    component: () => import('../views/resources/ResourcesMy.vue'),
    meta: { requireProfile: true }
  },
  {
    path: '/resources/category/:id',
    name: 'ResourcesCategory',
    component: () => import('../views/resources/ResourcesCategory.vue')
  },
  {
    path: '/resources/media-type/:id',
    name: 'ResourcesMediaType',
    component: () => import('../views/resources/ResourcesMediaType.vue')
  },
  {
    path: '/resources/resource/:id',
    name: 'ResourcesSingle',
    component: () => import('../views/resources/ResourcesSingle.vue')
  },
  {
    path: '/resources/faqs',
    name: 'ResourcesFaqs',
    component: () => import('../views/resources/ResourcesFaqs.vue')
  },
  {
    path: '/ports',
    name: 'Ports',
    component: () => import('../views/Ports.vue')
  },
  {
    path: '/port/:id',
    name: 'Port',
    component: () => import('../views/Port.vue')
  },
  {
    path: '/edit-port/:id',
    name: 'EditPort',
    component: () => import('../views/ports/PortEdit.vue')
  },
  {
    path: '/edit-port/location-details/:id',
    name: 'EditPortLocationDetails',
    component: () => import('../views/ports/PortEditLocationDetails.vue')
  },
  {
    path: '/edit-port/contact-details/:id',
    name: 'EditPortContactDetails',
    component: () => import('../views/ports/PortEditContactDetails.vue')
  },
  {
    path: '/edit-port/response-times/:id',
    name: 'EditPortResponseTimes',
    component: () => import('../views/ports/PortEditResponseTimes.vue')
  },
  {
    path: '/edit-port/staff/:id',
    name: 'EditPortStaff',
    component: () => import('../views/ports/PortEditStaff.vue')
  },
  {
    path: '/edit-port/accepted-requests/:id',
    name: 'EditPortAcceptedRequests',
    component: () => import('../views/ports/PortEditAcceptedRequests.vue')
  },
  {
    path: '/my-ports',
    name: 'MyPorts',
    component: () => import('../views/MyPorts.vue'),
    meta: { requireProfile: true }
  },
  {
    path: '/our-ports',
    name: 'OurPorts',
    component: () => import('../views/OurPorts.vue')
  },
  {
    path: '/check-in',
    name: 'CheckIn',
    component: () => import('../views/check-in/CheckIn.vue'),
    meta: { requireProfile: true }
  },
  {
    path: '/check-in/visited-log',
    name: 'CheckInLog',
    component: () => import('../views/check-in/CheckInLog.vue')
  },
  {
    path: '/chat-to-a-chaplain',
    name: 'Chat',
    component: () => import('../views/Chat.vue')
  },
  {
    path: '/seaferers-happiness-index',
    name: 'HappyAtSea',
    component: () => import('../views/HappyAtSea.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'NewsPost',
    component: () => import('../views/NewsPost.vue')
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import('../views/my-profile/MyProfile.vue'),
    meta: { requireProfile: true }
  },
  {
    path: '/my-profile/personal-information',
    name: 'MyProfilePersonalInformation',
    component: () => import('../views/my-profile/MyProfilePersonalInformation.vue')
  },
  {
    path: '/my-profile/login-details',
    name: 'MyProfileLoginDetails',
    component: () => import('../views/my-profile/MyProfileLoginDetails.vue')
  },
  {
    path: '/my-profile/professional-information',
    name: 'MyProfileProfessionalInformation',
    component: () => import('../views/my-profile/MyProfileProfessionalInformation.vue')
  },
  {
    path: '/my-profile/notification-preferences',
    name: 'MyProfileNotificationPreferences',
    component: () => import('../views/my-profile/MyProfileNotificationPreferences.vue')
  },
  {
    path: '/my-profile/technical-support',
    name: 'MyProfileTechnicalSupport',
    component: () => import('../views/my-profile/MyProfileTechnicalSupport.vue')
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../views/requests/Requests.vue'),
    meta: { requireProfile: true }
  },
  {
    path: '/requests/request/:id',
    name: 'RequestsSingle',
    component: () => import('../views/requests/RequestsSingle.vue')
  },
  {
    path: '/requests/new/:id',
    name: 'RequestsCategory',
    component: () => import('../views/requests/RequestsCategory.vue')
  },
  {
    path: '/requests/new',
    name: 'RequestsNew',
    component: () => import('../views/requests/RequestsNew.vue')
  },
  {
    path: '/requests/open',
    name: 'RequestsOpen',
    component: () => import('../views/requests/RequestsOpen.vue')
  },
  {
    path: '/requests/complete',
    name: 'RequestsComplete',
    component: () => import('../views/requests/RequestsComplete.vue')
  },
  {
    path: '/requests/all',
    name: 'RequestsAll',
    component: () => import('../views/requests/RequestsAll.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/qr',
    name: 'QR',
    component: () => import('../views/QR.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  }
  // {
  //   path: '/admin/dashboard',
  //   name: 'AdminDashboard',
  //   component: () => import('../views/admin/AdminDashboard.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView();
  }
});

router.beforeEach(async (to, from, next) => {
  store.state.isMenuOpen = false;
  store.state.isPortSearchModalOpen = false;
  store.state.isAccessModalOpen = false;
  store.state.isUpdateProfileModalOpen = false;
  store.state.isPortContactModalOpen = false;
  store.state.isPortCheckInConfirmModalOpen = false;
  if (store.getters.isAuthenticated && to.meta.hideWhenAuthed) {
    return next({
      path: '/dashboard'
    });
  }
  if (!store.getters.isAuthenticated && to.meta.requireProfile) {
    store.state.isAccessModalOpen = true;
  } else {
    return next();
  }
});

App.addListener('appUrlOpen', async function (event) {
  const slug = event.url.split('.org').pop();
  if (slug) {
    await router.push({
      path: slug
    });
  }
});

export default router;
