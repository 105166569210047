<template>
	<div class="port-list-block">
    	<router-link :to="{ name: 'Port', params: { id: id, responseTime: responseTime, responseTimeShopping: responseTimeShopping } }" class="port-list-link" @click.native="$store.state.requestPort = { id: id, name: port, responseTime: responseTime, responseTimeShopping: responseTimeShopping };">
    		<div class="port-list-content">
    			<div class="port-list-img">
    				<country-flag :country="countryCode ? countryCode : ''" size="normal" />
    			</div>
    			<div class="port-list-text">
    				<h3>{{ port }}</h3>
    				<span>{{ country }}</span>
    			</div>
    		</div>
    		<span class="port-list-date">{{ date }}</span>
    	</router-link>
    </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
export default {
	name: 'Card',
	props: {
        id: Number,
        responseTime: String,
        responseTimeShopping: String,
		port: String,
		country: String,
		date: String,
		img: String,
		url: String,
        countryCode: String
	},
	components: {
		CountryFlag
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-list-block {
		text-align: left;
		&:first-of-type {
			.port-list-link {
				padding-top: 0;
			}
		}
		&:last-of-type {
			.port-list-link {
				padding-bottom: 0;
				border-bottom: 0;
			}
		}
		.port-list-link {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $snow;
			padding: 10px 0;
		}
		.port-list-content {
			display: flex;
		}
		.port-list-img {
			width: 50px;
			height: 50px;
			position: relative;
			overflow: hidden;
			border-radius: 12px;
			margin-right: 15px;
			flex-shrink: 0;
			display: flex;
		    justify-content: center;
		    align-items: center;
			border: 1px solid #e5e5e5;
			.flag {
				margin: 0;
				flex-shrink: 0;
			}
		}
		.port-list-date {
			font-size: 10px;
			text-transform: uppercase;
			letter-spacing: 0.83px;
			line-height: 12px;
			color: $grey;
			font-weight: $weight_500;
			opacity: 0.8;
			margin-top: 8px;
		}
		.port-list-text {
			display: flex;
		    flex-direction: column;
		    justify-content: center;
			h3 {
				font-size: 17px;
				letter-spacing: -0.5px;
				line-height: 20px;
				color: $dark;
				margin-bottom: 2px;
			}
			span {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 17px;
				color: $mid;
				display: block;
			}
		}
	}
</style>
