<template>
	<div class="requests">
		<Nav />
		<span class="overlay fixed" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image green"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="PencilIcon"
				title="Make a new Request"
				colour="green"
			/>
			<div class="section-gap">
				<div class="request-block-wrap-big">
					<RequestBlock
						category="Shopping"
						title="Request Shopping"
                        slug="shopping"
					/>
					<RequestBlock
						category="Visit"
						title="Request a welfare visit"
                        slug="visit"
					/>
					<RequestBlock
						category="Contact"
						title="Help contact a loved one"
                        slug="contact"
					/>
					<RequestBlock
						category="Transport"
						title="Request Transport"
                        slug="transport"
					/>
					<RequestBlock
						category="Justice & Welfare"
						title="Request intervention in welfare issue"
                        slug="justice-welfare"
					/>
					<RequestBlock
						category="Other"
						title="Make a general request"
                        slug="other"
					/>
				</div>
			</div>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import RequestBlock from '@/components/RequestBlock.vue';
	import PortService from '../../services/PortService';
	export default {
		name: 'RequestsNew',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			RequestBlock
		},
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		methods: {
		},
		mounted () {
			this.getEnabledPortRequestTypes();
		}
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.request-block-wrap-big {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.request-block {
			width: 47.5%;
			margin-right: 0;
			margin-bottom: 5%;
			height: 175px;
		}
	}
	.overlay.fixed {
		position: fixed;
	}
</style>
