<template>
<div ref="modalPortAdmin" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortAdminModalOpen ? 1 : 0, bottom: modalPortAdminHeight + 'px'}" :class="{active: isPortAdminModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<ProfileIcon style="width: 30px; height: auto;" class="icon" />
			<span>Staff Member</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortAdminModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="section-gap port-admin-details">
		<div class="col" v-if="portAdmin">
			<span class="eyebrow">Name</span>
			<p class="p-strong">{{ portAdmin.name }}</p>
		</div>
		<div class="col" v-if="portAdmin">
			<span class="eyebrow">Email Address</span>
			<p class="p-strong">{{ portAdmin.email }}</p>
		</div>
		<div class="col" v-if="portAdmin">
			<span class="eyebrow">Date created</span>
			<p class="p-strong">{{ portAdmin.created_at }}</p>
		</div>
	</div>
</div><!-- modal -->
</template>
<script>
import GreyCloseIcon from '@/assets/close-grey.svg';
import ProfileIcon from '@/assets/profile.svg';
export default {
	name: 'PortAdminOverlay',
	props: {
		portAdmin: {
			type: Object,
			required: false,
			default: null
		}
	},
	data () {
		return {
			modalPortAdminHeight: null
		};
	},
	components: {
		GreyCloseIcon,
		ProfileIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortAdmin.clientHeight;
		},
        isPortAdminModalOpen () {
            return this.$store.state.isPortAdminModalOpen;
        }
	},
	methods: {
		closePortAdminModal () {
			this.$store.commit('PORT_ADMIN_TOGGLE');
		}
	},
	mounted () {
		this.modalPortAdminHeight = '-500';
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-admin-details {
		text-align: left;
		.col {
			margin-bottom: 20px;
		}
		.p-strong {
			color: $dark;
			font-weight: 500;
		}
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $dark;
				}
			}
			span {
				color: $dark;
			}
		}
		.close {
			g, path {
				stroke-width: 2.5;
				stroke: $dark;
			}
		}
	}
</style>
