<template>
	<div class="requests">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>

		<div v-if="isPortAdmin">
			<div class="header-wrap">
				<div class="header header-standard">
					<HeaderNavBar back />
					<div class="request-header">
						<h1>Requests</h1>
					</div>
					<div class="toggle">
						<div :class="['option', { active: isOpen == 'open' }]" @click="toggle('open')">
							<span>New</span>
							<span class="count">{{ openRequests.length }}</span>
						</div>
						<div class="mid" :class="['option', { active: isOpen == 'assigned' }]" @click="toggle('assigned')">
							<span>Assigned</span>
							<span class="count">{{ assignedRequests.length }}</span>
						</div>
						<div :class="['option', { active: isOpen == 'completed' }]" @click="toggle('completed')">
							<span>Completed</span>
							<span class="count">{{ completedRequests.length }}</span>
						</div>
				    </div>
				    <p v-if="!isOpen" class="completed-text">Complete requests will only show here for the next 2 weeks.</p>
				</div><!-- header -->
			</div>
			<div class="pa-requests" v-if="isOpen == 'open'">
				<RequestCardPortAdmin
					v-for="request in this.openRequests"
					:key="request.id"
					:id="request.id"
					:title="request.category"
					:category="request.category"
					:port="request.port_name"
					:country="request.country"
					:date="request.date"
					:content="request.message"
					:routeName="'RequestsSingle'"
				/>

			</div>
			<div class="pa-requests" v-else-if="isOpen == 'assigned'">
				<RequestCardPortAdmin
					v-for="request in assignedRequests"
					:key="request.id"
					assigned
					:title="request.category"
					:category="request.category"
					:port="request.port_name"
					:country="request.country"
					:date="request.date"
					:content="request.message"
					:id="request.id"
					:assignable_name="request.assignable_name"
					:routeName="'RequestsSingle'"
				/>
			</div>
			<div class="pa-requests" v-else-if="isOpen == 'completed'">
				<RequestCardPortAdmin
					v-for="request in completedRequests"
					:key="request.id"
					complete
					:title="request.category"
					:category="request.category"
					:port="request.port_name"
					:country="request.country"
					:date="request.date"
					:content="request.message"
					:id="request.id"
					:assignable_name="request.assignable_name"
					:routeName="'RequestsSingle'"
				/>
			</div>
		</div>

		<div v-else>
			<div class="header header-standard">
				<HeaderNavBar back pencil />
			</div><!-- header -->
			<div class="header-image green"></div><!-- header image -->
			<div class="content-wrap content-wrap-alt content-wrap-nav">
				<SectionTitle
					icon="SendIcon"
					title="Requests"
					colour="green"
				/>
				<div class="section-gap section-gap-extra">
					<div class="section-title-wrap">
						<div class="icon-split">
							<h2 class="section-title">Open Requests</h2>
							<span>{{ count }}</span>
						</div>
					</div>
					<router-link :to="{ name: 'RequestsOpen' }" class="request-card-wrap">
						<RequestCard
	                        v-for="(request, index) in requests"
	                        :key="index"
							:category="request.category"
							:title="request.category"
							:content="request.messages.length ? request.messages[0].content : request.message"
							:location="request.port_name"
							:messageCount="request.messages.length"
							:time="getTimeAgo(request.updated_at)"
							unread
						/>
					</router-link>
				</div>
				<div class="section-gap card-wrap">
					<Card
						routeName="RequestsComplete"
						class="card-block-cyan-filled"
						icon="TickIcon"
						title="Completed Requests"
						text="View previous requests that have been fulfilled"
					/>
					<Card
						routeName="RequestsNew"
						class="card-block-green-filled"
						icon="PencilIcon"
						title="Make a new Request"
						text="Get help from our in-port team"
					/>
				</div><!-- card wrap -->
				<div class="section-gap section-request-tags">
					<div class="section-title-wrap">
						<h2 class="section-title">Common Requests</h2>
						<router-link :to="{ name: 'RequestsNew' }" class="page-link page-link-grey">View more &raquo;</router-link>
					</div>
					<div class="request-block-wrap" v-dragscroll>
						<RequestBlock
							category="Shopping"
							title="Request Shopping"
	                        slug="shopping"
						/>
						<RequestBlock
							category="Visit"
							title="Request a welfare visit"
	                        slug="visit"
						/>
						<RequestBlock
							category="Contact"
							title="Help contact a loved one"
	                        slug="contact"
						/>
						<RequestBlock
							category="Transport"
							title="Request Transport"
	                        slug="transport"
						/>
						<RequestBlock
							category="Justice & Welfare"
							title="Request intervention in welfare issue"
	                        slug="justice-welfare"
						/>
						<RequestBlock
							category="Other"
							title="Make a general request"
	                        slug="other"
						/>
					</div><!-- port card wrap -->
				</div>
				<div class="section-gap card-wrap">
					<Card
						routeName="RequestsAll"
						class="card-block-dark-grey card-block-full"
						icon="SendIcon"
						category="Requests"
						title="My current requests"
					/>
				</div><!-- card wrap -->
			</div>
		</div>

		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import Card from '@/components/Card.vue';
	import RequestCard from '@/components/RequestCard.vue';
	import RequestCardPortAdmin from '@/components/RequestCardPortAdmin.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import RequestBlock from '@/components/RequestBlock.vue';
    import PortAdminService from '@/services/PortAdminService';
	import UserService from '@/services/UserService';
    import moment from 'moment';

	export default {
		name: 'Requests',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Card,
			SectionTitle,
			RequestBlock,
			RequestCard,
			RequestCardPortAdmin
		},
		data () {
	        return {
                requests: [],
				openRequests: [],
				completedRequests: [],
                count: 0,
                isOpen: 'open'
	        };
	    },
	    methods: {
	    	scrollToTop () {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			},
            getRequests () {
				if (this.isPortAdmin) {
					PortAdminService.getRequests().then((response) => {
						this.requests = response.data.requests;
						this.openRequests = this.requests.filter(request => request.status !== 'completed' && request.assignable_id === null);
						this.assignedRequests = this.requests.filter(request => request.assignable_id === this.$store.state.uid);

						const twoWeeksAgo = new Date();
						twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
						this.completedRequests = this.requests.filter(request => {
							const requestDate = new Date(request.updated_at);
							return request.status === 'completed' && requestDate >= twoWeeksAgo;
						});

						this.openRequests.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
						this.completedRequests.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

						if (this.openRequests.length > 0) {
							this.$store.commit('SET_PORT_ADMIN_RED_DOT_VISIBILITY', true);
						} else {
							this.$store.commit('SET_PORT_ADMIN_RED_DOT_VISIBILITY', false);
						}
					});
				} else {
					UserService.getRequests().then((response) => {
						this.requests = response.data.requests;
						this.count = response.data.count;
					});
				}
            },
            getTimeAgo (date) {
                return moment(date).fromNow();
            },
            toggle (option) {
		    	this.isOpen = option;
		    	this.scrollToTop();
		    }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
	        isPortAdmin () {
	            return this.$store.getters.isPortAdmin;
	        }
		},
		mounted () {
            this.getRequests();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	@mixin max_width($breakpoint) {
	    @media only screen and (max-width: $breakpoint) { @content; }
	}
	.completed-text {
		font-size: 14px;
	    line-height: 20px;
	    color: white;
	    text-align: left;
	    margin-bottom: 20px;
	    max-width: 300px;
	    align-self: flex-start;
	}
	.requests {
		background: $green_base;
	}
	.pa-requests {
		position: relative;
	    border-radius: 36px 36px 0 0;
	    background: $white;
	    width: 100%;
	    transition: 0.5s ease;
	    z-index: 20;
	    flex: auto;
	    text-align: left;
	    padding-bottom: 88px;
	}
	.toggle {
		display: flex;
		border-radius: 40px;
		overflow: hidden;
		align-self: flex-start;
		margin-top: 5px;
		margin-bottom: 16px;
	}
	.option {
		background: $green_dark;
		color: $white;
		display: flex;
		align-items: center;
		padding: 8px 16px;
		cursor: pointer;
		font-weight: bold;
		font-size: 16px;
		transition: 0.3s ease;
		border-radius: 40px;
		&.mid {
			margin: 0 5px;
			@include max_width(380px) {
				margin: 0 4px;
			}
		}
		@include max_width(380px) {
			font-size: 14px;
		}
		@include max_width(350px) {
			padding: 8px 12px;
		}
	}
	.option .count {
		background: $green_base;
		color: $white;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		margin-left: 8px;
	}
	.option.active {
		color: $green_base;
		background-color: $white;
		border-radius: 40px;
		&:first-child {
			border-radius: 40px;
		}
		&:last-child {
			border-radius: 40px;
		}
	}
	.header-wrap {
		background: $green_base;
		.header {
			position: relative;
			height: auto;
			padding-top: 70px;
		}
	}
	.request-header {
		width: 100%;
		text-align: left;
		padding-top: 30px;
		h1 {
			font-size: 26px;
			line-height: 31px;
			letter-spacing: -0.65px;
			color: $white;
			margin-bottom: 8px;
		}
	}
	.section-gap-extra {
		margin-bottom: 55px;
	}
	.request-card-wrap {
		position: relative;
		display: block;
		.request-card {
			z-index: 3;
			box-shadow: none;
			&:not(:first-child) {
				position: absolute;
				top: 0;
				left: 0;
			}
			&:nth-child(2) {
				transform: scale(0.95);
				top: 20px;
				z-index: 2;
			}
			&:nth-child(3) {
				transform: scale(0.9);
				top: 40px;
				z-index: 1;
				box-shadow: 5px 15px 15px 5px #f6f6f6;
			}
		}
	}
</style>
