import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
    },
    state: {
        token: localStorage.getItem('token') || '',
        uid: localStorage.getItem('uid') || 0,
        provider: '',
        user: localStorage.getItem('user') || '',
        hasProfile: false,
        isMenuOpen: false,
        isPortAdmin: false,
        port: {},
        isAccessModalOpen: false,
        isPortContactModalOpen: false,
        isPortCheckInConfirmModalOpen: false,
        isPortCheckInSuccessModalOpen: false,
        checkIn: {},
        isPortSearchModalOpen: false,
        isResourceAdviceModalOpen: false,
        isFaqModalOpen: false,
        isResourceSearchModalOpen: false,
        isUpdateProfileModalOpen: false,
        isPortAdminModalOpen: false,
        isPortAdminNewModalOpen: false,
        isPortAdminConfirmationModalOpen: false,
        isUpdatePasswordModalOpen: false,
        recentSearchResults: JSON.parse(localStorage.getItem('recent_search_results')) || [],
        requestCategory: '',
        requestPort: {},
        isRequestConfirmationModalOpen: false,
        isRequestSubmittedModalOpen: false,
        isPortAdminRequestConfirmationModalOpen: false,
        isPortAdminRequestSubmittedModalOpen: false,
        modalTitle: false,
        modalMessage: false,
        additionalDataAlt: null,
        modalTitleConfirmation: false,
        modalMessageConfirmation: false,
        additionalData: null,
        request: {},
        notifications: false,
        isProfileUpdateModalOpen: false,
        show2faPrompt: false,
        email: '',
        showPortAdminRedDot: false
    },
    mutations: {
        LOGIN: (state, token) => {
            state.token = token;
            localStorage.setItem('token', token);
        },
        LOGOUT: (state) => {
            state.token = '';
            localStorage.removeItem('token');
            localStorage.removeItem('uid');
            localStorage.removeItem('user');
            localStorage.removeItem('recent_search_results');
            state.recentSearchResults = [];
        },
        MENU_TOGGLE: (state) => {
            state.isMenuOpen = !state.isMenuOpen;
        },
        SET_USER: (state, user) => {
            state.user = user;
            localStorage.setItem('user', user);
        },
        SET_UID: (state, id) => {
            state.uid = id;
            localStorage.setItem('uid', id);
        },
        SET_NOTIFICATIONS: (state, boolean) => {
            state.notifications = boolean;
        },
        PORT_CONTACT_TOGGLE: (state, port) => {
            state.isPortContactModalOpen = !state.isPortContactModalOpen;
            if (port) {
                state.port = port;
            }
        },
        PORT_CHECK_IN_CONFIRM_TOGGLE: (state, port) => {
            state.isPortCheckInConfirmModalOpen = !state.isPortCheckInConfirmModalOpen;
            if (port) {
                state.port = port;
            }
        },
        SET_CHECK_IN: (state, checkIn) => {
            state.isPortCheckInConfirmModalOpen = false;
            state.isPortCheckInSuccessModalOpen = true;
            state.checkIn = checkIn;
        },
        PORT_SEARCH_TOGGLE: (state) => {
            state.isPortSearchModalOpen = !state.isPortSearchModalOpen;
        },
        RESOURCE_ADVICE_TOGGLE: (state) => {
            state.isResourceAdviceModalOpen = !state.isResourceAdviceModalOpen;
        },
        FAQ_TOGGLE: (state) => {
            state.isFaqModalOpen = !state.isFaqModalOpen;
        },
        RESOURCE_SEARCH_TOGGLE: (state) => {
            state.isResourceSearchModalOpen = !state.isResourceSearchModalOpen;
        },
        PORT_ADMIN_TOGGLE: (state) => {
            state.isPortAdminModalOpen = !state.isPortAdminModalOpen;
        },
        PORT_ADMIN_NEW_TOGGLE: (state) => {
            state.isPortAdminNewModalOpen = !state.isPortAdminNewModalOpen;
        },
        PORT_ADMIN_CONFIRMATION_TOGGLE: (state) => {
            state.isPortAdminConfirmationModalOpen = !state.isPortAdminConfirmationModalOpen;
        },
        UPDATE_PROFILE_TOGGLE: (state) => {
            state.isUpdateProfileModalOpen = !state.isUpdateProfileModalOpen;
        },
        UPDATE_PASSWORD_TOGGLE: (state) => {
            state.isUpdatePasswordModalOpen = !state.isUpdatePasswordModalOpen;
        },
        REQUEST_CONFIRMATION_MODAL_TOGGLE: (state, request) => {
            state.isRequestConfirmationModalOpen = !state.isRequestConfirmationModalOpen;
            if (request) {
                state.request = request;
            }
        },
        SET_REQUEST_SUBMITTED: (state) => {
            state.isRequestConfirmationModalOpen = false;
            state.isRequestSubmittedModalOpen = true;
        },
        TOGGLE_PORT_ADMIN_REQUEST_CONFIRMATION_MODAL: (state, payload) => {
            state.isPortAdminRequestConfirmationModalOpen = payload.isOpen;
            state.modalTitleConfirmation = payload.title || '';
            state.modalMessageConfirmation = payload.message || '';
            state.additionalData = payload.additionalData || null;
        },
        TOGGLE_PORT_ADMIN_REQUEST_SUBMITTED_MODAL: (state, payload) => {
            state.isPortAdminRequestSubmittedModalOpen = payload.isOpen;
            state.modalTitle = payload.title || '';
            state.modalMessage = payload.message || '';
            state.additionalDataAlt = payload.additionalData || '';
        },
        PORT_ADMIN_REQUEST_SUBMITTED_TOGGLE: (state) => {
            state.isPortAdminRequestSubmittedModalOpen = !state.isPortAdminRequestSubmittedModalOpen;
        },
        SET_PORT_ADMIN: (state, boolean) => {
            state.isPortAdmin = boolean;
        },
        SHOW_2FA_PROMPT: (state, boolean) => {
            state.show2faPrompt = boolean;
        },
        SET_EMAIL: (state, email) => {
            state.email = email;
        },
        CLEAR_2FA: (state) => {
            state.show2faPrompt = false;
            state.emailFor2fa = null;
        },
        SET_PORT_ADMIN_RED_DOT_VISIBILITY: (state, boolean) => {
            state.showPortAdminRedDot = boolean;
        }
    },
    getters: {
        isAuthenticated: state => !!state.token,
        isPortAdmin: state => state.isPortAdmin,
        showPortAdminRequestRedDot: state => state.showPortAdminRedDot
    },
    actions: {
    }
});
