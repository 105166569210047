<template>
	<div class="dashboard container container-full-height container-bg-none">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isProfileModalOpen || isAccessModalOpen || isPortSearchModalOpen }"></span>
		<div class="header header-standard">
			<HeaderNavBar />
		</div><!-- header -->
		<div class="header-image">
			<img class="bg" alt="The Mission to Seafarers" src="@/assets/dashboard-header.jpg" />
		</div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<div class="text-block no-space">
				<h1 v-if="authenticated" class="title no-space"><strong>Welcome{{ hasProfile ? ` back, ${name}` : '' }}!</strong></h1>
				<h1 v-else class="title no-space"><strong>Welcome!</strong></h1>
                <p v-if="message" class="intro-text">{{ message }}</p>
			</div>
			<div class="section-gap card-wrap">
				<Card
					v-if="isPortAdmin"
					routeName="Requests"
					class="card-block-green"
					icon="SendIcon"
					category="Requests"
					title="Manage Requests"
					text="View and respond to requests"
				/>
				<Card
					v-else
					routeName="Requests"
					class="card-block-green"
					icon="SendIcon"
					category="Requests"
					title="Make a Request"
					text="Pre-order goods and services"
				/>
				<Card
					v-if="isPortAdmin"
					routeName="Ports"
					class="card-block-blue"
					icon="AnchorIcon"
					category="Requests"
					title="Manage My Ports"
					text="Edit port and request details"
				/>
				<Card
					v-else
					routeName="CheckIn"
					class="card-block-orange"
					icon="CheckInIcon"
					category="check-in"
					title="Centre Check-in"
					text="Click for free, fast, Wi-FI"
				/>
			</div><!-- card wrap -->
			<div v-if="authenticated" class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">My Ports</h2>
				</div>
				<div v-if="ports.length !== 0" class="port-card-wrap" v-dragscroll>
                    <PortCard
                        v-for="port in ports"
                        :key="port.id"
                        :country="port.country"
                        :port="port.name"
                        :id="port.id"
                        :image="port.image_url"
                    />
                    <PortCard
						v-if="!isPortAdmin"
                    	className="grey"
                    	port="Add more ports to your list"
                    	add="true"
                        route="OurPorts"
                   	/>
				</div><!-- port card wrap -->
				<div v-else class="port-card-wrap" v-dragscroll>
                    <PortCard
                    	className="grey"
                    	port="Add ports to your list"
                    	add="true"
                        route="OurPorts"
                   	/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap section-ports">
				<div class="field field-icon field-icon-left">
					<div class="control">
						<input class="search-field" placeholder="Search Ports" type="text" @click="openSearchModal">
						<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
					</div>
				</div>
			</div>
			<div class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">Resources</h2>
                    <a @click="check('Resources')" class="page-link page-link-red">View more &raquo;</a>
				</div>
				<div class="card-row-wrap">
					<CardRow
						v-for="resource in resources"
						:key="resource.id"
						routeName="ResourcesSingle"
                        :id="resource.id"
						class="card-row-block-resources"
						:title="resource.title"
						:tag="resource.category"
						:type="resource.simplified_type"
					/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap card-wrap">
				<Card
					routeName="Resources"
					class="card-block-red"
					icon="FolderIcon"
					category="Resources"
					title="Well-being"
					text="Discover our resources"
				/>
				<Card
					link="https://www.seafarershappinessindex.org"
					class="card-block-sky"
					icon="ShipIcon"
					category="Happiness index"
					title="Seafarers Happiness Index"
					text="Complete the Seafarers Happiness Index"
				/>
			</div><!-- card wrap -->
			<div class="section-gap section-news">
				<div class="section-title-wrap">
					<h2 class="section-title">News</h2>
					<router-link :to="{ name: 'News' }" class="page-link page-link-grey">View more &raquo;</router-link>
				</div>
				<div class="news-card-wrap" v-dragscroll>
					<NewsCard
                        v-for="article in news"
                        :key="article.id"
						routeName="Dashboard"
						:image="article.image_url"
						:title="article.title"
						:date="article.created_at | moment('Do MMMM YYYY')"
                        :id="article.id"
					/>
				</div><!-- port card wrap -->
			</div>
			<div v-if="!isPortAdmin" class="section-gap card-wrap">
				<Card
					link="https://missiontoseafarers.org/donate"
					class="card-block-yellow card-block-full"
					icon="DonateIcon"
					category="Donate"
					title="Donate to support us"
					text="MtS relies on charitable funds to help seafarers"
				/>
			</div><!-- card wrap -->
            <div v-if="!isPortAdmin && online" class="section-gap card-wrap"><!-- Flying Angel -->
                <Card
                    link="https://www.missiontoseafarers.org/contact-a-chaplain"
                    class="card-block-blue card-block-full"
                    icon="ChatIcon"
                    category="Flying Angel"
                    title="Speak to the flying angel"
                    text="Our interactive digital assistant"
                />
            </div><!-- card wrap -->
		</div><!-- content wrap -->

		<div ref="modalProfile" class="phone-modal success-modal with-nav" style="bottom: -487px;" :class="{active: isProfileModalOpen}">
			<span class="modal-line"></span>
			<div class="modal-title-bar">
				<div class="icon-wrap">
					<RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
					<span>Create your profile</span>
				</div><!-- icon wrap -->
				<GreyCloseIcon @click="closeProfileModal" style="width: 31px; height: 31px;" class="icon close" />
			</div><!-- modal title bar -->
			<div class="modal-message-block">
				<SuccessIcon class="success-icon" style="width: 90px; height: 90px;" />
				<h3>Your Profile has been created.</h3>
				<p>Please continue to access your dashboard.</p>
			</div>
			<Button @click.native="closeProfileModal" icon="HomeIcon" class="btn btn-green btn-icon btn-home-white btn-icon-right" text="Visit Homepage"></Button>
		</div><!-- modal -->
		<div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
			<span class="modal-line"></span>
			<div class="modal-title-bar">
				<div class="icon-wrap">
					<RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
					<span>Create your profile</span>
				</div><!-- icon wrap -->
				<GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
			</div><!-- modal title bar -->
			<div class="modal-message-block wide">
				<ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
				<h3>Please create a profile to access this area.</h3>
				<p>This app will always be free, but creating a profile will give you greater access to the app.</p>
			</div>
			<Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
			<Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
			<a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
		</div><!-- modal -->

        <PortSearchOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import Card from '@/components/Card.vue';
	import CardRow from '@/components/CardRow.vue';
	import PortCard from '@/components/PortCard.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import SearchIcon from '@/assets/search.svg';
    import UserService from '@/services/UserService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import SuccessIcon from '@/assets/success-filled.svg';
    import Button from '@/components/Button.vue';
    import PortSearchOverlay from '@/components/PortSearchOverlay';
	import PushNotificationService from '@/services/PushNotificationService';

    export default {
		name: 'Dashboard',
		components: {
            PortSearchOverlay,
			HeaderNavBar,
			Button,
			Nav,
			Menu,
			Card,
			CardRow,
			PortCard,
			SuccessIcon,
			NewsCard,
			RegisterIcon,
			ProfileIcon,
			GreyCloseIcon,
			SearchIcon
		},
		data () {
	        return {
                name: '',
                ports: [],
                isProfileModalOpen: false,
                resources: [],
                news: [],
                message: null
	        };
	    },
	    computed: {
            authenticated () {
                return this.$store.getters.isAuthenticated;
            },
            isPortAdmin () {
	            return this.$store.getters.isPortAdmin;
	        },
            hasProfile () {
                return this.$store.state.hasProfile;
            },
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isPortSearchModalOpen () {
                return this.$store.state.isPortSearchModalOpen;
            },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            },
            online () {
                return window.navigator.onLine;
            }
		},
        methods: {
            getDashboard () {
                UserService.getDashboard().then((response) => {
                    this.$store.state.hasProfile = response.data.profile;
                    if (!this.$store.state.hasProfile) {
                        this.$router.push({ name: 'CreateProfileGetStarted' });
                    }
                    this.$store.commit('SET_USER', response.data.full_name);
                    this.$store.commit('SET_UID', response.data.id);
                    this.$store.commit('SET_NOTIFICATIONS', response.data.notifications);

					this.$store.commit('SET_PORT_ADMIN', response.data.is_port_admin);

					if (response.data.is_port_admin) {
						PushNotificationService.initialize();
					} else {
						console.log('No Push notifs Not a port admin');
					}

                    this.name = response.data.name;
                    this.ports = response.data.ports;
                    this.resources = response.data.resources;
                    this.news = response.data.news;
                    this.message = response.data.message;
                });
            },
            getPublicDashboard () {
                UserService.getPublicDashboard().then((response) => {
                    this.resources = response.data.resources;
                    this.news = response.data.news;
                    this.message = response.data.message;
                });
            },
            openLoginScreen () {
            	this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            logout () {
                UserService.logout().then(() => {
                    this.$store.commit('SET_USER', {});
                    this.$router.push({ name: 'Home' });
                });
            },
            closeProfileModal () {
            	this.isProfileModalOpen = !this.isProfileModalOpen;
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            },
            check (routeName) { // temp
                if (!this.authenticated) {
                    this.isAccessModalOpen = true;
                } else {
                    this.$router.push({ name: routeName });
                }
            },
            openSearchModal () {
                this.$store.commit('PORT_SEARCH_TOGGLE');
            }
        },
        mounted () {
            if (this.authenticated) {
                this.getDashboard();
            } else {
                this.getPublicDashboard();
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_global.scss";
.search-field {
    padding: 0 50px !important;
}
.intro-text {
	margin-top: 20px;
    padding: 18px 17px 18px;
    border-radius: 12px;
    background: #d9efff;
    color: #445d72;
    font-size: 15px;
}
</style>
