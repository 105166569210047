<template>
<div v-if="port" ref="modalPortContact" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortContactModalOpen ? 1 : 0, bottom: modalPortContactHeight + 'px'}" :class="{active: isPortContactModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<ContactIcon style="width: 25px; height: auto;" class="icon contact-icon" />
			<span>Contact our team</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="togglePortContactModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="section-gap">
		<Button newtab="_blank" :href="'tel:' + this.port.phonenumber" icon="PhoneIcon" class="none-bottom btn btn-med btn-green btn-square btn-icon btn-icon-right" text="Call now"></Button>
		<Button newtab="_blank" :href="'mailto:' + getPortAdminEmail" icon="ContactIcon" class="none-bottom btn btn-med btn-blue with-white btn-square btn-icon btn-icon-right" text="Email now"></Button>
		<Button routeName="/requests/new" icon="SendIcon" class="none-bottom btn btn-med btn-light-green btn-square btn-icon btn-icon-right" text="Make a request"></Button>
		<Button newtab="_blank" href="https://www.missiontoseafarers.org/contact-a-chaplain" icon="ChatIcon" class="none-bottom btn btn-med btn-light-blue btn-square btn-icon btn-icon-right" text="Live chat"></Button>
	</div>
	<div class="section-gap">
		<div class="info-card alt">
			<span class="eyebrow">Contact Information</span>
			<div class="info-card-wrap">
				<div v-if="port.telephone" class="row">
					<p class="p-strong col">Telephone</p>
					<p class="col"><a class="tel" :href="'tel:' + getPhoneNumber">{{ port.telephone }}</a></p>
				</div>
				<div v-if="port.opening_times" class="row">
					<p class="p-strong col">Opening Times</p>
					<p class="col">{{ port.opening_times }}</p>
				</div>
				<div v-if="port.address || port.location" class="row">
					<p class="p-strong col">Address</p>
					<p v-if="port.address" class="col" v-html="port.address">{{ port.address }}</p>
					<p v-else class="col">{{ port.location }}</p>
				</div>
			</div>
		</div><!-- info card -->
	</div>
</div><!-- modal -->
</template>

<script>
import GreyCloseIcon from '@/assets/close-grey.svg';
import ContactIcon from '@/assets/contact.svg';
import Button from '@/components/Button.vue';
import PortService from '@/services/PortService';
export default {
name: 'PortContactOverlay',
    props: {
        subtitle: String,
        title: String,
        icon: String
    },
    data () {
        return {
            modalPortContactHeight: null,
            phoneNumberTrimmed: '',
            tel: '0777777777',
            port: null // Initialize port as null
        };
    },
    components: {
        GreyCloseIcon,
        ContactIcon,
        Button
    },
    computed: {
        matchHeightModal () {
            return '-' + this.$refs.modalPortContact.clientHeight;
        },
        isPortContactModalOpen () {
            return this.$store.state.isPortContactModalOpen;
        },
        getPortAdminEmail () {
            console.log(this.port.port_admins);
            var portAdmin = this.port.port_admins.find(portAdmin => portAdmin.id === this.port.port_admin_id);
            return portAdmin ? portAdmin.email : '';
        },
        online () {
            return window.navigator.onLine;
        }
    },
    methods: {
        togglePortContactModal () {
            this.$store.commit('PORT_CONTACT_TOGGLE');
            this.modalPortContactHeight = '-' + this.$refs.modalPortContact.clientHeight;
        },
        getPortContactDetails () {
            PortService.getPortContactDetails(this.$route.params.id).then((response) => {
                console.log(response.data);
                this.port = response.data;
            });
        }
    },
    mounted () {
        this.modalPortContactHeight = '-1000';
        this.getPortContactDetails();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $blue_dark;
				}
			}
			span {
				color: $blue_dark;
			}
		}
		.close {
			g, path {
				stroke-width: 2.5;
				stroke: $dark;
			}
		}
	}
	.info-card {
		&.alt {
			padding: 0;
		    border: none;
		    box-shadow: none;
		    .info-card-wrap .row {
		    	border-bottom: none;
		    	padding-bottom: 5px;
		    	.p-strong {
		    		font-size: 17px;
		    		color: $dark;
		    		font-weight: 500;
		    	}
		    	.col {
		    		font-size: 17px;
		    		line-height: 24px;
		    	}
		    }
		}
	}
</style>
