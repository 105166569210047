<template>
	<div class="ports relative-container">
		<Nav />
		<span class="overlay" :class="{ active: isMenuOpen || isPortSearchModalOpen || isAccessModalOpen }"></span>
		<div class="header header-standard">
			<HeaderNavBar back search searchType="ports"/>
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="AnchorIcon"
				title="Ports"
				colour="blue"
			/>
			<div v-if="isAuthenticated" class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">My Ports</h2>
                    <router-link :to="{ name: 'MyPorts' }" class="page-link page-link-blue">View all &raquo;</router-link>
				</div>
				<div v-if="isPortAdmin" class="section-gap section-ports section-ports-alt">
					<div class="field field-icon field-icon-left">
						<div class="control">
							<input class="search-field" placeholder="Search Ports" type="text" @click="openSearchModal">
							<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
						</div>
					</div>
				</div>
				<div class="port-card-wrap" v-dragscroll>
                    <PortCard
                        v-for="port in ports"
                        :key="port.id"
                        :country="port.country"
                        :port="port.name"
                        :id="port.id"
                        :image="port.image_url"
                    />
                    <PortCard
						v-if="!isPortAdmin"
                    	className="grey"
                    	port="Add more ports to your list"
                    	add="true"
                        route="OurPorts"
                   	/>
				</div><!-- port card wrap -->
			</div>
			<div v-if="!isPortAdmin && isAuthenticated" class="section-gap section-port-list">
				<div class="section-title-wrap">
					<h2 class="section-title">Recently Visited Ports</h2>
                    <router-link :to="{ name: 'CheckInLog' }" class="page-link page-link-grey">View log &raquo;</router-link>
				</div>
				<div class="port-list-wrap">
                    <PortListBlock
                        v-for="checkIn in recent"
                        v-bind:key="checkIn.port.id"
                    	:port="checkIn.port.name"
                    	:country="checkIn.port.country"
                    	:date="checkIn.pivot.created_at | moment('Do MMMM YYYY')"
                        :id="checkIn.port.id"
                        :countryCode="checkIn.port.country_code"
                    />
				</div><!-- port list wrap -->
			</div>
			<div v-if="!isPortAdmin" class="section-gap section-ports">
				<div class="field field-icon field-icon-left">
					<div class="control">
						<input class="search-field" placeholder="Search Ports" type="text" @click="openSearchModal">
						<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
					</div>
				</div>
			</div>
			<div class="section-gap card-wrap">
				<Card
					routeName="OurPorts"
					class="card-block-blue"
					icon="AnchorIcon"
					category="Ports"
					:title="isPortAdmin ? 'View All Ports' : 'View Our Ports'"
					text="Find out where we work across the world"
				/>
				<Card
					v-if="!isPortAdmin"
					routeName="CheckIn"
					class="card-block-orange"
					icon="CheckInIcon"
					category="check-in"
					title="Centre Check-in"
					text="Already in a Port? Click here"
				/>
			</div><!-- card wrap -->
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
		<PortSearchOverlay />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SearchIcon from '@/assets/search.svg';
	import Card from '@/components/Card.vue';
	import PortCard from '@/components/PortCard.vue';
	import PortSearchOverlay from '@/components/PortSearchOverlay.vue';
	import PortListBlock from '@/components/PortListBlock.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import UserService from '@/services/UserService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'Ports',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SearchIcon,
			Card,
			PortCard,
			PortListBlock,
			SectionTitle,
			PortSearchOverlay,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
                ports: [],
                recent: [],
                search: ''
	        };
	    },
	    methods: {
            getPorts () {
                UserService.getPorts().then((response) => {
                    this.ports = response.data.ports;
                    this.recent = response.data.recent;
                });
            },
            openSearchModal () {
                this.$store.commit('PORT_SEARCH_TOGGLE');
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
	        isPortAdmin () {
	            return this.$store.getters.isPortAdmin;
	        },
            isPortSearchModalOpen () {
                return this.$store.state.isPortSearchModalOpen;
            },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            },
            isAuthenticated () {
                return this.$store.getters.isAuthenticated;
            }
		},
		mounted () {
            if (this.isAuthenticated) {
                this.getPorts();
            }
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
    @import "@/assets/scss/_global.scss";
    .search-field {
        padding: 0 50px !important;
    }
    .section-ports-alt {
    	margin-top: 15px;
    	margin-bottom: 15px;
    }
</style>
