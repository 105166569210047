<template>
	<div ref="menuModal" class="phone-modal phone-modal-menu" :style="{ bottom: menuModal + 'px'}" :class="{active: isMenuOpen}">
		<span class="modal-line"></span>
		<div class="modal-title-bar less">
			<div class="icon-wrap">
				<span class="big">Menu</span>
			</div><!-- icon wrap -->
			<GreyCloseIcon @click="closeMenu" style="width: 31px; height: 31px;" class="icon close" />
		</div><!-- modal title bar -->
		<div class="menu-wrap" @click="track()">
			<div class="menu-row">
				<router-link :to="{ name: 'Dashboard' }" class="nav-item">
					<div class="nav-img-wrap">
		            	<HomeIcon style="height: 23px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Home</span>
		        </router-link>
			</div>
			<div class="menu-row">
				<router-link :to="{ name: 'Resources' }" class="nav-item nav-item-red">
					<div class="nav-img-wrap">
		           		<FolderIcon style="height: 21px; width: auto;" class="nav-item-img" />
		           	</div>
		            <span class="nav-item-title">Resources</span>
		        </router-link>
			</div>
			<div class="menu-row">
				<router-link :to="{ name: 'Ports' }" class="nav-item nav-item-blue">
					<div class="nav-img-wrap">
		            	<AnchorIcon style="height: 23px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Ports</span>
		        </router-link>
			</div>
			<div class="menu-row">
				<router-link :to="{ name: 'Requests' }" class="nav-item nav-item-green">
					<div class="nav-img-wrap">
		            	<SendIcon style="height: 22px; width: auto;" class="nav-item-img" />
		            </div>
		            <span v-if="isPortAdmin" class="nav-item-title">Manage Requests</span>
		            <span v-else class="nav-item-title">Requests</span>
		        </router-link>
			</div>
			<div v-if="!isPortAdmin"  class="menu-row">
				<router-link :to="{ name: 'CheckIn' }" class="nav-item nav-item-orange">
					<div class="nav-img-wrap">
		            	<CheckInIcon style="height: 22px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Check-in</span>
		        </router-link>
			</div>
			<!-- <div class="menu-row">
				<router-link :to="{ name: 'Chat' }" class="nav-item nav-item-purple">
					<div class="nav-img-wrap">
		            	<ChatIcon style="height: 21px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Chat to a Chaplain</span>
		        </router-link>
			</div> -->
			<div class="menu-row">
				<a @click="openBrowser('https://www.seafarershappinessindex.org')" class="nav-item nav-item-sky">
					<div class="nav-img-wrap">
		            	<ShipIcon style="height: 26px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Seafarers Happiness Index</span>
		        </a>
			</div>
			<div v-if="!isPortAdmin"  class="menu-row">
				<a target="_blank" @click="openBrowser('https://www.missiontoseafarers.org/contact')" class="nav-item nav-item-grey">
					<div class="nav-img-wrap">
		            	<ContactIcon style="height: 21px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Contact our team</span>
		        </a>
			</div>
			<div class="menu-row">
				<router-link :to="{ name: 'News' }" class="nav-item nav-item-grey">
					<div class="nav-img-wrap">
		            	<NewsIcon style="height: 21px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">News</span>
		        </router-link>
			</div>
			<div v-if="!isPortAdmin"  class="menu-row">
				<router-link :to="{ name: 'About' }" class="nav-item nav-item-grey">
					<div class="nav-img-wrap">
		            	<NotificationIcon style="height: 23px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">About the app</span>
		        </router-link>
			</div>
			<div class="menu-row">
				<router-link :to="{ name: 'MyProfile' }" class="nav-item nav-item-grey">
					<div class="nav-img-wrap">
		            	<ProfileIcon style="height: 22px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">My profile</span>
		        </router-link>
			</div>
            <div class="menu-row">
                <router-link :to="{ name: 'PrivacyPolicy' }" class="nav-item nav-item-grey">
                    <div class="nav-img-wrap">
                        <LockIcon style="height: 23px; width: auto;" class="nav-item-img" />
                    </div>
                    <span class="nav-item-title">Privacy and Data Protection Notice</span>
                </router-link>
            </div>
			<div class="menu-row">
				<a v-if="isAuthenticated" @click="logout" class="nav-item nav-item-grey">
					<div class="nav-img-wrap">
		            	<ArrowLeft style="height: 22px; width: auto;" class="nav-item-img" />
		            </div>
		            <span class="nav-item-title">Log Out</span>
		        </a>
                <a v-else @click="$router.push('/')" class="nav-item nav-item-grey">
                    <div class="nav-img-wrap">
                        <ArrowRight style="height: 22px; width: auto;" class="nav-item-img" />
                    </div>
                    <span class="nav-item-title">Log In</span>
                </a>
			</div>
		</div>
	</div><!-- modal -->
</template>

<script>
import UserService from '@/services/UserService';
import HomeIcon from '@/assets/home.svg';
import FolderIcon from '@/assets/folder.svg';
import AnchorIcon from '@/assets/anchor-black.svg';
import SendIcon from '@/assets/send.svg';
import CheckInIcon from '@/assets/check-in.svg';
// import ChatIcon from '@/assets/chat.svg';
import ShipIcon from '@/assets/ship.svg';
import ContactIcon from '@/assets/contact.svg';
import NewsIcon from '@/assets/news.svg';
import ProfileIcon from '@/assets/profile.svg';
import NotificationIcon from '@/assets/chat-bubble.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import LockIcon from '@/assets/lock.svg';
import ArrowLeft from '@/assets/arrow-left.svg';
import ArrowRight from '@/assets/arrow-right.svg';
import UtilityService from '@/services/UtilityService';
import { Browser } from '@capacitor/browser';
export default {
	name: 'Menu',
	components: {
		GreyCloseIcon,
		FolderIcon,
		AnchorIcon,
		SendIcon,
		CheckInIcon,
		// ChatIcon,
		ContactIcon,
		NewsIcon,
		NotificationIcon,
		ProfileIcon,
		HomeIcon,
        ShipIcon,
        LockIcon,
        ArrowLeft,
        ArrowRight
		// MenuIcon,
		// NotificationIcon
	},
	data () {
		return {
			menuModal: null,
			menuModalHeight: null,
			showMenuModal: false
		};
	},
	computed: {
		isMenuOpen () {
            return this.$store.state.isMenuOpen;
        },
		matchHeightModal () {
			return '-' + this.$refs.menuModal.clientHeight;
		},
        isAuthenticated () {
            return this.$store.getters.isAuthenticated;
        },
        isPortAdmin () {
            return this.$store.getters.isPortAdmin;
        }
	},
	methods: {
		closeMenu () {
			this.$store.state.isMenuOpen = false;
		},
		menuModalActive () {
	    	this.showMenuModal = !this.showMenuModal;
	    },
	    logout () {
	    	if (this.$store.getters.isAuthenticated) {
	            UserService.logout().then(() => {
	                this.$router.push({ name: 'Home' });
	            });
	        } else {
	        	this.$router.push({ name: 'Home' });
	        }
        },
        track () {
            if (this.$store.getters.isAuthenticated) {
                UtilityService.trackClick({
                    element: 'burger-menu'
                });
            }
        },
        async openBrowser (url) {
            await Browser.open({
                url: url,
                presentationStyle: 'popover'
            });
        }
	},
	mounted () {
		this.menuModal = this.matchHeightModal;
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.menu-wrap {
		position: relative;
	}
	.menu-row {
		display: flex;
		height: 50px;
		border-bottom: 1px solid $snow;
		text-align: left;
		&:last-child {
			border-bottom: none;
		}
    	.nav-item {
    		display: flex;
    		align-items: center;
    		.nav-img-wrap {
    			width: 42px;
    		}
    		svg {
    			overflow: visible;
    			display: block;
    			path, g {
    				stroke-width: 3;
    			}
    		}
    		&.nav-item-grey {
    			svg {
    				path, g {
    					stroke: $mid;
    				}
    			}
    			.nav-item-title {
    				color: $mid;
    			}
    		}
    		&.nav-item-red {
    			svg {
    				path {
    					stroke: $red_base;
    				}
    			}
    		}
    		&.nav-item-blue {
    			svg {
    				path {
    					stroke: $blue_base;
    				}
    			}
    		}
    		&.nav-item-green {
    			svg {
    				path {
    					stroke: $green_base;
    				}
    			}
    		}
    		&.nav-item-orange {
    			svg {
    				path {
    					stroke: $orange_base;
    				}
    			}
    		}
    		&.nav-item-purple {
    			svg {
    				path {
    					stroke: $purple_base;
    				}
    			}
    		}
    		&.nav-item-sky {
    			svg {
    				path {
    					stroke: $sky_base;
    				}
    			}
    		}
    	}
    	.nav-item-title {
    		font-size: 17px;
    		font-weight: $weight_600;
    		line-height: 20px;
    		letter-spacing: -0.43px;
    		color: $dark;
    	}
	}
</style>
