<template>
	<div v-if="subtitle" class="section-title alt" :class="colour">
		<div class="circle">
			<AnchorIcon style="width: 20px;" class="section-title-img" v-if="icon === 'AnchorIcon'" />
			<StarIcon style="width: 20px;" class="section-title-img" v-if="icon === 'StarIcon'" />
			<CheckInIcon style="width: 20px;" class="section-title-img check-in" v-if="icon === 'CheckInIcon'" />
			<NewsIcon style="width: 17px;" class="section-title-img check-in" v-if="icon === 'NewsIcon'" />
			<ChatIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'ChatIcon'" />
			<ChatBubbleIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'ChatBubbleIcon'" />
			<FolderIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'FolderIcon'" />
			<DocumentIcon style="width: 17px;" class="section-title-img chat-icon" v-if="icon === 'DocumentIcon'" />
			<FaqIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'FaqIcon'" />
			<ProfileIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'ProfileIcon'" />
			<PersonalIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'PersonalIcon'" />
			<ProfessionalIcon style="width: 19px;" class="section-title-img chat-icon" v-if="icon === 'ProfessionalIcon'" />
			<NotificationIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'NotificationIcon'" />
			<LoginIcon style="width: 19px;" class="section-title-img chat-icon" v-if="icon === 'LoginIcon'" />
			<TechnicalIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'TechnicalIcon'" />
            <TagIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'TagIcon'" />
            <SendIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'SendIcon'" />
            <TickIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'TickIcon'" />
            <PencilIcon style="width: 18px;" class="section-title-img chat-icon" v-if="icon === 'PencilIcon'" />
            <ContactIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'ContactIcon'" />
		</div>
		<div class="section-title-info">
			<span class="sub">{{ subtitle }}</span>
			<h1>{{ title }}</h1>
		</div>
	</div>
	<div v-else class="section-title" :class="colour">
		<div class="circle">
			<AnchorIcon style="width: 20px;" class="section-title-img" v-if="icon === 'AnchorIcon'" />
			<StarIcon style="width: 20px;" class="section-title-img" v-if="icon === 'StarIcon'" />
			<CheckInIcon style="width: 20px;" class="section-title-img check-in" v-if="icon === 'CheckInIcon'" />
			<NewsIcon style="width: 17px;" class="section-title-img check-in" v-if="icon === 'NewsIcon'" />
			<ChatIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'ChatIcon'" />
			<ChatBubbleIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'ChatBubbleIcon'" />
			<FolderIcon style="width: 18px;" class="section-title-img chat-icon" v-if="icon === 'FolderIcon'" />
			<DocumentIcon style="width: 17px;" class="section-title-img chat-icon" v-if="icon === 'DocumentIcon'" />
			<FaqIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'FaqIcon'" />
			<ProfileIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'ProfileIcon'" />
			<PersonalIcon style="width: 20px;" class="section-title-img chat-icon" v-if="icon === 'PersonalIcon'" />
			<ProfessionalIcon style="width: 19px;" class="section-title-img chat-icon" v-if="icon === 'ProfessionalIcon'" />
			<NotificationIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'NotificationIcon'" />
			<LoginIcon style="width: 10px;" class="section-title-img chat-icon" v-if="icon === 'LoginIcon'" />
			<TechnicalIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'TechnicalIcon'" />
            <TagIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'TagIcon'" />
            <SendIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'SendIcon'" />
            <TickIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'TickIcon'" />
            <PencilIcon style="width: 18px;" class="section-title-img chat-icon" v-if="icon === 'PencilIcon'" />
            <ContactIcon style="width: 21px;" class="section-title-img chat-icon" v-if="icon === 'ContactIcon'" />
		</div>
		<h1>{{ title }}</h1>
	</div>
</template>

<script>
import AnchorIcon from '@/assets/anchor-white.svg';
import StarIcon from '@/assets/star-filled.svg';
import CheckInIcon from '@/assets/check-in.svg';
import NewsIcon from '@/assets/news.svg';
import ChatIcon from '@/assets/chat.svg';
import ChatBubbleIcon from '@/assets/chat-bubble.svg';
import FolderIcon from '@/assets/folder.svg';
import DocumentIcon from '@/assets/document.svg';
import FaqIcon from '@/assets/faq.svg';
import ProfileIcon from '@/assets/profile.svg';
import PersonalIcon from '@/assets/personal-info.svg';
import ProfessionalIcon from '@/assets/professional-info.svg';
import NotificationIcon from '@/assets/notification.svg';
import LoginIcon from '@/assets/login-details.svg';
import TechnicalIcon from '@/assets/technical-support.svg';
import TagIcon from '@/assets/tag.svg';
import SendIcon from '@/assets/send.svg';
import TickIcon from '@/assets/tick.svg';
import PencilIcon from '@/assets/pencil.svg';
import ContactIcon from '@/assets/contact.svg';
export default {
	name: 'SectionTitle',
	props: {
		title: String,
		subtitle: String,
		icon: String,
		colour: String
	},
	methods: {

    },
	components: {
		AnchorIcon,
		StarIcon,
		NewsIcon,
		ChatIcon,
		ChatBubbleIcon,
		CheckInIcon,
		FolderIcon,
		DocumentIcon,
		ProfileIcon,
		FaqIcon,
		PersonalIcon,
		ProfessionalIcon,
		NotificationIcon,
		LoginIcon,
		TechnicalIcon,
        TagIcon,
        SendIcon,
        TickIcon,
        PencilIcon,
        ContactIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title {
		display: flex;
		&.blue {
			&.alt {
				h1, .sub {
					color: $blue_dark;
				}
			}
			.circle {
				background: $blue_base;
			}
			h1 {
				color: $blue_dark;
			}
		}
		&.green {
			&.alt {
				h1, .sub {
					color: $green_dark;
				}
			}
			.circle {
				background: $green_base;
			}
			h1 {
				color: $green_dark;
			}
		}
		&.purple {
			&.alt {
				h1, .sub {
					color: $purple_dark;
				}
			}
			.circle {
				background: $purple_base;
			}
			h1 {
				color: $purple_dark;
			}
		}
		&.red {
			&.alt {
				h1, .sub {
					color: $red_dark;
				}
			}
			.circle {
				background: $red_base;
			}
			h1 {
				color: $red_dark;
			}
		}
		&.orange {
			&.alt {
				h1, .sub {
					color: $orange_dark;
				}
			}
			.circle {
				background: $orange_base;
			}
			h1 {
				color: $orange_dark;
			}
		}
		&.grey {
			&.alt {
				h1, .sub {
					color: $dark;
				}
			}
			.circle {
				background: $grey;
			}
			h1 {
				color: $dark;
			}
		}
		&.profile {
			&.alt {
				h1, .sub {
					color: $dark;
				}
			}
			h1 {
				color: $dark;
			}
			&.profile-blue {
				.circle {
					background: $blue_base;
				}
			}
			&.profile-cyan {
				.circle {
					background: $cyan_base;
				}
			}
			&.profile-yellow {
				.circle {
					background: $yellow_base;
				}
			}
			&.profile-red {
				.circle {
					background: $red_base;
				}
			}
			&.profile-purple {
				.circle {
					background: $purple_base;
				}
			}
		}
		.circle {
			height: 40px;
			width: 40px;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-items: center;
			flex-direction: column;
			margin-right: 12px;
			flex-shrink: 0;
			svg {
				flex: auto;
				g {
					stroke-width: 3;
				}
			}
		}
		h1 {
			font-size: 26px;
			text-align: left;
		}
		.section-title-img {
			&.check-in, &.chat-icon {
				path, g {
					stroke: $white;
					stroke-width: 3;
				}
				.word {
					stroke-width: 1;
					fill: $white;
				}
			}
		}
		.section-title-info {
			text-align: left;
		}
		&.alt {
			h1 {
				font-size: 17px;
				letter-spacing: -0.43px;
				line-height: 17px;
				color: $dark;
			}
			.sub {
				font-size: 17px;
				letter-spacing: -0.43px;
				line-height: 17px;
				color: $dark;
				opacity: 0.8;
			}
		}
	}
</style>
