<template>
	<div class="requests">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back pencil />
		</div><!-- header -->
		<div class="header-image green"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="TickIcon"
				title="Completed Requests"
				colour="green"
			/>
			<div class="section-gap">
				<div class="section-title-wrap">
					<div class="icon-split">
						<h2 class="section-title">Completed Requests</h2>
						<span>{{ count }}</span>
					</div>
				</div>
				<div class="request-card-wrap">
					<RequestCard
                        v-for="request in requests"
                        :key="request.id"
                        :id="request.id"
						:category="request.category"
						:title="request.category"
						:content="request.message"
						:location="request.port_name"
						:time="getTimeAgo(request.completed)"
						complete
						routeName="RequestsSingle"
					/>
				</div>
			</div>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import RequestCard from '@/components/RequestCard.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import UserService from '@/services/UserService';
    import moment from 'moment';
	export default {
		name: 'RequestsComplete',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			RequestCard
		},
		data () {
	        return {
                requests: [],
                count: 0
	        };
	    },
	    methods: {
            getRequests () {
                UserService.getCompletedRequests().then((response) => {
                    this.requests = response.data.requests;
                    this.count = response.data.count;
                });
            },
            getTimeAgo (date) {
                return moment(date).fromNow();
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getRequests();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
</style>
