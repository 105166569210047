import * as API from '@/services/API';
import store from '@/store';

export default {
    trackClick (payload) {
        return API.apiClient.post('/tracking', payload);
    },
    getLocation (payload) {
        return API.apiClient.post('/location', payload);
    }
};
