import * as API from '@/services/API';
import store from '@/store';

export default {
    logout () {
        return API.apiClient.post('/auth/logout').then(() => {
            store.commit('LOGOUT');
            API.apiClient.defaults.headers.Authorization = '';
        });
    },
    archiveAccount () {
        return API.apiClient.post('/auth/archive').then(() => {
            store.commit('LOGOUT');
            API.apiClient.defaults.headers.Authorization = '';
        });
    },
    deleteAccount () {
        return API.apiClient.post('/auth/delete').then(() => {
            store.commit('LOGOUT');
            API.apiClient.defaults.headers.Authorization = '';
        });
    },
    getUser () {
        return API.apiClient.get('/user');
    },
    updateUser (payload) {
        return API.apiClient.put('/auth/user/profile-information', payload);
    },
    updatePassword (payload) {
        return API.apiClient.put('/auth/user/password', payload);
    },
    createProfile (payload) {
        return API.apiClient.post('/user/profile', payload);
    },
    getProfile () {
        return API.apiClient.get('/user/profile');
    },
    updateProfile (payload) {
        return API.apiClient.put('/user/profile', payload);
    },
    getDashboard () {
        return API.apiClient.get('/user/dashboard');
    },
    getPublicDashboard () {
        return API.apiClient.get('/dashboard');
    },
    getPorts () {
        return API.apiClient.get('/user/ports');
    },
    getAllPorts () {
        return API.apiClient.get('/user/ports/all');
    },
    isPortFavourite (id) {
        return API.apiClient.get(`/user/ports/${id}`);
    },
    togglePort (id) {
        return API.apiClient.post('/user/ports', {
            id: id
        });
    },
    getCheckIn () {
        return API.apiClient.get('/user/check-in');
    },
    getCheckInRecent () {
        return API.apiClient.get('/user/check-in/recent');
    },
    getResources () {
        return API.apiClient.get('/user/resources');
    },
    isResourceFavourite (id) {
        return API.apiClient.get(`/user/resources/${id}`);
    },
    toggleResource (id) {
        return API.apiClient.post('/user/resources', {
            id: id
        });
    },
    createRequest (payload) {
        return API.apiClient.post('/user/requests', payload);
    },
    getRequests () {
        return API.apiClient.get('/user/requests');
    },
    getOpenRequests () {
        return API.apiClient.get('/user/requests/open');
    },
    getCompletedRequests () {
        return API.apiClient.get('/user/requests/completed');
    },
    getAllRequests () {
        return API.apiClient.get('/user/requests/all');
    },
    getRequest (id) {
        return API.apiClient.get(`/user/requests/${id}`);
    },
    cancelRequest (id) {
        return API.apiClient.get(`/user/requests/${id}/cancel`);
    },
    sendMessage (requestId, payload) {
        return API.apiClient.post(`/user/requests/${requestId}/message`, payload);
    },
    getNotifications () {
        return API.apiClient.get('/user/notifications');
    },
    sendTechnicalSupport (payload) {
        return API.apiClient.post('/user/technical-support', payload);
    },
    updateNotificationPreferences (payload) {
        return API.apiClient.post('/user/notification-preferences', payload);
    }
};
