<template>
	<div class="requests">
		<Nav v-if="!keyboardOpen"/>
		<span class="overlay fixed" :class="{active: isMenuOpen || isPortSearchModalOpen || isRequestConfirmModalOpen || isRequestSubmittedModalOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back/>
		</div><!-- header -->
		<div class="header-image green"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="PencilIcon"
				:title="form.category"
				subtitle="New Request"
				colour="green"
			/>
			<form class="form" @submit.prevent="">
				<div class="field field-icon" :class="{ 'field-error': $v.form.port.$error && $v.form.port.$invalid }">
					<label for="full-name-input" class="label">At what port?</label>
					<div class="control">
						<input @click.prevent="openSearchModal" id="full-name-input" class="input" type="text" placeholder="Select a Port" v-model="form.port" autocomplete="off">
						<AnchorIcon style="height: 21px;" class="icon icon-anchor" />
					</div>
					<span class="field-sub">Please select the port at which you would like your request to be made.</span>
				</div>
				<p v-if="categoryError" class="intro-text" style="background: #FEE2DB;">Sorry, this port is not currently accepting {{ form.category }} requests. </p>
		        <b-field class="with-sub field-date" label="For what date?" :class="{ 'field-error': $v.form.date.$error && $v.form.date.$invalid }">
			         <b-datepicker
                        placeholder="DD/MM/YYYY"
                        icon-right="calendar-day"
                        :mobile-native="true"
                        v-model="form.date"
                        :min-date="minDate"
                     >
                    </b-datepicker>
                     <span class="field-sub">When would you like this request to be actioned? Please ensure this is a date you are in port to accept delivery.</span>
			    </b-field>
		        <div class="field" :class="{ 'field-error': $v.form.vesselName.$error && $v.form.vesselName.$invalid }">
					<label for="full-name-input" class="label">Vessel Name</label>
					<div class="control">
						<input id="full-name-input" ref="vessel_name" class="input" type="text" placeholder="Enter your vessel name" v-model="form.vesselName" enterkeyhint="next" @keyup.enter="$refs.request.focus()" @keydown.enter.prevent @focus="scrollToInput('vessel_name')">
					</div>
				</div>
		        <b-field class="with-sub" label-for="request" label="What is your request?" :class="{ 'field-error': $v.form.request.$error && $v.form.request.$invalid }">
		            <b-input placeholder="Your request" type="textarea" id="request" ref="request" v-model="form.request" enterkeyhint="done" @keyup.enter.native="submitRequest" @focus="scrollToBottom"></b-input>
		             <span class="field-sub">Please explain your request in as much detail as possible</span>
		        </b-field>
				<b-field v-if="isTransportCategory" class="with-sub" label-for="pickup-location" label="Pickup Location" :class="{ 'field-error': $v.form.details.pickupLocation.$error && $v.form.details.pickupLocation.$invalid }">
					<b-input placeholder="Pickup Location" type="text" id="pickup-location" v-model="form.details.pickupLocation" enterkeyhint="next" @keyup.enter="$refs.delivery_location.focus()" @keydown.enter.prevent @focus="scrollToInput('pickup-location')"></b-input>
					<span class="field-sub">Please provide the pickup location</span>
				</b-field>
				<b-field v-if="isTransportCategory" class="with-sub" label-for="passenger-number" label="Number of Passengers" :class="{ 'field-error': $v.form.details.passengerCount.$error && $v.form.details.passengerCount.$invalid }">
					<b-input placeholder="Number of Passengers" type="number" id="passenger-number" v-model="form.details.passengerCount" enterkeyhint="next" @keyup.enter="$refs.delivery_location.focus()" @keydown.enter.prevent @focus="scrollToInput('passenger-number')"></b-input>
					<span class="field-sub">Please provide the number of passengers</span>
				</b-field>
		        <div class="flex-bottom" ref="bottom">
					<Button @click.native="submitRequest" icon="ArrowRightIcon" class="btn btn-med btn-tick btn-square btn-icon btn-icon-right" text="Review & Submit Request" :disabled="categoryError" :class="{ 'btn-disabled': categoryError }"></Button>
				</div><!-- flex bottom -->
			</form>
		</div>
		<PortSearchOverlay active/>
		<RequestSubmittedOverlay />
		<RequestConfirmationOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import Button from '@/components/Button.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import { required, requiredIf } from 'vuelidate/lib/validators';
	import AnchorIcon from '@/assets/anchor-black.svg';
	import PortSearchOverlay from '@/components/PortSearchOverlay.vue';
	import RequestSubmittedOverlay from '@/components/RequestSubmittedOverlay.vue';
	import RequestConfirmationOverlay from '@/components/RequestConfirmationOverlay.vue';
    import { Capacitor } from '@capacitor/core';
    import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
	import PortService from '@/services/PortService';
	export default {
		name: 'RequestsCategory',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Button,
			SectionTitle,
			AnchorIcon,
			PortSearchOverlay,
			RequestSubmittedOverlay,
			RequestConfirmationOverlay
		},
		data () {
	        return {
	        	form: {
					port: '',
                    portId: null,
					date: '',
					vesselName: '',
					request: '',
                    category: '',
					details: {
						pickupLocation: '',
						passengerCount: ''
					}
				},
				errorStyling: null,
                keyboardOpen: false,
                responseTime: 24,
                responseTimeShopping: 48,
				categoryError: false
	        };
	    },
	    validations () {
	    	return {
	    		form: {
	            	port: {
						required
					},
                    portId: {
                        required
                    },
					date: {
						required
					},
					vesselName: {
						required
					},
					request: {
						required
					},
					details: {
						pickupLocation: {
                            required: requiredIf(function (form) {
                                return this.form.category === 'Transport';
                            })
                        },
						passengerCount: {
                            required: requiredIf(function (form) {
                                return this.form.category === 'Transport';
                            })
                        }
					}
	           	}
	    	};
	    },
	    methods: {
			submitRequest () {
                this.$v.form.$touch();
                if (!this.$v.form.$error) {
                    this.$store.commit('REQUEST_CONFIRMATION_MODAL_TOGGLE', this.form);
                } else {
					this.errorStyling = true;
				}
            },
            openSearchModal () {
                this.$store.commit('PORT_SEARCH_TOGGLE');
            },
            scrollToInput (ref) {
                if (ref) {
                    this.$refs[ref].scrollIntoView({ behavior: 'smooth' });
                }
            },
            scrollToBottom () {
                this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
            },
			checkEnabledPortRequestTypes (portId) {
				PortService.getEnabledPortRequestTypes(portId).then((response) => {
					this.enabledPortRequestTypes = response.data;
					console.log([this.enabledPortRequestTypes, this.$route.params.id]);
					if (!this.enabledPortRequestTypes.includes(this.$route.params.id)) {
						this.categoryError = true;
					} else {
						this.categoryError = false;
					}
				});
			}
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
	        isPortSearchModalOpen () {
                return this.$store.state.isPortSearchModalOpen;
            },
            requestPort () {
                return this.$store.state.requestPort;
            },
            isRequestConfirmModalOpen () {
                return this.$store.state.isRequestConfirmationModalOpen;
            },
            isRequestSubmittedModalOpen () {
                return this.$store.state.isRequestSubmittedModalOpen;
            },
            minDate () {
                var date = new Date();
				if (!Capacitor.isNativePlatform()) {
					date.setDate(date.getDate() - 1);
				}

                if (this.form.category === 'Shopping') {
                    if (this.responseTimeShopping) {
                        date.setDate((date.getDate()) + (this.responseTimeShopping / 24));
                    } else {
                        date.setDate(date.getDate());
                    }
                } else {
                    if (this.responseTime) {
                        date.setDate((date.getDate()) + (this.responseTime / 24));
                    } else {
                        date.setDate(date.getDate());
                    }
                }
                return date;
            },
			isTransportCategory () {
				return this.form.category === 'Transport';
			}
		},
        watch: {
            requestPort (port) {
                this.form.port = port.name;
                this.form.portId = port.id;
                this.responseTime = port.responseTime;
                this.responseTimeShopping = port.responseTimeShopping;
				this.checkEnabledPortRequestTypes(port.id);
            }
        },
		mounted () {
            this.minDate.setDate(this.minDate.getDate() + 1);
            this.minDate.setHours(0, 0, 0, 0);
            switch (this.$route.params.id) {
                case 'shopping':
                    this.form.category = 'Shopping';
                    break;
                case 'visit':
                    this.form.category = 'Visit';
                    break;
                case 'contact':
                    this.form.category = 'Contact';
                    break;
                case 'transport':
                    this.form.category = 'Transport';
                    break;
                case 'justice-welfare':
                    this.form.category = 'Justice & Welfare';
                    break;
                default:
                    this.form.category = 'Other';
            }
            if (Capacitor.isNativePlatform()) {
                Keyboard.setAccessoryBarVisible({
                    isVisible: true
                });
                Keyboard.setResizeMode({
                    mode: KeyboardResize.Native
                });
                Keyboard.addListener('keyboardWillShow', () => {
                    this.keyboardOpen = true;
                });
                Keyboard.addListener('keyboardWillHide', () => {
                    this.keyboardOpen = false;
                });
            }
        },
        beforeRouteLeave: (to, from, next) => {
            if (to.name !== 'Port') {
                next();
            }
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.overlay.fixed {
		position: fixed;
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 30px;
		}
		.flex-bottom {
			margin-top: 30px;
			margin-bottom: 50px;
		}
		.icon-anchor {
			g, path {
				stroke-width: 3;
			}
		}
		textarea {
			height: 300px;
		}
	}

	.intro-text {
	margin-top: 20px;
	margin-bottom: 20px;
    padding: 18px;
	padding-left: 20px;
	padding-right: 20px;
    border-radius: 12px;
    background: #d9efff;
    color: #445d72;
    font-size: 15px;
}
</style>
