<template>
<div ref="modalResourceSearch" class="phone-modal phone-modal-port-search with-nav" style="bottom: -779px;" :class="{active: isResourceSearchModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<DocumentIcon style="width: 25px; height: auto;" class="icon anchor-icon" />
			<span v-if="title">{{ title }}</span>
			<span v-else>Search resources</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeResourceSearchModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="field field-icon field-icon-left">
		<div class="control">
			<input ref="searchInput" class="search-field" placeholder="Type to search..." type="text" v-model="query" enterkeyhint="search">
			<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
		</div>
	</div>
	<div class="section-gap section-port-list">
		<div class="section-title-wrap">
			<h2 v-if="show" class="section-title">{{ resources.count }} Result{{ resources.count && resources.count > 1 ? 's' : '' }}</h2>
		</div>
		<div v-if="show" class="card-row-wrap section-scroll">
			<CardRow
                v-for="resource in resources.results"
                :key="resource.id"
				routeName="ResourcesSingle"
                :id="resource.id"
				class="card-row-block-resources"
				:title="resource.title"
				:tag="resource.category"
				:type="resource.simplified_type"
                @click.native="closeResourceSearchModal"
			/>
		</div><!-- port card wrap -->
	</div>
</div><!-- modal -->
</template>

<script>
import DocumentIcon from '@/assets/document.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import SearchIcon from '@/assets/search.svg';
import CardRow from '@/components/CardRow.vue';
import ResourceService from '@/services/ResourceService';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
export default {
name: 'ResourceSearchOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
        return {
            delay: null,
            query: '',
            resources: [],
            show: false
        };
	},
    watch: {
        query () {
            clearTimeout(this.delay);
            this.delay = setTimeout(() => {
                if (this.query) {
                    ResourceService.search(this.query).then((response) => {
                        this.show = true;
                        this.resources = response.data;
                    });
                } else {
                    this.resources = [];
                    this.show = false;
                }
            }, 500);
        }
    },
	components: {
		DocumentIcon,
		GreyCloseIcon,
		SearchIcon,
		CardRow
	},
    computed: {
        isResourceSearchModalOpen () {
            if (this.$store.state.isResourceSearchModalOpen) {
                this.$refs.searchInput.focus();
                return true;
            }
            return false;
        }
    },
	methods: {
        closeResourceSearchModal () {
        	this.$store.commit('RESOURCE_SEARCH_TOGGLE');
        }
    },
    mounted () {
        if (Capacitor.isNativePlatform()) {
            Keyboard.setAccessoryBarVisible({
                isVisible: true
            });
            Keyboard.setResizeMode({
                mode: KeyboardResize.None
            });
            Keyboard.addListener('keyboardWillShow', () => {
                this.$refs.modalResourceSearch.style.height = '90%';
            });
            Keyboard.addListener('keyboardWillHide', () => {
                this.$refs.modalResourceSearch.style.height = 'unset';
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.phone-modal.phone-modal-port-search.active {
		top: 100px;
	}
	.section-title-wrap .section-title {
		color: $grey;
	}
	.section-scroll {
		overflow: scroll;
	    position: relative;
	    height: 500px;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $red_dark;
				}
			}
			span {
				color: $red_dark;
			}
		}
	}
    .search-field {
        padding: 0 50px !important;
    }
</style>
