import { PushNotifications } from '@capacitor/push-notifications';
import PortAdminService from '@/services/PortAdminService';

export default {
    
    async initialize () {
        console.log('Initializing push notifications');
        // Request permissions for push notifications
        const permission = await PushNotifications.requestPermissions();
        console.log('Push notification permission:', permission);
        if (permission.receive === 'granted') {
            await PushNotifications.register();

            // Handle successful registration
            PushNotifications.addListener('registration', async (token) => {
                console.log('Device registered with token:', token.value);

                // Save the token to the backend
                PortAdminService.storeDeviceToken({ token: token.value });

                PushNotifications.getDeliveredNotifications().then((notifications) => {
                    console.log('Delivered notifications:', notifications);
                });
            });

            // Handle registration errors
            PushNotifications.addListener('registrationError', (error) => {
                console.error('Registration error:', error);
            });

            // Handle received notifications
            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.log('Notification received:', notification);
                // Add logic to display the notification in the app
                const { title, body } = notification;

                // Function to display the notification
                function displayNotification (title, body, icon) {
                    if (window.Notification && Notification.permission === 'granted') {
                        Notification(title, {
                            body: body,
                            icon: icon
                        });
                    }
                }

                // Display the notification manually if the app is in the foreground
                displayNotification(title, body, notification.android?.smallIcon || 'assets/icon/favicon.png');
            });

            // Handle notification interactions
            PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                console.log('Notification action performed:', notification);
                // Navigate or handle the user's action
            });
        } else {
            console.warn('Push notification permissions not granted');
        }
    },

    async saveTokenToBackend (token) {
        try {
            await fetch('/api/device-token', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            });
            console.log('Token successfully saved to backend');
        } catch (error) {
            console.error('Error saving token to backend:', error);
        }
    }
};
