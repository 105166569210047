<template>
	<div class="port-edit-location-details">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="CheckInIcon"
				subtitle="Edit Port"
				title="Location Details"
				colour="profile profile-blue"
			/>
			<form class="form" @submit.prevent="">
				<div class="field" :class="{ 'field-error': $v.form.name.$error || errorStyling == true }">
					<label for="name-input" class="label">Name</label>
					<div class="control">
						<input id="name-input" class="input" type="text" v-model="form.name">
					</div>
				</div>
				<div class="field" :class="{ 'field-error': $v.form.country.$error || errorStyling == true }">
					<label for="country-input" class="label">Country</label>
					<div class="control">
						<input id="country-input" class="input" type="text" v-model="form.country">
					</div>
				</div>
				<div class="field" :class="{ 'field-error': $v.form.continent.$error || errorStyling == true }">
					<label for="continent-input" class="label">Continent</label>
					<div class="control">
						<input id="continent-input" class="input" type="text" v-model="form.continent">
					</div>
				</div>
				<!-- <div class="field">
					<label for="region-input" class="label">Region</label>
					<div class="control">
						<input id="region-input" class="input" type="text" v-model="form.region">
					</div>
				</div> -->
				<b-field label-for="region-input" label="Region" :class="{ 'field-error': $v.form.region_id.$error || errorStyling == true }">
		            <b-select id="region-input" v-model="form.region_id">
						<option v-for="region in this.regions" :key="region.id" :value="region.id">{{ region.name }}</option>
		            </b-select>
		        </b-field>
				<b-field label-for="address-input" label="Address" :class="{ 'field-error': $v.form.address.$error || errorStyling == true }">
		            <b-input placeholder="" type="textarea" id="address-input" v-model="form.address"></b-input>
		        </b-field>
				<b-field label-for="timezone-input" label="Timezone" :class="{ 'field-error': $v.form.timezone.$error || errorStyling == true }">
		            <b-select id="timezone-input" v-model="form.timezone">
                        <option value="1">Europe/Dublin (GMT +0)</option>
                        <option value="2">Europe/Dublin (GMT +0)</option>
                        <option value="3">Europe/Dublin (GMT +0)</option>
		            </b-select>
		        </b-field>
		        <b-field label-for="description-input" label="Description" :class="{ 'field-error': $v.form.description.$error || errorStyling == true }">
		            <b-input placeholder="" type="textarea" id="description-input" v-model="form.description"></b-input>
		        </b-field>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Please make sure all fields are filled in.</span>
				</div>
				<div class="flex-bottom">
					<Button @click.native="updateLocationDetails" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Save changes"></Button>
				</div><!-- flex bottom -->
			</form>
		</div>
		<Menu />
	</div>
</template>
<script>
	    import HeaderNavBar from '@/components/HeaderNavBar.vue';
    import Button from '@/components/Button.vue';
    import { required } from 'vuelidate/lib/validators';
    import Nav from '@/components/Nav.vue';
    import NewsCard from '@/components/NewsCard.vue';
    import CardRow from '@/components/CardRow.vue';
    import Menu from '@/components/Menu.vue';
    import SectionTitle from '@/components/SectionTitle.vue';
    import UserService from '@/services/UserService';
    import PortService from '@/services/PortService';
    import RegionService from '@/services/RegionService';

    export default {
        name: 'MyProfile',
        components: {
            Button,
            Nav,
            HeaderNavBar,
            Menu,
            SectionTitle
        },
        data () {
            return {
            	form: {
                    name: null,
                    country: null,
                    continent: null,
                    region_id: null,
                    address: null,
                    timezone: null,
                    description: null
                },
                errorMessage: false,
                errorStyling: false,
                regions: [],
				port_id: null
            };
        },
        validations () {
        	return {
        		form: {
                	name: { required },
                	country: { required },
                	continent: { required },
					region_id: { required },
                	address: { required },
                	timezone: { required },
                	description: { required }
               	}
        	};
        },
        methods: {
            updateLocationDetails () {
                var self = this;
                this.$v.form.$touch();
                if (!this.$v.form.$error) {
                    try {
                        PortService.updatePort(this.port_id, {
							name: this.form.name,
							country: this.form.country,
							continent: this.form.continent,
							region_id: this.form.region_id,
							address: this.form.address,
							timezone: this.form.timezone,
							description: this.form.description
						}).then((response) => {
                        });
                    } catch (error) {
                        console.log(error);
                        this.errorMessage = true;
                        this.errorStyling = true;
                    }
                }
            },
            getRegions () {
                return RegionService.getRegions().then((response) => {
                    this.regions = response.data;
                });
            },
            fetchPort () {
                return PortService.getPort(this.$route.params.id).then((response) => {
                    this.form = response.data;
					this.port_id = response.data.id;
                    console.log(this.form.name);
                    if (response.data.user) {
                        this.myPorts = response.data.user;
                    }
                    if (this.isAuthenticated) {
                        UserService.isPortFavourite(this.$route.params.id).then((response) => {
                            this.myPorts = response.data;
                        });
                    }
                });
            }
        },
        computed: {
            isMenuOpen () {
                return this.$store.state.isMenuOpen;
            }
        },
        mounted () {
            this.fetchPort();
			this.getRegions();
        }
    };
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
</style>
