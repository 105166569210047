import axios from 'axios';
import store from '@/store';
import * as API from '@/services/API';

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true
});

export default {
    login (payload) {
        return authClient.post('/auth/login', payload).then((response) => {
            console.log('Login response:', response);

            if (response.data.includes('2FA code sent')) {
                // Show the 2FA form if a 2FA code is required
                store.commit('SHOW_2FA_PROMPT', true);
                store.commit('SET_EMAIL', payload.email); // Store email for 2FA verification
                return response.data;
            } else {
                // Successful login: store token and set Authorization header
                store.commit('LOGIN', response.data);
                API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
                return response.data;
            }
        }).catch(error => {
            // Handle login errors here
            console.error('Login failed:', error);
        });
    },
    register (payload) {
        return authClient.post('/auth/register', payload).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    },
    oauth (provider, code) {
        return authClient.get(`/auth/${provider}/callback?code=${code}`).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    },
    apple (payload) {
        return authClient.post('/auth/apple', payload).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    },
    forgotPassword (payload) {
        return authClient.post('/auth/user/forgot-password', payload);
    },
    resetPassword (payload) {
        return authClient.post('/auth/user/reset-password', payload);
    }
};
