import axios from 'axios';
import store from '@/store';
import router from '@/router';

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
        Authorization: 'Bearer ' + store.state.token
    }
});

apiClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        store.commit('LOGOUT');
    }
    return Promise.reject(error);
});
