<template>
<div ref="modalRequestSubmitted" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isRequestSubmittedModalOpen ? 1 : 0, bottom: modalRequestSubmittedHeight + 'px'}" :class="{active: isRequestSubmittedModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<CheckInIcon style="width: 25px; height: auto;" class="icon checkin-icon" />
			<span>Request Submitted</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeRequestSubmittedModal()" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="modal-message-block">
		<SuccessIcon class="success-icon" style="width: 90px; height: 90px;" />
		<h3>Your request has been submitted</h3>
		<p>You can check for updates or amend your request at any time.</p>
	</div>
	<Button icon="SendIcon" class="none-bottom btn btn-green btn-tick btn-icon btn-icon-right" text="Return to Requests" routeName="/requests"></Button>
	<Button icon="HomeIcon" class="none-bottom btn btn-grey btn-tick btn-icon btn-icon-right" text="Return Home" @click.native="closeRequestSubmittedModal" routeName="/dashboard"></Button>
</div><!-- modal -->
</template>

<script>
import Button from '@/components/Button.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import CheckInIcon from '@/assets/check-in.svg';
import SuccessIcon from '@/assets/success-filled.svg';
export default {
name: 'RequestSubmittedOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			modalRequestSubmittedHeight: null
		};
	},
	components: {
		Button,
		GreyCloseIcon,
		CheckInIcon,
		SuccessIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalRequestSubmitted.clientHeight;
		},
        isRequestSubmittedModalOpen () {
            return this.$store.state.isRequestSubmittedModalOpen;
        },
        checkIn () {
            return this.$store.state.checkIn;
        }
	},
	methods: {
        closeRequestSubmittedModal () {
        	this.$store.state.isRequestSubmittedModalOpen = false;
            this.$router.push({ name: 'Requests' });
        }
    },
    mounted () {
		this.modalRequestSubmittedHeight = this.matchHeightModal;
	},
    destroyed () {
        this.$store.state.isRequestSubmittedModalOpen = false;
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $green_dark;
				}
			}
			span {
				color: $green_dark;
			}
		}
	}
	.modal-message-block {
		svg path {
			fill: $green_base;
		}
	}
</style>
