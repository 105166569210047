import * as API from '@/services/API';

export default {
    getPorts () {
        return API.apiClient.get('/ports');
    },
    getPort (id) {
        return API.apiClient.get(`/ports/${id}`);
    },
    search (query) {
        return API.apiClient.get(`/ports/search/${query}`);
    },
    searchActive (query) {
        return API.apiClient.get(`/ports/search-active/${query}`);
    },
    checkIn (id) {
        return API.apiClient.get(`/ports/${id}/check-in`);
    },
    updatePort (id, payload) {
        return API.apiClient.put(`/ports/${id}`, payload);
    },
    getPortContactDetails (id) {
        return API.apiClient.get(`/ports/${id}/contact-info`);
    },
    updatePortContactDetails (id, payload) {
        return API.apiClient.put(`/ports/${id}/contact-info`, payload);
    },
    getPortResponseTimes (id) {
        return API.apiClient.get(`/ports/${id}/response-times`);
    },
    updatePortResponseTimes (id, payload) {
        return API.apiClient.put(`/ports/${id}/response-times`, payload);
    },
    requestPortAdmin (payload) {
        return API.apiClient.post('/admin-requests', payload);
    },
    getPortPermissions (id) {
        return API.apiClient.get(`/ports/${id}/permissions`);
    },
    getEnabledPortRequestTypes (id) {
        return API.apiClient.get(`/ports/${id}/enabled-request-types`);
    },
    updatePortAcceptedRequests (id, payload) {
        return API.apiClient.put(`/ports/${id}/accepted-requests`, payload);
    }
};
