import * as API from '@/services/API';
import store from '@/store';

export default {
    getRequests () {
        return API.apiClient.get('/port-admin/requests');
    },
    getOpenRequests () {
        return API.apiClient.get('/user/requests/open');
    },
    getCompletedRequests () {
        return API.apiClient.get('/user/requests/completed');
    },
    getAllRequests () {
        return API.apiClient.get('/user/requests/all');
    },
    getRequest (id) {
        return API.apiClient.get(`/port-admin/requests/${id}`);
    },
    cancelRequest (id) {
        return API.apiClient.get(`/user/requests/${id}/cancel`);
    },
    sendMessage (requestId, payload) {
        return API.apiClient.post(`/port-admin/requests/${requestId}/message`, payload);
    },
    getNotifications () {
        return API.apiClient.get('/user/notifications');
    },
    sendTechnicalSupport (payload) {
        return API.apiClient.post('/user/technical-support', payload);
    },
    updateNotificationPreferences (payload) {
        return API.apiClient.post('/user/notification-preferences', payload);
    },
    getPortAdmins ($portId) {
        return API.apiClient.get('/port-admins/' + $portId);
    },
    reassignRequestPortAdmin (requestId, payload) {
        return API.apiClient.post(`/port-admin/requests/${requestId}/reassign`, payload);
    },
    markRequestComplete (requestId) {
        return API.apiClient.post(`/port-admin/requests/${requestId}/complete`);
    },
    reopenRequest (requestId) {
        return API.apiClient.post(`/port-admin/requests/${requestId}/reopen`);
    },
    deleteRequest (requestId) {
        return API.apiClient.delete(`/port-admin/requests/${requestId}`);
    },
    storeDeviceToken (payload) {
        return API.apiClient.post('/user/device-token', payload);
    }
};
