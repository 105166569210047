<template>
	<router-link class="pa-request" :to="{ name: routeName, params: { id: id } }" :class="{new: newMessage, complete: complete}">
		<div class="pa-request-split">
			<div class="main">
				<h4>{{ title }}</h4>
			</div>
			<div class="date">
				<span>{{ date }}</span>
			</div>
		</div>
		<div class="info">
			<div class="info-block info-block-port">
				<AnchorIcon style="width: 15px;" class="anchor-icon" />
				<span>{{ port }}, {{ country }}</span>
			</div>
			<div class="info-block info-block-cat">
				<BasketIcon style="width: 16px;" class="shopping-icon" v-if="category === 'Shopping'" />
				<PositionIcon style="width: 16px;" v-if="category === 'Visit'" />
				<LoveIcon style="width: 16px;" v-if="category === 'Contact'" />
				<CarIcon style="width: 16px;" v-if="category === 'Transport'" />
				<ScaleIcon style="width: 16px;" v-if="category === 'Justice & Welfare'" />
				<QuestionIcon style="width: 16px;" v-if="category === 'Other'" />
				<span>{{ category }}</span>
			</div>
		</div>
		<div class="pa-content">
			<p>{{ content }}</p>
		</div>
		<div v-if="(assigned || completed) && assignable_name" class="info-block info-block-port pa-assigned">
			<ProfileIcon style="width: 15px;" class="anchor-icon" />
			<span>Assigned to: {{ assignable_name }}</span>
		</div>
	</router-link>

</template>

<script>
import NewMessageIcon from '@/assets/new-message.svg';
import ReadMessageIcon from '@/assets/read-message.svg';
import AnchorIcon from '@/assets/anchor-black.svg';
import BasketIcon from '@/assets/basket.svg';
import TickIcon from '@/assets/tick.svg';
import PositionIcon from '@/assets/position.svg';
import LoveIcon from '@/assets/love.svg';
import CarIcon from '@/assets/car.svg';
import ScaleIcon from '@/assets/scale.svg';
import QuestionIcon from '@/assets/question.svg';
import ProfileIcon from '@/assets/profile-plus.svg';

export default {
	name: 'RequestCard',
	props: {
		title: String,
		category: String,
		content: String,
		port: String,
		country: String,
		date: String,
		newMessage: Boolean,
		complete: Boolean,
		assigned: Boolean,
		sent: Boolean,
		routeName: String,
        id: Number,
		assignable_name: String
	},
	components: {
		AnchorIcon,
		BasketIcon,
		PositionIcon,
		LoveIcon,
		CarIcon,
		ScaleIcon,
		QuestionIcon,
		ProfileIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.pa-assigned {
		margin-top: 18px;
		p {
			font-size: 13px;
		    font-style: normal;
		    font-weight: 400;
		    line-height: 18px;
		    letter-spacing: -0.5px;
		    color: #475668;
		}
	}
	.pa-request {
		position: relative;
		display: block;
		padding: 26px 26px 26px 18px;
		border-left: 8px solid $white;
		border-bottom: 1px solid #D3DAE2;
		min-height: 173px;
		color: $slate;
		&:first-of-type {
			border-radius: 36px 36px 0 0;
		}
		&.new {
			border-left-color: $green_dark;
			background: #F8FDFA;
			.main h4 {
				color: $green_dark;
				font-weight: 600;
			}
			.date {
				span {
					color: $green_dark;
					font-weight: 600;
				}
				svg path {
					stroke: $green_dark;
				}
				.tick-icon path {
					stroke: green_base;
				}
			}
			.info-block {
				span {
					font-weight: 600;
				}
			}
		}
		&.complete {
			.main h4 {
				color: $slate;
			}
			.date {
				span {
					color: #A6B1BF;
				}
				svg path, svg circle {
					stroke: $green_base;
				}
			}
			.info-block {
				span {
					color: #A6B1BF;
				}
				svg path {
					stroke: #A6B1BF;
				}
			}
			.pa-content {
				p {
					color: #A6B1BF;
				}
			}
		}
		.main {
			h4 {
				font-size: 17px;
				font-weight: 400;
				color: $dark;
				letter-spacing: -0.5px;
				overflow: hidden;
			    text-overflow: ellipsis;
			    display: -webkit-box;
			    -webkit-line-clamp: 3;
			    -webkit-box-orient: vertical;
			}
		}
		.info {
			margin-top: 8px;
			display: flex;
			align-items: center;
		}
		.info-block {
			display: flex;
			align-items: center;
			margin-right: 10px;
			svg {
				margin-right: 6px;
				flex-shrink: 0;
				g {
					stroke: $slate;
					stroke-width: 3;
				}
			}
			span {
				font-size: 13px;
				font-weight: 400;
				letter-spacing: -0.5px;
			}
		}
		.date {
			display: flex;
			align-items: center;
			margin-top: 3px;
			margin-left: 15px;
			span {
				font-size: 13px;
				font-weight: 400;
				letter-spacing: -0.5px;
			}
			svg {
				margin-left: 8px;
				g {
					stroke: $slate;
					stroke-width: 3;
				}
			}
		}
	}
	.pa-request-split {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.pa-content {
		overflow: hidden;
	    text-overflow: ellipsis;
	    display: -webkit-box;
	    -webkit-line-clamp: 3;
	    -webkit-box-orient: vertical;
	    margin-top: 12px;
			p {
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.5px;
			color: $slate;
		}
	}
</style>
