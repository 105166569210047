import { render, staticRenderFns } from "./ResourcesMy.vue?vue&type=template&id=18951ffc&scoped=true"
import script from "./ResourcesMy.vue?vue&type=script&lang=js"
export * from "./ResourcesMy.vue?vue&type=script&lang=js"
import style0 from "./ResourcesMy.vue?vue&type=style&index=0&id=18951ffc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18951ffc",
  null
  
)

export default component.exports