<template>
<div ref="modalPortAdminRequestConfirmation" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortAdminRequestConfirmationModalOpen ? 1 : 0, bottom: modalPortAdminRequestConfirmationHeight + 'px'}" :class="{active: isPortAdminRequestConfirmationModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<SendIcon style="width: 27px; height: auto;" class="icon checkin-icon" />
			<span>{{ title }}</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortAdminModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->

	<div v-if="!additionalData.status === 'assign'" class="modal-message-block alt">
		<InfoIcon class="success-icon" style="width: 80px; height: 80px; margin-bottom: 0;" />
	</div>

	<div class="section-gap">
		<p class="dark">{{ message }}</p>
	</div>

	<div v-if="additionalData.status === 'assign'" class="section-gap port-admin-assign">
		<form class="form" @submit.prevent="">
			<div class="field disabled">
				<label for="name-input" class="label">Current assigned port admin</label>
				<div class="control">
					<input id="name-input" class="input" type="text" v-model="form.currentAssignee">
				</div>
			</div>
			<b-field label-for="timezone-input" label="Select new port admin" :class="{ 'field-error': $v.form.newAssignee.$error || errorStyling == true }">
	            <b-select id="timezone-input" v-model="form.newAssignee">
					<option v-for="portAdmin in portAdmins" :key="portAdmin.id" :value="portAdmin.id">{{ portAdmin.name }}</option>
	            </b-select>
	        </b-field>
		</form>
	</div>

	<div v-if="additionalData.status === 'delete'" class="flex-bottom">
		<Button icon="ArrowRightIcon" class="section-gap none-bottom btn btn-red icon-white btn-icon btn-icon-right btn-square" text="Delete Request" @click.native="deleteRequest"></Button>
		<Button class="none-bottom btn btn-med btn-snow btn-square btn-icon" text="Keep open" @click.native="closePortAdminModal"></Button>
	</div>
	<div v-if="additionalData.status === 'complete'" class="flex-bottom">
		<Button icon="ArrowRightIcon" class="section-gap none-bottom btn btn-green icon-white btn-icon btn-icon-right btn-square" text="Confirm" @click.native="markRequestComplete"></Button>
		<Button routeName="/dashboard" class="none-bottom btn btn-med btn-snow btn-square btn-icon" text="Cancel" @click.native="closePortAdminModal"></Button>
	</div><!-- flex bottom -->
	<div v-if="additionalData.status === 'assign'" class="flex-bottom">
		<Button icon="ArrowRightIcon" class="section-gap none-bottom btn btn-green icon-white btn-icon btn-icon-right btn-square" text="Confirm" @click.native="reassignRequestPortAdmin"></Button>
		<Button routeName="/dashboard" class="none-bottom btn btn-med btn-snow btn-square btn-icon" text="Cancel" @click.native="closePortAdminModal"></Button>
	</div><!-- flex bottom -->
	<div v-if="additionalData.status === 'reopen'" class="flex-bottom">
		<Button icon="ArrowRightIcon" class="section-gap none-bottom btn btn-green icon-white btn-icon btn-icon-right btn-square" text="Confirm" @click.native="reopenRequest"></Button>
		<Button routeName="/dashboard" class="none-bottom btn btn-med btn-snow btn-square btn-icon" text="Cancel" @click.native="closePortAdminModal"></Button>
	</div><!-- flex bottom -->

</div><!-- modal -->
</template>

<script>
import GreyCloseIcon from '@/assets/close-grey.svg';
import SendIcon from '@/assets/send.svg';
import InfoIcon from '@/assets/info.svg';
import Button from '@/components/Button.vue';
import { required } from 'vuelidate/lib/validators';
import PortAdminService from '@/services/PortAdminService';
export default {
name: 'PortContactOverlay',
	props: {
		subtitle: String,
		title: String,
		message: String,
		icon: String,
		delete: Boolean
	},
	data () {
		return {
			modalPortAdminRequestConfirmationHeight: null,
			overlayTitle: '',
      		overlayMessage: '',
      		form: {
      			currentAssignee: 'Unassigned',
      			newAssignee: ''
      		},
			port_id: null,
			portAdmins: []
		};
	},
	validations () {
    	return {
    		form: {
            	newAssignee: {
            		required
            	}
           	}
    	};
    },
	components: {
		GreyCloseIcon,
		SendIcon,
		InfoIcon,
		Button
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortAdminRequestConfirmation.clientHeight;
		},
        isPortAdminRequestConfirmationModalOpen () {
            return this.$store.state.isPortAdminRequestConfirmationModalOpen;
        },
        additionalData () {
	        return this.$store.state.additionalData || {};
	    }
	},
	methods: {
        closePortAdminModal () {
        	this.$store.commit('TOGGLE_PORT_ADMIN_REQUEST_CONFIRMATION_MODAL', {
            	isOpen: false,
            	title: '',
            	message: '',
            	additionalData: null
        	});
        },
        openModal (title, message, additionalData) {
        	this.closePortAdminModal();
			this.$store.commit('TOGGLE_PORT_ADMIN_REQUEST_SUBMITTED_MODAL', {
				isOpen: true,
				title,
				message,
				additionalData: additionalData || {}
			});
		},
		closeModal () {
			this.$store.commit('TOGGLE_PORT_ADMIN_REQUEST_SUBMITTED_MODAL', {
				isOpen: false,
				additionalData: null
			});
		},
		getRequestData () {
			PortAdminService.getRequest(this.$route.params.id).then((response) => {
				this.form.currentAssignee = response.data.information?.assignable?.name || 'Unassigned';
				this.port_id = response.data.information.port_id;
				this.getPortAdmins(this.port_id);
			});
		},
		getPortAdmins () {
			PortAdminService.getPortAdmins(this.port_id).then((response) => {
				this.portAdmins = response.data;
			});
		},
		reassignRequestPortAdmin () {
			PortAdminService.reassignRequestPortAdmin(this.$route.params.id, { port_admin_id: this.form.newAssignee }).then((response) => {
				this.closePortAdminModal();
				this.openModal('Assign To', 'This request has been successfully reassigned');
			}).then(() => {
				this.getRequestData();
				this.$emit('request-updated');
			});
		},
		markRequestComplete () {
			PortAdminService.markRequestComplete(this.$route.params.id).then((response) => {
				this.closePortAdminModal();
				this.openModal('Mark as Complete', 'This request has been successfully marked as completed');
			}).then(() => {
				this.getRequestData();
				this.$emit('request-updated');
			});
		},
		reopenRequest () {
			PortAdminService.reopenRequest(this.$route.params.id).then((response) => {
				this.closePortAdminModal();
				this.openModal('Reopen Request', 'This request has been successfully reopened');
			}).then(() => {
				this.getRequestData();
				this.$emit('request-updated');
			});
		},
		deleteRequest () {
			PortAdminService.deleteRequest(this.$route.params.id).then((response) => {
				this.closePortAdminModal();
				this.openModal('Delete Request', 'This request has been successfully deleted', { status: 'delete' });
			}).then(() => {
				this.getRequestData();
				this.$emit('request-updated');
			});
		}
    },
    mounted () {
    	this.modalPortAdminRequestConfirmationHeight = '-500';
		this.getRequestData();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-admin-assign {
		.field {
			&.disabled {
				input {
					background: $snow;
				}
			}
		}
	}
	.port-admin-details {
		text-align: left;
		.col {
			margin-bottom: 20px;
		}
		.p-strong {
			color: $dark;
			font-weight: 500;
		}
	}
	p.dark {
		text-align: left;
		color: $dark;
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
	.modal-message-block {
		&.alt {
			margin-top: 35px;
		}
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $dark;
				}
				.p {
					stroke-width: 0;
				}
			}
			span {
				color: $dark;
			}
		}
		.close {
			g, path {
				stroke-width: 2.5;
				stroke: $dark;
			}
		}
	}
	.flex-bottom {
		.btn-green, .btn-red {
			margin-bottom: 15px;
		}
	}
	.modal-message-block {
		svg path {
			// fill: $green_base;
		}
	}
</style>
