<template>
	<div class="port-edit">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="AnchorIcon"
				subtitle="Edit Port"
				:title="this.port.name"
				colour="profile profile-blue"
			/>
			<div class="profile-blocks section-gap">
				<router-link :to="{ name: 'EditPortLocationDetails' }" class="profile-block">
					<CheckInIcon style="width: 34px;" class="profile-block-img" />
					<div class="profile-block-info">
						<h1>Location details</h1>
						<span class="sub">Name, country, address</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'EditPortContactDetails' }" class="profile-block">
					<ContactIcon style="width: 34px;" class="profile-block-img" />
					<div class="profile-block-info">
						<h1>Contact details</h1>
						<span class="sub">Opening times, telephone</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'EditPortResponseTimes' }" class="profile-block">
					<ChatIcon style="width: 34px;" class="profile-block-img" />
					<div class="profile-block-info">
						<h1>Response times</h1>
						<span class="sub">Change each response time</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'EditPortStaff' }" class="profile-block">
					<ProfileIcon style="width: 34px;" class="profile-block-img" />
					<div class="profile-block-info">
						<h1>Staff</h1>
						<span class="sub">Admin management</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'EditPortAcceptedRequests' }" class="profile-block">
					<ProfileIcon style="width: 34px;" class="profile-block-img" />
					<div class="profile-block-info">
						<h1>Requests</h1>
						<span class="sub">Update Port Request Types</span>
					</div>
				</router-link>
			</div>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import CheckInIcon from '@/assets/check-in.svg';
	import ContactIcon from '@/assets/contact.svg';
	import ChatIcon from '@/assets/chat-bubble.svg';
	import ProfileIcon from '@/assets/profile.svg';
    import UserService from '@/services/UserService';
	import PortService from '@/services/PortService';
	export default {
		name: 'MyProfile',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			CheckInIcon,
			ContactIcon,
			ChatIcon,
			ProfileIcon
		},
		data () {
	        return {
				port: {}
	        };
	    },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
			this.getPort();
        },
		methods: {
			getPort () {
                return PortService.getPort(this.$route.params.id).then((response) => {
                    this.port = response.data;
					console.log(this.port.name);
                    if (response.data.user) {
                        this.myPorts = response.data.user;
                    }
                    if (this.isAuthenticated) {
                        UserService.isPortFavourite(this.$route.params.id).then((response) => {
                            this.myPorts = response.data;
                        });
                    }
                });
            }
		}
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.profile-blocks {
		margin-bottom: 100px;
		.profile-block {
			display: block;
			text-align: left;
			border-radius: 12px;
			border: 1px solid $snow;
			box-shadow: 5px 15px 15px 5px $snow;
			padding: 20px;
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			h1 {
				font-size: 17px;
				letter-spacing: -0.43px;
				line-height: 17px;
				color: $dark;
				margin-bottom: 5px;
			}
			.sub {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 14px;
				color: $slate;
				display: block;
			}
		}
		.profile-block-img {
			flex-shrink: 0;
			display: block;
			margin-right: 16px;
		}
	}
</style>
