<template>
	<div class="requests" :class="{portAdminStyles: isPortAdmin}">
		<transition name="fade">
			<div class="sticky-header" v-show="stickyHeader" :class="{active: stickyHeader}">
				<div v-if="isPortAdmin" class="sticky-inner space-between">
					<div class="sticky-inner-wrap">
						<a @click="$router.go(-1)" href="#" class="sticky-arrow">
							<ArrowLeft style="width: 27px;" />
						</a>
						<div class="sticky-title">
							<h3>{{ information.category }}</h3>
							<div class="icon-wrap">
								<AnchorIcon style="width: 14px;" />
								<span>{{ information.port ? information.port.name : '' }}</span>
							</div>
						</div>
					</div>
					<div class="icon-hold">
						<a @click.prevent="openModalConfirmation('Mark as Complete', 'Are you sure you want to mark this request as complete?', { status: 'complete' })">
							<TickIcon class="tick-icon" style="width: 29px;" />
						</a>
						<a @click.prevent="tabTwo">
							<InfoIcon class="info-icon" style="width: 29px;" />
						</a>
					</div>
				</div>
				<div v-else class="sticky-inner">
					<a @click="$router.go(-1)" href="#" class="sticky-arrow">
						<ArrowLeft style="width: 27px;" />
					</a>
					<div class="sticky-title">
						<h3>{{ information.category }}</h3>
						<div class="icon-wrap">
							<AnchorIcon style="width: 14px;" />
							<span>{{ information.port ? information.port.name : '' }}</span>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="nav-wrap" v-if="tabOneActive">
			<div v-if="information.open" class="input-bar">
				<ArrowLeftIcon
					style="width: 12px;"
					v-if="typing"
				/>
				<ImageIcon
                    v-else
					style="width: 25px;"
                    @click="$refs.upload.click()"
				/>
                <input ref="upload" type="file" accept="image/*" hidden @change="upload">
				<input
					class="input"
					type="text"
					:placeholder="file ? file.name : 'Type your message...'"
					v-model="message"
					@focus="typing = true"
					@blur="typing = false"
                    @keyup.enter="sendMessage"
				/>
				<SendIcon
					style="width: 25px;"
					class="svg-right"
                    v-if="message.length"
                    @click="sendMessage"
				/>
			</div>
			<Nav v-if="!keyboardOpen"/>
		</div>
		<Nav v-if="tabTwoActive && !keyboardOpen" />
		<span class="overlay overlay-alt" :class="{active: isMenuOpen || isPortAdminRequestConfirmationModalOpen || isPortAdminRequestSubmittedModalOpen }"></span>
		<div class="header-wrap">
			<div class="header header-standard">
				<HeaderNavBar back />
				<div class="request-header">
					<h1>{{ information.category }}</h1>
					<div class="request-header-port">
						<AnchorIcon style="width: 15px; height: auto;" class="icon anchor-icon" />
						<span>{{ information.port ? information.port.name : '' }}</span>
					</div>
					<div v-if="isPortAdmin" class="request-nav request-nav-port-admin">
						<a @click="tabTwo" :class="{active: tabTwoActive}" href="#">Information</a>
						<a @click="tabOne" :class="{active: tabOneActive}" href="#">Messages</a>
					</div>
					<div v-else class="request-nav">
						<a @click="tabOne" :class="{active: tabOneActive}" href="#">Messages</a>
						<a @click="tabTwo" :class="{active: tabTwoActive}" href="#">Information</a>
                        <a @click="cancelRequest()" style="float: right;">Cancel Request</a>
					</div>
				</div>
			</div><!-- header -->
		</div>
		<div class="chat-window">
			<div class="chat-wrap" v-if="tabOneActive">
				<div id="messages" class="chat-date-wrap">
					<span class="eyebrow date">{{ information.created_at | moment('Do MMMM YYYY') }}</span>
                    <div class="chat-group" :class="{ 'sent': !isPortAdmin }">
                        <div class="chat-row">
                            <div class="chat-bubble-wrap">
                                <div class="chat-bubble">
                                    <p>{{ information.message }}</p>
                                </div>
                                <span class="time">{{ information.created_at | moment('HH:mm') }}</span>
                            </div>
                        </div>
                    </div>
					<div
                        v-for="(message, index) in messages"
                        :key="message.id"
                        class="chat-group"
						:class="{ 'sent': isPortAdmin ? message.user_id !== information.user_id : message.user_id === information.user_id }"                       :ref="index === (messages.length - 1) ? 'scrollTo' : ''"
                    >
						<div class="chat-row">
							<div class="chat-bubble-wrap">
								<div class="chat-bubble">
                                    <img v-if="message.file && message.file.path" :src="getFilePath(message.file.path)" :alt="message.file.name"/>
									<p v-else>{{ message.content }}</p>
								</div>
								<span class="time">{{ message.created_at | moment('HH:mm') }}</span>
							</div>
						</div>
					</div>
				</div><!-- date wrap -->
				<div v-if="!information.open && information.completed && !isPortAdmin" class="chat-complete">
					<TickIcon style="width: 45px; height: auto;" class="icon tick-icon" />
					<p>This request was marked as completed on the <strong>{{ information.completed | moment('Do MMMM YYYY') }}</strong> at <strong>{{ information.completed | moment('HH:mm') }}</strong></p>
				</div>
				<div class="chat-complete" v-if="!information.open && information.completed && isPortAdmin">
					<TickIcon style="width: 45px; height: auto;" class="icon tick-icon" />
					<p>This request was marked as completed on the <strong>{{ information.completed | moment('Do MMMM YYYY') }}</strong> at <strong>{{ information.completed | moment('HH:mm') }}</strong> by <strong>{{ information.completed_by.name }}</strong></p>
				</div>
			</div><!-- chat wrap -->

			<!-- Seafarer Request Details -->
			<div class="information-wrap" v-if="tabTwoActive && !isPortAdmin">
				<div class="info-card">
					<h3>Your Request</h3>
					<div class="info-card-wrap">
						<div class="row">
							<div class="col full">
								<span class="eyebrow">Type</span>
								<p class="p-strong">{{ information.category }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="eyebrow">Port</span>
			                    <p class="p-strong">{{ information.port ? information.port.name : '' }}</p>
							</div>
							<div class="col">
								<span class="eyebrow">Date</span>
                                <p class="p-strong">{{ information.created_at | moment('Do MMMM YYYY') }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="eyebrow">Status</span>
			                    <p class="p-strong">{{ information.status }}</p>
							</div>
							<div class="col">
								<span class="eyebrow">Last Response</span>
			                    <p class="p-strong">{{ information.updated_at | moment('Do MMMM YYYY') }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col full">
								<span class="eyebrow">Request</span>
								<p class="p-strong">{{ information.message }}</p>
							</div>
						</div>
					</div>
				</div>
				<Button v-if="information.port_id" icon="ContactIcon" class="section-gap none-bottom btn btn-yellow-light btn-icon btn-icon-right" text="Have an issue? Talk to us" :routeName="`/port/${information.port.id}`"></Button>
				<Button icon="SendIcon" class="none-bottom btn btn-green-light btn-icon btn-icon-right" text="Make another request" routeName="/requests/new"></Button>
				<Button icon="CrossIcon" class="none-bottom btn btn-red-light btn-icon btn-icon-right" text="Cancel request" @click.native="cancelRequest()"></Button>
			</div>

			<!-- Port Admin Request Details -->
			<div class="information-wrap" v-if="tabTwoActive && isPortAdmin">
				<div class="info-card main">
					<div class="info-icon-wrap">
						<SendAltIcon style="width: 27px;" class="svg-right" />
						<h3>Request Details</h3>
					</div>
					<div class="info-card-wrap">
						<div class="row">
							<div class="col">
								<!-- <pre>{{ information }}</pre> -->
								<span class="eyebrow">Requested by</span>
								<p class="p-strong">{{ information.user && information.user.profile && information.user.profile.name || '' }}</p>
							</div>
							<div class="col">
								<span class="eyebrow">Type</span>
								<p class="p-strong">{{ information.category }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="eyebrow">Port</span>
			                    <p class="p-strong">{{ information.port ? information.port.name : '' }}</p>
							</div>
							<div class="col">
								<span class="eyebrow">Vessel name</span>
                                <p class="p-strong">{{ information.vessel_name }}</p>
							</div>
						</div>
						<div v-if="showTransportDetails" class="row">
							<div class="col">
								<span class="eyebrow">Pickup location</span>
			                    <p class="p-strong">{{ information.details.pickupLocation }}</p>
							</div>
							<div class="col">
								<span class="eyebrow">Passenger Count</span>
                                <p class="p-strong">{{ information.details.passengerCount }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="eyebrow">Date</span>
                                <p class="p-strong">{{ information.created_at | moment('Do MMMM YYYY') }}</p>
							</div>
							<div class="col">
								<span class="eyebrow">Last Response</span>
			                    <p class="p-strong">{{ information.updated_at | moment('Do MMMM YYYY') }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col col-flex">
								<div class="col-wrap">
									<span class="eyebrow">Request ID</span>
									<p class="p-strong">{{ information.display_id }}</p>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col col-flex">
								<div class="col-wrap">
									<span class="eyebrow">Assigned to</span>
			                    	<p class="p-strong">{{ information.assignable ? information.assignable.name : 'Not assigned' }}</p>
			                    	<p class="p-strong small"><a class="assign-link" :class="{disabled: !information.open && information.completed}" @click="openModalConfirmation('Assign To', 'Assign this request to another Port Admin below', { status: 'assign' })">Change?</a></p>
			                    </div>
							</div>
							<div class="col">
								<span class="eyebrow">Status</span>
			                    <p class="p-strong green">{{ capitalizeFirstLetter(information.status) }}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="information.status == 'completed'" >
					<Button icon="TickIcon" class="section-gap none-bottom btn btn-light-green btn-icon btn-icon-right btn-square" text="Reopen Request" @click.native="openModalConfirmation('Reopen Request', 'Are you sure you want to reopen this request?', { status: 'reopen' })"></Button>
				</div>
				<div v-else >
					<Button icon="TickIcon" class="section-gap none-bottom btn btn-green icon-white btn-icon btn-icon-right btn-square" text="Mark as complete" @click.native="openModalConfirmation('Mark as Complete', 'Are you sure you want to mark this request as complete?', { status: 'complete' })"></Button>
					<Button icon="CrossIcon" class="none-bottom btn btn-red btn-icon btn-icon-right btn-square" text="Delete Request" @click.native="openModalConfirmation('Delete Request', 'Are you sure you want to delete this request?', { status: 'delete' })"></Button>
				</div>
			</div>

		</div>
		<PortAdminRequestSubmittedOverlay :title="modalTitle" :message="modalMessage"  />
		<PortAdminRequestConfirmationOverlay :title="modalTitleConfirmation" :message="modalMessageConfirmation"  :port_id="information.port_id" @request-updated="getRequest" />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import PortAdminRequestSubmittedOverlay from '@/components/PortAdminRequestSubmittedOverlay.vue';
	import PortAdminRequestConfirmationOverlay from '@/components/PortAdminRequestConfirmationOverlay.vue';
	import AnchorIcon from '@/assets/anchor-black.svg';
	import TickIcon from '@/assets/tick.svg';
	import Button from '@/components/Button.vue';
	import ImageIcon from '@/assets/image-upload.svg';
	import SendIcon from '@/assets/send-filled.svg';
	import SendAltIcon from '@/assets/send.svg';
	import InfoIcon from '@/assets/info-grey.svg';
	import ArrowLeft from '@/assets/arrow-left.svg';
	import ArrowLeftIcon from '@/assets/arrow-left-alt.svg';
    import UserService from '@/services/UserService';
    import { Capacitor } from '@capacitor/core';
    import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
	import PortAdminService from '@/services/PortAdminService';
	import Echo from 'laravel-echo';
	import Pusher from 'pusher-js';
	export default {
		name: 'RequestsSingle',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			AnchorIcon,
			Button,
			TickIcon,
			ImageIcon,
			InfoIcon,
			ArrowLeftIcon,
			ArrowLeft,
			SendIcon,
			SendAltIcon,
			PortAdminRequestSubmittedOverlay,
			PortAdminRequestConfirmationOverlay
		},
		data () {
	        return {
	        	tabOneActive: true,
	        	tabTwoActive: false,
	        	message: '',
	        	typing: false,
	        	stickyHeader: false,
                information: {},
                messages: [],
                file: null,
                keyboardOpen: false,
                overlayTitle: '',
      			overlayMessage: '',
      			overlayTitleConfirmation: '',
      			overlayMessageConfirmation: '',
				showTransportDetails: false
	        };
	    },
	    methods: {
	    	tabOne () {
	    		this.tabOneActive = true;
	    		this.tabTwoActive = false;
	    	},
	    	tabTwo () {
	    		this.tabOneActive = false;
	    		this.tabTwoActive = true;
	    	},
	    	handleScroll () {
	    		if (window.scrollY > 350) {
	    			this.stickyHeader = true;
	    		} else {
	    			this.stickyHeader = false;
	    		}
	    	},
            sendMessage () {
                let payload = {
                    message: this.message
                };
                if (this.file) {
                    const formData = new FormData();
                    formData.append('message', this.file.name);
                    formData.append('is_file', true);
                    formData.append('file', this.file);
                    payload = formData;
                }
                if (this.isPortAdmin) {
					PortAdminService.sendMessage(this.$route.params.id, payload).then(() => {
						this.message = '';
					});
				} else {
					UserService.sendMessage(this.$route.params.id, payload).then(() => {
						this.message = '';
					});
				}
            },
            getRequest () {
				if (this.isPortAdmin) {
					PortAdminService.getRequest(this.$route.params.id).then((response) => {
						this.information = response.data.information;
						this.messages = response.data.messages;
						this.message = '';
						this.showTransportDetails = response.data.information.category === 'Transport' && response.data.information.details;
					}).finally(() => {
						this.scrollToMessage();
					});
				} else {
					UserService.getRequest(this.$route.params.id).then((response) => {
						this.information = response.data.information;
						this.messages = response.data.messages;
						this.message = '';
					}).finally(() => {
						this.scrollToMessage();
					});
				}
            },
            scrollToMessage () {
                const el = this.$refs.scrollTo;
                if (el && el[0]) {
                    el[0].scrollIntoView({ behavior: 'smooth' });
                }
            },
            upload (e) {
                if (!e.target.files.length) return;
                const file = e.target.files[0];
                this.file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    this.$emit('loaded', { src, file });
                };
                this.message = file.name;
            },
            cancelRequest () {
                if (confirm('Are you sure you want to cancel this request?')) {
                    UserService.cancelRequest(this.$route.params.id).then(() => {
                        this.$router.push({ name: 'Requests' });
                    });
                }
            },
            deleteRequest () {
                if (confirm('Are you sure you want to delete this request?')) {
                    UserService.cancelRequest(this.$route.params.id).then(() => {
                        this.$router.push({ name: 'Requests' });
                    });
                }
            },
            getFilePath (path) {
                return process.env.VUE_APP_API_URL + path;
            },
			openModalConfirmation (title, message, additionalData) {
				this.$store.commit('TOGGLE_PORT_ADMIN_REQUEST_CONFIRMATION_MODAL', {
					isOpen: true,
					title,
					message,
					additionalData: additionalData || {}
				});
			},
			closeModalConfirmation () {
				this.$store.commit('TOGGLE_PORT_ADMIN_REQUEST_CONFIRMATION_MODAL', {
					isOpen: false
				});
			},
			capitalizeFirstLetter (string) {
				if (!string) return '';
				return string.charAt(0).toUpperCase() + string.slice(1);
			}, 
			markRequestComplete () {
				PortAdminService.markRequestComplete(this.$route.params.id).then((response) => {
					this.closePortAdminModal();
					this.openModal('Mark as Complete', 'This request has been successfully marked as completed');
				});
			},
			initializePusher () {
				window.Pusher = Pusher;
				window.Echo = new Echo({
					authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
					auth: {
						headers: {
							Authorization: `Bearer ${this.$store.state.token}`
						}
					},
					encrypted: true,
					broadcaster: 'pusher',
					key: process.env.VUE_APP_PUSHER_CHANNELS_KEY,
					cluster: 'eu',
					forceTLS: true
				});

				window.Echo.channel('request.' + this.$route.params.id)
					.listen('.newMessage', (e) => {
						this.messages.push(e.message);
						this.information = e.request;
						this.getRequest();
						// this.showNotification('New Message', e.message.content || 'You have a new message.');
					});
			},
			showNotification (title, message) {
				if (Notification.permission === 'granted') {
					this.createNotification(title, {
						body: message
					});
				}
			},
			createNotification (title, options) {
				return new Notification(title, options);
			}
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
	        isPortAdmin () {
				console.log('isPortAdmin:', this.$store.state.isPortAdmin);
	            return this.$store.state.isPortAdmin;
	        },
	        isPortAdminRequestSubmittedModalOpen () {
		        return this.$store.state.isPortAdminRequestSubmittedModalOpen;
		    },
		    isPortAdminRequestConfirmationModalOpen () {
		        return this.$store.state.isPortAdminRequestConfirmationModalOpen;
		    },
		    modalTitle () {
		        return this.$store.state.modalTitle;
		    },
		    modalMessage () {
		        return this.$store.state.modalMessage;
		    },
		    modalTitleConfirmation () {
		        return this.$store.state.modalTitleConfirmation;
		    },
		    modalMessageConfirmation () {
		        return this.$store.state.modalMessageConfirmation;
		    }
		},
        watch: {
            messages () {
                this.scrollToMessage();
            }
        },
		created () {
		    window.addEventListener('scroll', this.handleScroll);
		  },
		destroyed () {
		    window.removeEventListener('scroll', this.handleScroll);
		 },
		mounted () {
			if (this.isPortAdmin) {
				this.tabOneActive = false;
				this.tabTwoActive = true;
			}
            this.getRequest();
            // make more securue using this.information.id...
            // window.Echo.private(`request.${this.$route.params.id}`)
            //     .listen('RequestMessage', (e) => {
            //         this.messages.push(e.message);
            //         this.information = e.request;
            //         this.getRequest();
            //     }); // beforeRouteLeave / destroyed : window.Echo.leave ?
            if (Capacitor.isNativePlatform()) {
                Keyboard.setAccessoryBarVisible({
                    isVisible: true
                });
                Keyboard.setResizeMode({
                    mode: KeyboardResize.Native
                });
                Keyboard.addListener('keyboardWillShow', () => {
                    this.keyboardOpen = true;
                });
                Keyboard.addListener('keyboardWillHide', () => {
                    this.keyboardOpen = false;
                });
            }
			// window.Pusher = Pusher;
			// window.Echo = new Echo({
			// 	authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
			// 	auth: {
			// 		headers: {
			// 			Authorization: `Bearer ${this.$store.state.token}`
			// 		}
			// 	},
			// 	encrypted: true,
			// 	broadcaster: 'pusher',
			// 	key: process.env.VUE_APP_PUSHER_CHANNELS_KEY,
			// 	cluster: 'eu',
			// 	forceTLS: true
			// });
			// window.Echo.channel('request.' + this.$route.params.id)
			// .listen('.newMessage', (e) => {
			// 	this.messages.push(e.message);
			// 	this.information = e.request;
			// 	this.getRequest();
			// });

			this.initializePusher();

			// Request notification permission
			if (Notification.permission !== 'granted') {
				Notification.requestPermission().then(permission => {
					if (permission === 'granted') {
						console.log('Notification permission granted.');
					} else {
						console.log('Notification permission denied.');
					}
				});
			}
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.overlay-alt {
		position: fixed;
		z-index: 130;
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
	.assign-link {
		color: $green_base;
	    text-decoration: underline;
	    font-size: 13px;
	    display: inline-block;
	    margin-top: 8px;
		&.disabled {
			pointer-events: none;
			opacity: 0;
		}
	}
	.sticky-header {
		position: fixed;
	    top: 0;
	    z-index: 120;
	    width: 100%;
	    background: $white;
	    height: 102px;
	    box-shadow: 0px 25px 50px 0px rgba($black, 0.1);
	    padding: 40px 28px 0;
	    .sticky-inner {
	    	display: flex;
	    	align-items: center;
	    	&.space-between {
	    		justify-content: space-between;
	    	}
	    	.icon-hold {
    			display: flex;
    			align-items: center;
    			.info-icon {
	    			margin-left: 10px;
	    			overflow: visible;
	    		}
	    		.tick-icon {
	    			overflow: visible;
	    			g {
	    				stroke: $green_base;
	    				stroke-width: 2.5;
	    			}
	    		}
    		}
	    }
	    .sticky-inner-wrap {
    		display: flex;
    	}
	    .sticky-title {
	    	font-size: 17px;
	    	letter-spacing: -0.42px;
	    	line-height: 20px;
	    	color: $dark;
	    	h3 {
	    		margin-bottom: 6px;
	    	}
	    }
	    .sticky-arrow {
	    	margin-right: 15px;
	    	svg {
	    		display: block;
	    		path, g {
	    			stroke-width: 3;
	    			stroke: $grey;
	    		}
	    	}
	    }
	    .icon-wrap {
	    	display: flex;
	    	align-items: center;
	    	svg {
	    		margin-right: 8px;
	    		display: block;
	    		path, g {
	    			stroke-width: 4;
	    		}
	    	}
	    	span {
	    		font-size: 14px;
	    		line-height: 17px;
	    		color: $mid;
	    	}
	    }
	}
	.information-wrap {
		padding-bottom: 50px;
		.btn-yellow-light, .btn-green {
			margin-bottom: 15px;
		}
		.info-card {
			padding: 25px;
			margin-bottom: 20px;
			.info-icon-wrap {
				display: flex;
			    align-items: center;
			    margin-bottom: 15px;
			    svg {
			    	margin-right: 10px;
			    	g {
			    		stroke: $dark;
						stroke-width: 2.5;
			    	}
			    }
			    h3 {
			    	margin-bottom: 0;
			    	color: $dark;
			    }
			}
			h3 {
				color: $dark;
				font-size: 17px;
				margin-bottom: 5px;
			}
			.col-flex {
				display: flex;
				align-items: center;
				svg {
					display: block;
					g {
						stroke: $slate;
						stroke-width: 2.5;
					}
				}
			}
			&.main {
				p {
					font-size: 17px;
					font-weight: 500;
					&.green {
						color: $green_base;
					}
				}
				.eyebrow {
					margin-bottom: 8px;
				}
			}
			.info-card-wrap {
				.row {
					border-bottom: none;
				}
			}
		}
	}
	.chat-window {
		padding: 28px 28px 170px 28px;
		.date {
			color: $grey;
			margin-bottom: 25px;
			opacity: 1;
		}
	}
	.chat-wrap {
		display: flex;
		flex-direction: column;
	}
	.chat-date-wrap {
		display: flex;
		flex-direction: column;
	}
	.chat-group {
		display: flex;
		flex-direction: column;
		margin-bottom: 18px;
		.chat-row {
			&:last-child {
				.chat-bubble {
					border-radius: 20px 20px 20px 0;
				}
				.media-bubble {
					img {
						border-radius: 20px 20px 20px 0;
					}
				}
			}
		}
		&.sent {
			.chat-row {
				justify-content: flex-end;
				&:last-child {
					.chat-bubble {
						border-radius: 20px 20px 0 20px;
					}
					.media-bubble {
						img {
							border-radius: 20px 20px 0 20px;
						}
					}
				}
				.chat-bubble-wrap {
					.time {
						text-align: right;
					}
				}
				.media-bubble {
					justify-content: flex-end;
				}
				.chat-bubble {
					background: $green_base;
					p {
						color: $white;
					}
				}
			}
		}
	}
	.chat-row {
		display: flex;
		text-align: left;
		margin-bottom: 10px;
		.chat-bubble-wrap {
			position: relative;
			width: 75%;
			display: flex;
			flex-direction: column;
			.time {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 17px;
				font-weight: $weight_600;
				color: $grey;
				margin-top: 5px;
			}
		}
		.media-bubble {
			display: flex;
			justify-content: flex-start;
			img {
				border-radius: 20px;
				max-width: 60%;
			}
		}
		.chat-bubble {
			padding: 18px;
			background: $white;
			box-shadow: 5px 15px 15px 5px $snow;
    		border: 1px solid $snow;
    		border-radius: 20px;
    		flex-shrink: 0;
			p {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 17px;
				font-weight: $weight_600;
				margin: 0;
				color: $slate;
			}
		}
	}
	.chat-complete {
		width: 260px;
		margin: 20px auto 50px;
		svg {
			margin-bottom: 7px;
			g, path {
				stroke: $mid;
			}
		}
		p {
			font-size: 14px;
			letter-spacing: -0.41px;
			line-height: 19px;
			font-weight: $weight_400;
			color: $mid;
			strong {
				font-weight: $weight_600;
				color: $mid;
			}
		}
	}
	.header-wrap {
		background: $green_dark;
		.portAdminStyles & {
			background: $green_base;
		}
		.header {
			position: relative;
			height: auto;
			padding-top: 70px;
		}
	}
	.request-header {
		width: 100%;
		text-align: left;
		padding-top: 30px;
		h1 {
			font-size: 26px;
			line-height: 31px;
			letter-spacing: -0.65px;
			color: $white;
			margin-bottom: 8px;
		}
		.request-header-port {
			display: flex;
			align-items: center;
			svg {
				margin-right: 5px;
				g, path {
					stroke: $light;
					stroke-width: 3;
					.portAdminStyles & {
						stroke: $white;
					}
				}
			}
			span {
				color: $light;
				font-size: 14px;
				line-height: 17px;
				font-weight: $weight_600;
				.portAdminStyles & {
					color: $white;
				}
			}
		}
		.request-nav {
			padding-top: 30px;
			display: flex;
			a {
				display: flex;
				color: $light;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: -0.35px;
				border-bottom: 4px solid transparent;
				padding-bottom: 5px;
				margin-right: 20px;
				font-weight: $weight_600;
				.portAdminStyles & {
					color: #076d33;
				}
				&.active {
					color: $white;
					border-color: $green_base;
				}
			}
			&.request-nav-port-admin {
				a {
					padding: 12px 18px;
				    border: 2px solid white;
				    border-bottom: none;
				    border-radius: 3px 3px 0 0;
				    background: #19924d;
				    border-color: #19924d;
				    color: $white;
				    margin-right: 12px;
				    &.active {
				    	background: $white;
				    	border-color: $white;
				    	color: $green_base;
				    }
				}
			}
		}
	}
	.nav-wrap {
	    position: fixed;
	    bottom: 0;
	    z-index: 120;
	    width: 100%;
	    background: $white;
	    .input-bar {
	    	display: flex;
	    	align-items: center;
	    	padding: 0 30px;
	    	height: 80px;
	    	border-bottom: 1px solid #ededed;
	    	.input {
	    		background: $snow;
	    		border-radius: 21px;
	    		border-color: $snow;
	    		box-shadow: none;
	    		height: 42px;
	    		line-height: 42px;
	    		font-size: 14px;
	    		letter-spacing: -0.21px;
	    		line-height: 17px;
	    		color: $dark;
	    		padding: 0 18px;
	    		&::placeholder {
		            color: $mid;
		        }
		        &:focus {
		        	background: $light;
		        	border-color: $light;
		        }
	    	}
	    	svg {
	    		margin-right: 15px;
	    		flex-shrink: 0;
	    		cursor: pointer;
	    		g, path, circle {
	    			stroke: $blue_base;
	    		}
	    		.fill {
	    			fill: $blue_base;
	    		}
	    		&.svg-right {
	    			margin-right: 0;
	    			margin-left: 15px;
	    			g, path, circle {
		    			stroke: $green_base;
		    			fill: $green_base;
		    		}
	    		}
	    	}
	    }
		nav {
			position: relative;
			box-shadow: none;
			border-radius: none;
		}
	}
</style>
