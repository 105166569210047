<template>
	<div class="news">
		<span class="overlay" :class="{active: isMenuOpen || isAccessModalOpen}"></span>
		<div class="hero-container">
			<Nav back />
			<div class="header header-standard">
				<HeaderNavBar back />
			</div><!-- header -->
			<div class="header-image about-header-image huge">
                <img class="bg" alt="The Mission to Seafarers" src="../assets/news-card.jpg" />
            </div><!-- header image -->
			<div class="news-hero-content">
				<div class="info">
					<h1 class="news-title">Privacy and Data Protection Notice</h1>
					<span class="eyebrow">Happy at Sea</span>
				</div><!-- info -->
			</div>
		</div>
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<div class="news-content">
                <h2>Who we are</h2>

                <p>The Seafarers Happiness Index is an initiative of The Mission to Seafarers (“MTS“), a maritime welfare charity working in 50 countries across 200 ports.</p>
                <p>MTS is registered as a charity in England and Wales (registered charity number 1123613) and we are also registered as a company limited by guarantee (company number 6220240).</p>
                <p>This policy (together with our end-user licence agreement as set out at [www.app.missiontoseafarers.org] (EULA) and any additional terms of use incorporated by reference into the EULA, together our Terms of Use) applies to your use of:</p>
                <ul>
                    <li>Happy at Sea (which was last updated on [[]May 2023]) mobile application software (App) available on the App Store (for Apple devices) or Google Play store  (App Site), once you have downloaded a copy of the App onto your mobile telephone or handheld device (Device); and</li>
                    <li>any of the services accessible through the App (Services) that are available on the App Site or other sites of ours (Services Sites) save as otherwise agreed in writing by MtS.</li>
                </ul>
                <p>This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. This App is not intended for children below the age of 14 years and we do not knowingly collect data relating to children below the age of 14 years. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.</p>

                <h2>Our Notice</h2>
                <p>In carrying out our day to day activities we process and store personal information relating to our Seafarers and supporters and are therefore required to adhere to the requirements of the General Data Protection Regulation (GDPR) (EU) 2018/679 and of the UK General Data Protection Regulation and the Data Protection Act 2018 (UK GDPR).</p>
                <p>We take our responsibilities under the EU GDPR and UK GDPR very seriously and we ensure the personal information we obtain is held, used, transferred, and otherwise processed in accordance with the applicable law, all applicable data protection laws and regulations including, but not limited to, the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended and any other applicable UK data protection laws and regulations.</p>
                <p>We will be clear when we collect your data about why we are collecting it and what we intend to do with it, and not do anything you wouldn’t reasonably expect.</p>
                <p>This privacy notice sets out our data processing practices for personal information in electronic and manual form. If you have any requests concerning your personal information or any queries regarding these practices, please contact our Data Protection Officer, details of whom are provided at the end of this Notice.</p>
                <p>This privacy notice sets out our data processing practices for personal information collected through the “Happy at Sea” website.</p>

                <h2>Right to amend this Notice</h2>
                <p>We will regularly review and update this Privacy Notice and will update, modify, add or remove sections at our discretion. If we do so, we will post the changes on this page or notify you by email or when you next start the App or log onto one of the Services Sites. Such changes will apply from the time we post them. Please check this page from time to time so that you are aware of any changes. If you continue to use our website, any of our services and/or continue to provide us with your personal information after we have posted the changes to this Privacy Notice we will assume that you are aware of its amended contents. This notice was last changed on [●] May 2023</p>

                <h2>What personal information do we collect?</h2>
                <p>If you choose to respond to the Seafarers Happiness Index survey (the “Survey”), we will collect personal information (also called "personal data") which can be used to identify you. It can include, but is not limited to:</p>

                <h2>I. Identity data, such as your name and that of any other individual you specify</h2>

                <h2>II. Anonymised Survey data</h2>
                <p>This consists of answers to the Survey questions including ratings, none of which contain personally identifiable data. Survey questions provide free-text options for feedback. We ask participants not to use these fields to provide any personal information. Genuinely anonymised data from which an individual is not identifiable including by access to other available information is not "personal data" under data protection law.</p>

                <h2>III. Optional email address</h2>
                <p>You can optionally provide your email address if you wish to receive a copy of the Survey results.</p>

                <h2>IV. Contact Form</h2>

                <h2>V. Location Data</h2>
                <p>Location data including details of your current location disclosed by GPS technology OR [other technology] so that location-installed Services are activated for your welfare and wellbeing needs.</p>

                <p>If you have contacted us through the online “Contact” form, we will collect your name and email address.</p>
                <p>We may also collect “sensitive personal information” about you in relation to your use of the App such as race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data (also called "special categories of personal data").</p>
                <p>For example, if you participate in an event, we may need information about your dietary or access requirements to ensure we provide appropriate facilities for you. We will make it clear to you when collecting this information what we are collecting and why.</p>

                <h2>Legal basis for using your personal information</h2>
                <p>By providing your email address to receive a copy of the Survey results, you are providing consent to the processing of your personal data for this purpose.</p>
                <p>If we collect sensitive personal information from you, we will obtain your consent to our processing of this information.</p>
                <p>If you contact us through the online “Contact” Form, we have “legitimate interests” to process your information to provide you with the information you have requested, and if you have opted in to receive future communications from The Seafarers Happiness Index, we rely on this consent to do so.</p>
                <p>However, in other cases we may use lawful grounds to process your information as follows:</p>
                <ul>
                    <li>
                        We have certain 'Legitimate interests' to process your personal information to help us to achieve our goals as a charity and to give you the best service and secure experience.  When we use legitimate interests to process your personal information we carefully consider your rights and interests. We will not process your personal information using legitimate interests if the impact on your rights and interests outweighs our interests. Our legitimate interests include:
                        <ul>
                            <li>to communicate with our existing donors, including acknowledging donations and informing them about recent work or appeals;</li>
                            <li>to develop awareness about our charity and its work;</li>
                            <li>to improve our website and our services;</li>
                            <li>to prevent fraud and to keep our network and information secure;</li>
                            <li>to assert or enforce our legal rights;</li>
                            <li>to deal with complaints and claims;</li>
                            <li>to comply with guidance from relevant regulators such as the Charity Commission;</li>
                            <li>to maintain and administer our charity and our records;</li>
                        </ul>
                    </li>
                    <li>We may need to use your personal information to comply with our legal obligations.</li>
                </ul>

                <h2>When do we collect personal information?</h2>
                <p>We collect personal information from you at the point you complete the Survey or contact us through the online “Contact” form, or when you install the App.</p>
                <p>We will only collect the data we need for the purposes we specify at the point of collection. We will not use your personal data for a purpose different from the one specified at collection unless you give your explicit consent for us to do so or unless we have another lawful basis for processing your personal data for a different purpose.</p>
                <p>If you do nothing other than read pages or download information from our website, we may gather information about this using cookie, such as which pages are most visited and which events or activities are of most interest.  This information can be used to help us improve our website and services and ensure we provide you with the best service.  Wherever possible, the information we use for this purpose will be aggregated or anonymised i.e. it will not identify you as an individual visitor to our website.  You can find more information about cookies in our Cookie Policy and in the section below entitled “Our website”.</p>

                <h2>Why we collect and how we use your information</h2>
                <p>We collect your personal information for one or more of the following reasons:</p>
                <ul>
                    <li>to register you as a new App user;</li>
                    <li>to process in-App purchases (if applicable) and deliver any services;</li>
                    <li>to provide you with the services, products or information you have requested;</li>
                    <li>to process any donation(s) we may receive from you;</li>
                    <li>to provide you with information about our work or our activities, that you have asked to receive;</li>
                    <li>to send you the items you have ordered from us;</li>
                    <li>for administration purposes e.g. we may contact you about a donation you have made or event you have expressed an interest in or registered for;</li>
                    <li>for internal record keeping, such as the management of feedback or complaints;</li>
                    <li>to manage our relationship with you including notifying you of any changes to the App or Services;</li>
                    <li>to administer and protect our business and this App including troubleshooting, data analysis and system testing;</li>
                    <li>to analyse and improve the services we offer; and/or</li>
                    <li>where it is required or authorised by law.</li>
                </ul>
                <p>In order to accept these terms, access MtS content and participate in MtS activities, you must be at least 14 years of age.</p>
                <p>We collect the Survey answers to gather feedback from seafarers on life at sea. The anonymised consolidated feedback forms the Survey results, which are publicly available and inform the public and relevant stakeholders of the state of seafarers’ welfare. We collect your email address to share the Survey results with you if you have requested for us to do so.</p>
                <p>If you contact us through the online “Contact” Form, we collect this information for administrative purposes, to provide you with the information you have requested.</p>

                <h2>Your information and third parties</h2>
                <p>We will not sell or swap your information with any third party. We may share your information with our data processors. These are trusted partner organisations that work with us in connection with our charitable purposes, for instance by processing our donations, mailing our newspapers to you, sending you the Christmas cards you have purchased, providing software for the emailing of our publications and newsletters, or for the provision of our digital chaplaincy services.</p>
                <p>All our trusted partners are required to comply with data protection laws and our high standards and are only allowed to process your information in strict compliance with our instructions. We will always make sure appropriate contracts and controls are in place and we regularly monitor all our partners to ensure their compliance.</p>
                <p>We may disclose your personal information to third parties if we are required to do so through a legal obligation (for example to the police or a government body); to enable us to enforce or apply our terms and conditions or rights under an agreement; or to protect us, for example, in the case of suspected fraud or defamation. If you have asked us to claim Gift Aid on your donations, we will share your details with HM Revenue and Customs in order to do so. We do not share your information for any other purposes.</p>

                <h2>How long do we keep your information for?</h2>
                <p>We will only keep your information for as long as required to enable us to carry out the purpose for which it was collected. We will not keep your information for any longer than is necessary.</p>
                <p>Anonymised survey data will be kept for the purposes of analysing the data for an indeterminate amount of time (until we no longer need it).</p>
                <p>If you have opted to provide your email address to receive a copy of the Survey results, your email address will be stored until you unsubscribe, upon which it will be promptly and permanently deleted.</p>
                <p>If you have contacted us through the Contact Form, we will keep your information for as long as is required to resolve your enquiry.</p>

                <h2>How we store your information and our website</h2>
                <p>We promise to respect any personal data you share with us and keep it safe. Information is stored in a secure part of our website hosted in the UK.</p>
                <p>We enforce strict procedures and security features to protect your information and prevent unauthorised access. Whilst we cannot guarantee that loss, misuse or alteration of data will not occur while it is under our control, we use our best efforts to attempt to prevent this.</p>
                <p>In addition, as your personal data is shared with our data processors, it may also be transferred and stored securely outside the UK and the European Economic Area. Where that is the case, we will take steps to ensure it is subject to equivalent legal protections through the use of model contract clauses or other appropriate safeguards which give personal data the same protection is it has in the UK, if the recipient's jurisdiction does not benefit from appropriate data adequacy recognition.</p>
                <p>Our website contains links to The Mission to Seafarers’ main website and other websites belonging to third parties and we sometimes choose to participate in social networking sites including but not limited to Facebook, Twitter, YouTube, Instagram and LinkedIn. We may include content from sites such as these on our websites but we do not have any control over the privacy practices of these other sites.</p>

                <h2>Your rights</h2>
                <p>The type of communications and information you receive about our charity is your choice. You can change your mind at any time by contacting our Supporter Care Officer at The Mission to Seafarers, 1st Floor, 6 Bath Place, Rivington Street, London EC2A 3JE or on 0207 246 2930 or e-mail gdpr@missiontoseafarers.org.</p>

                <h2>You have the right to:</h2>
                <ul>
                    <li>request a copy of the personal information we hold about you;</li>
                    <li>withdraw your consent if we are relying on your consent to process your personal information;</li>
                    <li>update or amend the personal information we hold about you if it is wrong;</li>
                    <li>change your communication preferences at any time;</li>
                    <li>request the transfer of your personal data;</li>
                    <li>ask us to remove your personal information from our records;</li>
                    <li>object to the processing of your personal information for marketing purposes; or</li>
                    <li>raise a concern or complaint with us or with the UK's Information Commissioner's Office about the way in which your personal information is being used.</li>
                </ul>
                <p>If you wish to exercise or find out more about these rights, if you have questions or queries about this Privacy and Data Protection Notice, to obtain a copy of the information we hold about you or to make a complaint about how your personal data has been used by us, please contact our Data Protection Officer at:</p>
                <p>
                    Data Protection OfficerThe Mission to Seafarers<br>
                    1st Floor, 6 Bath Place<br>
                    Rivington Street<br>
                    London<br>
                    EC2A 3JE
                </p>
                <p>
                    Tel: 020 7246 2944<br>
                    Email: dataprotection@missiontoseafarers.org
                </p>
                <p>If you remain dissatisfied, you are entitled to raise your concerns with the Information Commissioner’s Office. https://ico.org.uk/</p>
                <h2>Published: May 2023</h2>
			</div><!-- news content -->
			<div class="logo-section">
				<div class="wrapper">
					<span class="logo-title">Supported by</span>
					<div class="logo-wrap">
						<div class="logo-block">
							<img src="../assets/cargill.png" alt="The Mission to Seafarers">
						</div>
						<div class="logo-block">
							<img src="../assets/dnv-logo.png" alt="The Mission to Seafarers">
						</div>
						<div class="logo-block">
							<img src="../assets/seafarers-charity.png"  alt="The Mission to Seafarers">
						</div>
					</div>
				</div><!-- wrapper -->
			</div><!-- logo section -->
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'PrivacyPolicy',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
	        };
	    },
        watch: {
            newsId () {
                this.getArticle();
            }
        },
	    methods: {
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
		mounted () {
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.about-header-image img.bg {
		opacity: 0.15;
	}
	.logo-section {
		position: relative;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 70px;
		.logo-title {
			text-align: center;
			font-size: 16px;
			line-height: 26px;
			color: #6D7A8B;
			margin-bottom: 20px;
			display: inline-block;
		}
		.logo-wrap {
			display: flex;
			justify-content: center;
			background: #EAF5FA;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0 10px;
			.logo-block {
				width: 30%;
				height: 110px;
				margin-left: 0;
				margin-right: 0;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: auto;
				    height: auto;
				    max-width: 70%;
				    max-height: 65%;
				}
			}
		}
	}
	.section-title-wrap-big {
		margin-bottom: 20px;
	}
	.news-hero-content {
		position: relative;
		text-align: left;
		display: flex;
	    justify-content: space-between;
	    margin: 0 28px;
	    align-items: flex-end;
	    min-height: 175px;
    	padding: 0 0 22px;
	    .news-title {
    		font-size: 26px;
    		line-height: 31px;
    		color: $white;
    		display: block;
    		margin-bottom: 12px;
    	}
    	.eyebrow {
    		color: $white;
    	}
	}
	.news-content ::v-deep {
		text-align: left;
		margin-bottom: 50px;
		iframe {
			width: 100%;
			margin: 10px 0 30px;
		}
		h2, h3, h4, h5 {
			color: $dark;
			font-size: 17px;
			letter-spacing: -0.5px;
			font-weight: $weight_600;
			line-height: 26px;
			margin-bottom: 30px;
		}
		h6 {
			color: $orange_base;
			font-size: 17px;
			letter-spacing: -0.5px;
			font-weight: $weight_600;
			line-height: 26px;
			margin-bottom: 22px;
			margin-bottom: 30px;
		}
		p {
			margin-bottom: 22px;
			a {
				color: $blue_base;
				text-decoration: underline;
				font-weight: $weight_500;
			}
		}
		ul {
			list-style: disc;
		    padding-left: 15px;
		    margin-bottom: 25px;
			li {
				font-family: "Inter", sans-serif;
			    font-weight: 400;
			    letter-spacing: -0.5px;
			    font-size: 17px;
			    line-height: 1.6em;
			    color: #6D7A8B;
			    margin-bottom: 10px;
			}
		}
	}
</style>
