<template>
	<div class="resources">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isAccessModalOpen}"></span>
		<div class="header header-standard">
            <HeaderNavBar back v-if="myResources" star @click.native="toggleResource"/>
            <HeaderNavBar back v-else starOutline @click.native="toggleResource"/>
		</div><!-- header -->
		<div class="header-image red"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<div class="text-block no-space">
				<h1 class="title no-space"><strong>{{ resource.title }}</strong></h1>
				<div class="resource-tags">
					<div v-if="resource.category" class="resource-tag">
						<TagIcon class="tag-icon" />
						<span>{{ resource.category }}</span>
					</div><!-- tag -->
					<div class="resource-tag">
						<TagIcon class="tag-icon" />
						<span>{{ resource.simplified_type }}</span>
					</div><!-- tag -->
				</div><!-- resource tags -->
			</div>
			<div class="section-gap resource-content">
                <span class="eyebrow resource-date">{{ resource.created_at | moment('Do MMMM YYYY') }}</span><br>
                <a v-if="resource.simplified_type === 'Image'" class="resource-video">
                    <img class="bg-image" alt="The Mission to Seafarers" :src="resource_url"/>
                </a><!-- resource-video -->
				<a v-if="resource.simplified_type === 'Video'" href="#" class="resource-video">
					<img class="bg" alt="The Mission to Seafarers" src="@/assets/dashboard-header.jpg" />
					<PlayIcon />
				</a><!-- resource-video -->
				<a>
                    <CardRow
                        v-if="resource.simplified_type && (resource.simplified_type === 'Document' || resource.simplified_type === 'PDF')"
                        @click.native="openDocument"
                        class="view-resource"
                        title="View this document"
                        text="An internet connection is required the first time you view"
                        type="Document"
                    />
                </a>
                <div v-html="resource.content"></div>
			</div>
			<div class="section-gap section-ports" v-if="similar.length">
				<div class="section-title-wrap">
					<h2 class="section-title">Similar Resources</h2>
                    <router-link v-if="resource.category" :to="`/resources/category/${resource.category}`" class="page-link page-link-red">View more &raquo;</router-link>
                    <router-link v-else to="/resources/latest-resources" class="page-link page-link-red">View more &raquo;</router-link>
				</div>
				<div class="card-row-wrap-alt" v-dragscroll>
					<CardRow
                        v-for="resource in similar"
                        :key="resource.id"
						eyebrow
						routeName="ResourcesSingle"
                        :id="resource.id"
						class="card-row-block-resources card-row-block-alt"
						:title="resource.title"
						:text="resource.simplified_content"
						:tag="resource.category"
						:type="resource.simplified_type"
					/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap card-wrap">
				<!--<Card
					routeName="Chat"
					class="card-block-red"
					icon="DownloadIcon"
					title="Make this available offline"
				/>-->
				<Card
					class="card-block-red card-block-full"
                    :class="{ 'filled': myResources }"
					icon="StarIcon"
					:title="myResources ? 'Remove from My Resources' : 'Add to My Resources'"
                    @click.native="toggleResource($event, true)"
				/>
			</div><!-- card wrap -->
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<MobileDataOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import Card from '@/components/Card.vue';
	import CardRow from '@/components/CardRow.vue';
	import TagIcon from '@/assets/tag.svg';
	import PlayIcon from '@/assets/play-white.svg';
	import MobileDataOverlay from '@/components/MobileDataOverlay.vue';
    import ResourceService from '@/services/ResourceService';
    import UserService from '@/services/UserService';
    import { Http } from '@capacitor-community/http';
    import { Directory, Filesystem } from '@capacitor/filesystem';
    import { FileOpener } from '@capacitor-community/file-opener';
    import { Capacitor } from '@capacitor/core';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';

    export default {
		name: 'ResourcesSingle',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Card,
			CardRow,
			TagIcon,
			MobileDataOverlay,
			PlayIcon,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
                resource: {},
                resource_url: '',
                similar: [],
                myResources: false
	        };
	    },
	    methods: {
            processContent (content) {
                const captionRegex = /\[caption.*?\](<img.*?>)(.*?)\[\/caption\]/g;
                return content.replace(captionRegex, (match, imgTag, caption) => {
                    const titleAttr = `title="${caption.trim()}"`;
                    return imgTag.replace('<img', `<img ${titleAttr}`);
                });
            },
            setResource (resource) {
                this.resource = resource;
                this.resource.content = this.processContent(resource.content);
            },
            getResource () {
                return ResourceService.getResource(this.$route.params.id).then((response) => {
                    this.setResource(response.data.resource);
                    this.similar = response.data.similar;
                    if (response.data.resource.external_url) {
                        this.resource_url = response.data.resource_url;
                    } else {
                        if (response.data.resource_url) {
                            this.resource_url = process.env.VUE_APP_API_URL + response.data.resource_url;
                        }
                    }
                    if (this.isAuthenticated) {
                        UserService.isResourceFavourite(this.$route.params.id).then((response) => {
                            this.myResources = response.data;
                        });
                    }
                });
            },
            toggleResource (event, ignore) {
                if (ignore || (event.target.classList.contains('star-icon') || event.target.parentElement.classList.contains('star-icon'))) {
                    if (this.isAuthenticated) {
                        UserService.toggleResource(this.resource.id).then((response) => {
                            this.myResources = response.data.resource.user;
                        });
                    } else {
                        this.$store.state.isAccessModalOpen = true;
                    }
                }
            },
            async openDocument () {
                if (Capacitor.isNativePlatform()) {
                    const path = localStorage.getItem('resource_' + this.resource.id);
                    if (path) {
                        try {
                            await FileOpener.open({
                                filePath: path
                            });
                        } catch (error) {
                            if (error.message === 'File does not exist') {
                                localStorage.removeItem('resource_' + this.resource.id);
                                const download = await Http.downloadFile({
                                    url: this.resource.external_url ? this.resource.external_url : process.env.VUE_APP_API_URL + '/files/resources/' + this.resource.file_name,
                                    filePath: this.getSeconds() + '_' + this.resource.file_name,
                                    fileDirectory: Directory.Documents,
                                    method: 'GET'
                                });
                                if (download.path) {
                                    localStorage.setItem('resource_' + this.resource.id, download.path);
                                    await FileOpener.open({
                                        filePath: download.path
                                    });
                                }
                            }
                        }
                    } else {
                        localStorage.removeItem('resource_' + this.resource.id);
                        const download = await Http.downloadFile({
                            url: this.resource.external_url ? this.resource.external_url : process.env.VUE_APP_API_URL + '/files/resources/' + this.resource.file_name,
                            filePath: this.getSeconds() + '_' + this.resource.file_name,
                            fileDirectory: Directory.Documents,
                            method: 'GET'
                        });
                        if (download.path) {
                            localStorage.setItem('resource_' + this.resource.id, download.path);
                            await FileOpener.open({
                                filePath: download.path
                            });
                        }
                    }
                }
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            },
            getSeconds () {
                return Math.floor(new Date().getTime() / 1000);
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAuthenticated () {
                return this.$store.getters.isAuthenticated;
            },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
        watch: {
            '$route' () {
                this.getResource();
            }
        },
		mounted () {
            this.getResource();
        }
    };
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.resource-content ::v-deep {
		text-align: left;
		p {
			margin-bottom: 15px;
		}
		.view-resource {
			margin-bottom: 25px;
		}
	}
	.resource-video {
		height: 205px;
		position: relative;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $dark;
		margin-bottom: 25px;
		img.bg {
			border-radius: 12px;
			opacity: 0.5;
		}
        img.bg-image {
            border-radius: 12px;
        }
		svg {
			width: 60px;
		}
	}
	.resource-tags {
		margin-top: 10px;
		display: flex;
		.resource-tag {
			display: flex;
			align-items: center;
			margin-right: 25px;
			&:last-of-type {
				margin-right: 0;
			}
			.tag-icon {
			    width: 23px;
				overflow: visible;
				margin-right: 10px;
				.line {
					stroke: $mid;
					stroke-width: 3;
				}
				.dot {
					fill: $mid;
				}
			}
			span {
				font-size: 17px;
				line-height: 20px;
				font-weight: $weight_600;
				color: $mid;
			}
		}
	}
</style>
