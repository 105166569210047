<template>
	<div class="my-profile">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image grey"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="ProfileIcon"
				title="My Profile"
				colour="grey"
			/>
			<div class="profile-blocks section-gap">
				<router-link :to="{ name: 'MyProfilePersonalInformation' }" class="profile-block">
					<PersonalIcon style="width: 34px;" class="profile-block-img blue" />
					<div class="profile-block-info">
						<h1>Personal Information</h1>
						<span class="sub">Name, Age, Location etc</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'MyProfileProfessionalInformation' }" class="profile-block">
					<ProfessionalIcon style="width: 34px;" class="profile-block-img yellow" />
					<div class="profile-block-info">
						<h1>Professional Information</h1>
						<span class="sub">Rank, Vessel Type, Service Length etc</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'MyProfileNotificationPreferences' }" class="profile-block">
					<NotificationIcon style="width: 34px;" class="profile-block-img red" />
					<div class="profile-block-info">
						<h1>Notification Preferences</h1>
						<span class="sub">Control the notifications you receive</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'MyProfileLoginDetails' }" class="profile-block">
					<LoginIcon style="width: 34px;" class="profile-block-img cyan" />
					<div class="profile-block-info">
						<h1>Login Details</h1>
						<span class="sub">Email Address, Password etc</span>
					</div>
				</router-link>
				<router-link :to="{ name: 'MyProfileTechnicalSupport' }" class="profile-block">
					<TechnicalIcon style="width: 34px;" class="profile-block-img purple" />
					<div class="profile-block-info">
						<h1>Technical Support</h1>
						<span class="sub">Report a technical issue to our team</span>
					</div>
				</router-link>
                <a @click="deleteAccount" class="profile-block">
                    <CloseIcon style="width: 34px;" class="profile-block-img red" />
                    <div class="profile-block-info">
                        <h1>Delete Account</h1>
                    </div>
                </a>
			</div>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import PersonalIcon from '@/assets/personal-info.svg';
	import ProfessionalIcon from '@/assets/professional-info.svg';
	import NotificationIcon from '@/assets/notification.svg';
	import LoginIcon from '@/assets/login-details.svg';
	import TechnicalIcon from '@/assets/technical-support.svg';
    import CloseIcon from '@/assets/close-grey.svg';
    import UserService from '@/services/UserService';
	export default {
		name: 'MyProfile',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			PersonalIcon,
			ProfessionalIcon,
			NotificationIcon,
			LoginIcon,
			TechnicalIcon,
            CloseIcon
		},
		data () {
	        return {

	        };
	    },
	    methods: {
            deleteAccount () {
                if (confirm('Are you sure you want to delete your account?')) {
                    if (confirm('This will delete your account and all associated data permanently.')) {
                        UserService.deleteAccount().then(() => {
                            this.$router.push('/');
                        });
                    }
                }
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {

        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title {
		&.red {
			color: #C83254;
		}
	}
	.profile-blocks {
		margin-bottom: 100px;
		.profile-block {
			display: block;
			text-align: left;
			border-radius: 12px;
			border: 1px solid $snow;
			box-shadow: 5px 15px 15px 5px $snow;
			padding: 20px;
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			h1 {
				font-size: 17px;
				letter-spacing: -0.43px;
				line-height: 17px;
				color: $dark;
				margin-bottom: 5px;
			}
			.sub {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 14px;
				color: $slate;
				display: block;
			}
		}
		.profile-block-img {
			flex-shrink: 0;
			display: block;
			margin-right: 16px;
			&.blue {
				path, g {
					stroke: $blue_base;
					stroke-width: 2;
				}
			}
			&.yellow {
				path, g {
					stroke: $yellow_base;
					stroke-width: 2;
				}
			}
			&.red {
				path, g {
					stroke: $red_base;
					stroke-width: 2;
				}
			}
			&.cyan {
				path, g {
					stroke: $cyan_base;
					stroke-width: 2;
				}
			}
			&.purple {
				path, g {
					stroke: $purple_base;
					stroke-width: 2;
				}
			}
		}
	}
</style>
