<template>
    <nav @click="track()">
        <router-link :to="{ name: 'Dashboard' }" class="nav-item nav-item-home">
            <HomeIcon style="width: 22px;" class="nav-item-img" />
            <span class="nav-item-title">Home</span>
        </router-link>
        <router-link :to="{ name: 'Ports' }" class="nav-item nav-item-ports">
            <AnchorIcon style="width: 22px;" class="nav-item-img" />
            <span class="nav-item-title">Ports</span>
        </router-link>
        <router-link v-if="isPortAdmin" :to="{ name: 'Requests' }" class="nav-item nav-item-blue">
            <div class="icon-hold">
                <span v-if="showRedDot" class="red-dot"></span>
                <SendIcon style="width: 24px;" class="nav-item-img" />
            </div>
            <span class="nav-item-title">Requests</span>
        </router-link>
        <router-link v-else :to="{ name: 'CheckIn' }" class="nav-item nav-item-check-in">
            <CheckInIcon style="width: 24px;" class="nav-item-img" />
            <span class="nav-item-title">Check-in</span>
        </router-link>
        <a @click="openBrowser('https://www.seafarershappinessindex.org')" class="nav-item nav-item-requests">
            <ShipIcon style="width: 25px;" class="nav-item-img" />
            <span class="nav-item-title">SHI</span>
        </a>
        <router-link :to="{ name: 'Resources' }" class="nav-item nav-item-resources">
            <FolderIcon style="width: 23px;" class="nav-item-img" />
            <span class="nav-item-title">Well-being</span>
        </router-link>
    </nav>
</template>

<script>
import HomeIcon from '@/assets/home.svg';
import FolderIcon from '@/assets/folder.svg';
import AnchorIcon from '@/assets/anchor-black.svg';
import ShipIcon from '@/assets/ship.svg';
import CheckInIcon from '@/assets/check-in.svg';
import SendIcon from '@/assets/send.svg';
import UtilityService from '@/services/UtilityService';
import { Browser } from '@capacitor/browser';
export default {
    name: 'Nav',
    components: {
        HomeIcon,
        FolderIcon,
        AnchorIcon,
        ShipIcon,
        CheckInIcon,
        SendIcon
    },
    computed: {
        isMenuOpen () {
            return this.$store.state.isMenuOpen;
        },
        isPortAdmin () {
            return this.$store.getters.isPortAdmin;
        },
        showRedDot () {
            return this.$store.getters.showPortAdminRequestRedDot;
        }
    },
    methods: {
        toggle () {
            this.$store.commit('MENU_TOGGLE');
        },
        track () {
            if (this.$store.getters.isAuthenticated) {
                UtilityService.trackClick({
                    element: 'sticky-menu'
                });
            }
        },
        async openBrowser (url) {
            await Browser.open({
                url: url,
                presentationStyle: 'popover'
            });
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/scss/_global.scss";
    .icon-hold {
        .red-dot {
            position: absolute;
            top: -5px;
            right: 5px;
            width: 11px;
            height: 11px;
            border-radius: 100%;
            background: $red_base;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
            color: white;
            font-weight: 600;
        }
    }
    nav {
        padding: 0 28px;
        width: 100%;
        max-width: 680px;
        position: fixed;
        height: 80px;
        bottom: 0;
        z-index: 120;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $white;
        box-shadow: 0 0 250px rgba(0,0,0,.15);
        border-radius: 35px 35px 0 0;
        svg {
            display: block;
            overflow: visible;
            path {
                stroke: $grey;
                stroke-width: 3;
                &.fill {
                    stroke: none;
                    fill: $grey;
                }
            }
        }
        a {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 46px;
            justify-content: space-between;
            .nav-item-title {
                font-size: 11px;
                line-height: 18px;
                color: $slate;
                font-weight: $weight_400;
                opacity: 0.6;
                transition: $ease;
            }
            &.router-link-active {
                svg {
                    transition: $ease;
                    path {
                        transition: $ease;
                        stroke: $dark;
                        &.fill {
                            fill: $dark;
                        }
                    }
                }
                .nav-item-title {
                    color: $dark;
                    opacity: 1;
                }
                &.nav-item-ports {
                    svg path {
                        stroke: $blue_base;
                        &.fill {
                            fill: $blue_base;
                        }
                    }
                    .nav-item-title {
                        color: $blue_base;
                    }
                }
                &.nav-item-resources {
                    svg path {
                        stroke: $red_base;
                        &.fill {
                            fill: $red_base;
                        }
                    }
                    .nav-item-title {
                        color: $red_base;
                    }
                }
                &.nav-item-requests {
                    svg path {
                        stroke: $green_base;
                        &.fill {
                            fill: $green_base;
                        }
                    }
                    .nav-item-title {
                        color: $green_base;
                    }
                }
                &.nav-item-blue {
                    svg path {
                        stroke: $green_base;
                        &.fill {
                            fill: $green_base;
                        }
                    }
                    .nav-item-title {
                        color: $green_base;
                    }
                }
                &.nav-item-check-in {
                    svg path {
                        stroke: $orange_base;
                        &.fill {
                            fill: $orange_base;
                        }
                    }
                    .nav-item-title {
                        color: $orange_base;
                    }
                }
            }
        }
        .nav-item {
            position: relative;
        }
    }

</style>
