<template>
	<div class="port-edit-response-details">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="ChatBubbleIcon"
				subtitle="Edit Port"
				title="Response Times"
				colour="profile profile-blue"
			/>
			<form class="form" @submit.prevent="">
				<div class="field">
                    <label class="label">Request Types</label>
                    <div class="control">
                        <div v-for="type in requestTypes" :key="type.value" class="checkbox">
                            <label>
                                <input type="checkbox" v-model="type.selected" :value="type.value">
                                {{ type.displayName }}
                            </label>
                        </div>
                    </div>
                </div>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Please make sure all fields are filled in.</span>
				</div>
				<div class="flex-bottom">
					<Button @click.native="updatePortAllowedRequests" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Update Accepted Requests"></Button>
				</div><!-- flex bottom -->
			</form>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Button from '@/components/Button.vue';
	import { required } from 'vuelidate/lib/validators';
	import Nav from '@/components/Nav.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import UserService from '@/services/UserService';
	import PortService from '@/services/PortService';

    export default {
		name: 'MyProfile',
		components: {
			Button,
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle
		},
		data () {
	        return {
	        	form: {
					
				},
				errorMessage: false,
				errorStyling: false,
				port_id: null,
                enabledPortRequestTypes: [],
                requestTypes: [
                    { value: 'shopping', displayName: 'Shopping', selected: false },
                    { value: 'transport', displayName: 'Transport', selected: false },
                    { value: 'visit', displayName: 'Visit', selected: false },
                    { value: 'contact', displayName: 'Contact', selected: false },
                    { value: 'justice_welfare', displayName: 'Justice/Welfare', selected: false },
                    { value: 'other', displayName: 'Other', selected: false }
                ]
	        };
	    },
		validations () {
			return {
				form: {
					response_time_shopping: { required },
					response_time: { required }
		       	}
			};
		},
	    methods: {
            updatePortAllowedRequests () {
                try {
                    PortService.updatePortAcceptedRequests(this.port_id, {
                        acceptedTypes: this.requestTypes
                    });
                } catch (error) {
                    console.log(error);
                    this.errorMessage = true;
                    this.errorStyling = true;
                }
            },
            getPortAllowedRequests () {
                PortService.getEnabledPortRequestTypes(this.$route.params.id).then((response) => {
                    const enabledTypes = Object.values(response.data); // Convert object values to array
                    this.requestTypes = this.requestTypes.map(type => {
                        return {
                            ...type,
                            selected: enabledTypes.includes(type.value)
                        };
                    });
                    this.port_id = this.$route.params.id;
                });
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getPortAllowedRequests();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.message-text {
		background: $sky_light;
		margin-top: 20px;
	    padding: 18px 17px 18px;
	    border-radius: 12px;
	    color: #445d72;
	    text-align: left;
	    p {
	    	color: $slate;
	    	font-size: 17px;
	    }
		.sub {
			font-weight: 600;
		}
		.text {

		}
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
</style>
