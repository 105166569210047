<template>
	<div class="home container container-full-height min">
		<span class="overlay" :class="{ active: showResetPasswordModal }"></span>
		<span class="gradient"></span>
		<div class="content-container">
			<div class="welcome-text">
				<img class="mission-logo" alt="The Mission to Seafarers" src="../assets/tmts.png" />
				<img class="happy-logo" alt="The Mission to Seafarers" src="../assets/happy-at-sea-logo.png" />
				<p>Free Port Welfare and Wellbeing Services from The Mission to Seafarers</p>
			</div><!-- welcome text -->
		</div><!-- content container -->
        <!-- Forgot password modal -->
        <div ref="modalResetPassword" class="phone-modal modal-email" :style="{ bottom: matchHeightModalResetPassword + 'px' }" :class="{ active: showResetPasswordModal }">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <KeyIcon style="width: 31px; height: 31px;" class="icon email-icon" />
                    <span>Reset Password</span>
                </div><!-- icon wrap -->
            </div><!-- modal title bar -->
            <form style="padding-top: 10px;" class="form" @submit.prevent>
                <div class="field field-gap field-icon" :class="{ 'field-error':$v.resetForm.password.$error || errorStyling == true }">
                    <label for="password-input" class="label">Password</label>
                    <div class="control">
                        <input id="password-input" class="input" type="password" placeholder="Enter your password" v-model="resetForm.password" enterkeyhint="next" @keyup.enter="$refs.confirm_password.focus()" @keydown.enter.prevent>
                        <KeyIcon style="width: 20px; height: 21px;" class="icon icon-key" />
                    </div>
                </div>
                <div class="field field-gap field-icon" :class="{ 'field-error':$v.resetForm.password_confirmation.$error || errorStyling == true }">
                    <label for="password-input" class="label">Confirm Password</label>
                    <div class="control">
                        <input id="password-input" ref="confirm_password" class="input" type="password" placeholder="Confirm your password" v-model="resetForm.password_confirmation" enterkeyhint="done" @keyup.enter="resetPassword">
                        <KeyIcon style="width: 20px; height: 21px;" class="icon icon-key" />
                    </div>
                </div>
                <div class="field error" v-if="errorMessage">
                    <span class="field-error-message">{{ errorMessage }}</span>
                </div>
                <Button v-if="resetPasswordButton" icon="ArrowRightIcon" class="btn-med btn-green btn-icon btn-icon-right btn-arrow" text="Reset Password" @click.native="resetPassword"></Button>
            </form>
            <router-link to="/" class="text-link text-link-grey">Cancel &raquo;</router-link>
        </div><!-- modal -->
        <!-- /Forgot password modal -->
	</div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import KeyIcon from '@/assets/key.svg';
import Button from '@/components/Button.vue';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

export default {
	name: 'ResetPassword',
	components: {
    	Button,
    	KeyIcon
	},
	data () {
		return {
            resetForm: {
                password: '',
                password_confirmation: ''
            },
			errorMessage: null,
			blankPassword: null,
			errorStyling: null,
            showResetPasswordModal: true,
            modalResetPasswordHeight: null,
            resetPasswordButton: true
		};
	},
	validations: {
        resetForm: {
            password: {
                required
            },
            password_confirmation: {
                required
            }
        }
	},
	computed: {
        matchHeightModalResetPassword () {
            if (this.$refs.modalResetPassword) {
                return '-' + this.$refs.modalResetPassword.clientHeight;
            }
            return 0;
        }
	},
    methods: {
        resetPassword () {
            this.errorStyling = false;
            this.resetPasswordButton = false;
            this.$v.resetForm.$touch();
            if (!this.$v.resetForm.$error) {
                AuthService.resetPassword({
                    token: this.$route.query.token,
                    email: this.$route.query.email,
                    password: this.resetForm.password,
                    password_confirmation: this.resetForm.password_confirmation
                }).then((response) => {
                    if (response.data.status) {
                        alert(response.data.status);
                        this.$router.push('/');
                    }
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.errorMessage = error.response.data.message;
                    }
                    if (error.response.data.email) {
                        this.errorMessage = error.response.data.email;
                    }
                    this.errorStyling = true;
                }).finally(() => {
                    this.resetPasswordButton = true;
                });
            } else {
                if (!this.$v.resetForm.password.$error || !this.$v.resetForm.password_confirmation.$error) {
                    this.errorStyling = true;
                }
                this.resetPasswordButton = true;
            }
        }
    },
	mounted () {
        this.modalResetPasswordHeight = this.matchHeightModalResetPassword;
        if (this.$store.getters.isAuthenticated) {
            UserService.getUser().then((response) => {
                if (response.status === 200) {
                    this.$router.push({ name: 'Dashboard' });
                }
            });
        }
        if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
            Keyboard.setAccessoryBarVisible({
                isVisible: true
            });
            Keyboard.setResizeMode({
                mode: KeyboardResize.None
            });
            Keyboard.addListener('keyboardWillShow', (info) => {
                this.$refs.modalResetPassword.style.marginBottom = `${info.keyboardHeight}px`;
            });
            Keyboard.addListener('keyboardWillHide', () => {
                this.$refs.modalResetPassword.style.marginBottom = '0';
            });
        }
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_global.scss";
.gradient {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 30%;
    background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,145,255,1) 100%);
}
.welcome-text {
	position: relative;
	max-width: 320px;
	margin: 0 auto;
	padding-top: 40px;
	width: 100%;
	span.sub {
		font-size: 17px;
		font-weight: 600;
		color: #0A203D;
		display: block;
		margin-bottom: 18px;
	}
	.mission-logo {
		width: 100px;
    	display: block;
    	margin: 0 auto 35px;
	}
	.happy-logo {
		width: 200px;
		margin-bottom: 25px
	}
	h1 {
		margin: 0 0 10px;
		color: $white;
		font-size: 26px;
		line-height: 31px;
	}
	p {
		margin: 0;
		color: #0A203D;
		font-weight: 400;
		font-size: 17px;
	}
}
.content-wrap {
	position: relative;
	border-radius: 35px 35px 0 0;
	padding: 40px 28px 28px 28px;
	background: $white;
	width: 100%;
	transition: 0.5s ease;
	z-index: 100;
	flex: auto;
}
.container {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.container-full-height {
	height: 100vh;
	&.min {
		min-height: 100vh;
		height: auto;
	}
}
.content-container {
	display: flex;
	flex: auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px;
}
.welcome-funnels {
	position: relative;
	margin-top: 50px;
	.seafarers-img {
		position: relative;
	    width: 180px;
	    height: auto;
	    top: 30px;
	    z-index: 0;
	}
	.button-wrap {
		z-index: 5;
		position: relative;
	}
	.text {
		margin-bottom: 30px;
	}
	p {
		color: $white;
		margin: 0;
		strong {
			color: $white;
		}
	}
}
</style>
