<template>
	<div class="port-edit-response-details">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="ChatBubbleIcon"
				subtitle="Edit Port"
				title="Response Times"
				colour="profile profile-blue"
			/>
			<form class="form" @submit.prevent="">
				<b-field label-for="timezone-input" label="Shopping (hours)">
		             <b-numberinput
		             	expanded
		             	v-model="form.response_time_shopping"
			            step="24"
			            min="24"
			            max="120"
			            aria-minus-label="Decrement by 24"
			            aria-plus-label="Increment by 24">
			        </b-numberinput>
		        </b-field>
		        <!-- <b-field label-for="timezone-input" label="Transport (hours)">
		             <b-numberinput
		             	expanded
		             	v-model="form.transport"
			            step="24"
			            min="24"
			            max="120"
			            aria-minus-label="Decrement by 24"
			            aria-plus-label="Increment by 24">
			        </b-numberinput>
		        </b-field>
		        <b-field label-for="timezone-input" label="Ship visit (hours)">
		             <b-numberinput
		             	expanded
		             	v-model="form.shipVisit"
			            step="24"
			            min="24"
			            max="120"
			            aria-minus-label="Decrement by 24"
			            aria-plus-label="Increment by 24">
			        </b-numberinput>
		        </b-field> -->
		        <b-field label-for="timezone-input" label="General (hours)">
		             <b-numberinput
		             	expanded
		             	v-model="form.response_time"
			            step="24"
			            min="24"
			            max="120"
			            aria-minus-label="Decrement by 24"
			            aria-plus-label="Increment by 24">
			        </b-numberinput>
		        </b-field>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Please make sure all fields are filled in.</span>
				</div>
				<div class="flex-bottom">
					<Button @click.native="updatePortResponseTimes" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Save changes"></Button>
				</div><!-- flex bottom -->
				<div class="message-text">
					<p class="sub">Looking to change opening times?</p>
					<p class="text">You can change opening times in the Contact Details section.</p>
				</div>
			</form>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Button from '@/components/Button.vue';
	import { required } from 'vuelidate/lib/validators';
	import Nav from '@/components/Nav.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import UserService from '@/services/UserService';
	import PortService from '@/services/PortService';
	export default {
		name: 'MyProfile',
		components: {
			Button,
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle
		},
		data () {
	        return {
	        	form: {
					response_time_shopping: null,
					// transport: null,
					// shipVisit: null,
					response_time: null
				},
				errorMessage: false,
				errorStyling: false,
				port_id: null
	        };
	    },
		validations () {
			return {
				form: {
					response_time_shopping: { required },
					response_time: { required }
		       	}
			};
		},
	    methods: {
            updatePortResponseTimes () {
                var self = this;
                this.$v.form.$touch();
                if (!this.$v.form.$error) {
                    try {
                        PortService.updatePortResponseTimes(this.port_id, {
							response_time_shopping: this.form.response_time_shopping,
							response_time: this.form.response_time
						}).then(() => {
							this.$store.commit('UPDATE_PROFILE_TOGGLE');
						});
                    } catch (error) {
                        console.log(error);
                        this.errorMessage = true;
                        this.errorStyling = true;
                    }
	            }
            },
			getPortResponseTimes () {
				return PortService.getPortResponseTimes(this.$route.params.id).then((response) => {
					this.form = response.data;
					this.port_id = this.$route.params.id;
				});
			}
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
			this.getPortResponseTimes();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.message-text {
		background: $sky_light;
		margin-top: 20px;
	    padding: 18px 17px 18px;
	    border-radius: 12px;
	    color: #445d72;
	    text-align: left;
	    p {
	    	color: $slate;
	    	font-size: 17px;
	    }
		.sub {
			font-weight: 600;
		}
		.text {

		}
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
</style>
