<template>
<div ref="modalPortAdminConfirmation" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortAdminConfirmationModalOpen ? 1 : 0, bottom: modalPortAdminConfirmationHeight + 'px'}" :class="{active: isPortAdminConfirmationModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<ProfileIcon style="width: 30px; height: auto;" class="icon" />
			<span>Create a Port Admin</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortAdminModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="modal-message-block">
		<SuccessIcon class="success-icon" style="width: 90px; height: 90px; margin-bottom: 0;" />
	</div>
	<div class="section-gap">
		<p class="dark">A request for new port admin has been successfully sent. You’ll hear back about this request in 2-3 working days</p>
	</div>
	<div class="flex-bottom">
		<Button routeName="/dashboard" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-snow btn-square btn-icon btn-icon-right" text="Return home"></Button>
	</div><!-- flex bottom -->
</div><!-- modal -->
</template>

<script>
import GreyCloseIcon from '@/assets/close-grey.svg';
import ProfileIcon from '@/assets/profile-plus.svg';
import SuccessIcon from '@/assets/success-filled.svg';
import Button from '@/components/Button.vue';
export default {
name: 'PortContactOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			modalPortAdminConfirmationHeight: null
		};
	},
	components: {
		GreyCloseIcon,
		ProfileIcon,
		SuccessIcon,
		Button
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortAdminConfirmation.clientHeight;
		},
        isPortAdminConfirmationModalOpen () {
            return this.$store.state.isPortAdminConfirmationModalOpen;
        }
	},
	methods: {
        closePortAdminModal () {
        	this.$store.commit('PORT_ADMIN_CONFIRMATION_TOGGLE');
			this.$store.commit('PORT_ADMIN_TOGGLE');
        }
    },
    mounted () {
    	this.modalPortAdminConfirmationHeight = '-500';
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-admin-details {
		text-align: left;
		.col {
			margin-bottom: 20px;
		}
		.p-strong {
			color: $dark;
			font-weight: 500;
		}
	}
	p.dark {
		text-align: left;
		color: $dark;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $dark;
				}
				.p {
					stroke-width: 0;
				}
			}
			span {
				color: $dark;
			}
		}
		.close {
			g, path {
				stroke-width: 2.5;
				stroke: $dark;
			}
		}
	}
	.modal-message-block {
		svg path {
			fill: $green_base;
		}
	}
</style>
