<template>
<div ref="modalPortAdminRequestSubmitted" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortAdminRequestSubmittedModalOpen ? 1 : 0, bottom: modalPortAdminRequestSubmittedHeight + 'px'}" :class="{active: isPortAdminRequestSubmittedModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<SendIcon style="width: 27px; height: auto;" class="icon checkin-icon" />
			<span>{{ title }}</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortAdminModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="modal-message-block alt">
		<SuccessIcon class="success-icon" style="width: 90px; height: 90px; margin-bottom: 0;" />
	</div>
	<div class="section-gap">
		<p class="dark">{{ message }}</p>
	</div>
	<div class="flex-bottom">
		<Button v-if="additionalData.status === 'delete'" routeName="/requests" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-snow btn-square btn-icon btn-icon-right" text="Return to Requests"></Button>
		<Button v-else routeName="/dashboard" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-snow btn-square btn-icon btn-icon-right" text="Return home"></Button>
	</div><!-- flex bottom -->
</div><!-- modal -->
</template>

<script>
import GreyCloseIcon from '@/assets/close-grey.svg';
import SendIcon from '@/assets/send.svg';
import SuccessIcon from '@/assets/success-filled.svg';
import Button from '@/components/Button.vue';
export default {
name: 'PortContactOverlay',
	props: {
		subtitle: String,
		title: String,
		message: String,
		icon: String
	},
	data () {
		return {
			modalPortAdminRequestSubmittedHeight: null
		};
	},
	components: {
		GreyCloseIcon,
		SendIcon,
		SuccessIcon,
		Button
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortAdminRequestSubmitted.clientHeight;
		},
		additionalData () {
	        return this.$store.state.additionalDataAlt || {};
	    },
        isPortAdminRequestSubmittedModalOpen () {
            return this.$store.state.isPortAdminRequestSubmittedModalOpen;
        }
	},
	methods: {
        closePortAdminModal () {
        	this.$store.commit('TOGGLE_PORT_ADMIN_REQUEST_SUBMITTED_MODAL', {
            	isOpen: false,
            	title: '',
            	message: '',
            	additionalData: null
        	});
        }
    },
    mounted () {
    	this.modalPortAdminRequestSubmittedHeight = '-500';
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-admin-details {
		text-align: left;
		.col {
			margin-bottom: 20px;
		}
		.p-strong {
			color: $dark;
			font-weight: 500;
		}
	}
	p.dark {
		text-align: left;
		color: $dark;
	}
	.modal-message-block {
		&.alt {
			margin-top: 35px;
		}
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $dark;
				}
				.p {
					stroke-width: 0;
				}
			}
			span {
				color: $dark;
			}
		}
		.close {
			g, path {
				stroke-width: 2.5;
				stroke: $dark;
			}
		}
	}
	.modal-message-block {
		svg path {
			fill: $green_base;
		}
	}
</style>
