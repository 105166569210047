<template>
	<div class="port-edit-contact-details">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="ContactIcon"
				subtitle="Edit Port"
				title="Contact Details"
				colour="profile profile-blue"
			/>
			<form class="form" @submit.prevent="">
				<div class="field" :class="{ 'field-error': $v.form.telephone.$error || errorStyling == true }">
					<label for="telephone-input" class="label">Telephone</label>
					<div class="control">
						<input id="telephone-input" class="input" type="text" v-model="form.telephone">
					</div>
				</div>
				<div class="field" :class="{ 'field-error': $v.form.openingTimes.$error || errorStyling == true }">
					<label for="opening-times-input" class="label">Opening Times</label>
					<div class="control">
						<input id="opening-times-input" class="input" type="text" v-model="form.openingTimes">
					</div>
				</div>
				<b-field label-for="timezone-input" label="Primary Port Admin" :class="{ 'field-error': $v.form.port_admin_id.$error || errorStyling == true }">
		            <b-select id="timezone-input" v-model="form.port_admin_id">
						<option v-for="portAdmin in portAdmins" :key="portAdmin.id" :value="portAdmin.id">{{ portAdmin.name }}</option>
		            </b-select>
		        </b-field>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Please make sure all fields are filled in.</span>
				</div>
				<div class="flex-bottom">
					<Button @click.native="updateContactDetails" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Save changes"></Button>
				</div><!-- flex bottom -->
			</form>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Button from '@/components/Button.vue';
	import { required } from 'vuelidate/lib/validators';
	import Nav from '@/components/Nav.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import UserService from '@/services/UserService';
	import PortService from '@/services/PortService';
	export default {
		name: 'MyProfile',
		components: {
			Button,
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle
		},
		data () {
	        return {
	        	form: {
					telephone: null,
					openingTimes: null,
					port_admin_id: null
				},
				errorMessage: false,
				errorStyling: false,
				port_id: null,
				portAdmins: []
	        };
	    },
	    validations () {
	    	return {
	    		form: {
	            	telephone: { required },
	            	openingTimes: { required },
	            	port_admin_id: { required }
	           	}
	    	};
	    },
	    methods: {
            updateContactDetails () {
                var self = this;
                this.$v.form.$touch();
                if (!this.$v.form.$error) {
                    try {
                        PortService.updatePortContactDetails(this.port_id, {
							telephone: this.form.telephone,
							opening_times: this.form.openingTimes,
							port_admin_id: this.form.port_admin_id
						}).then(() => {
							this.$store.commit('UPDATE_PROFILE_TOGGLE');
						});
                    } catch (error) {
                        console.log(error);
                        this.errorMessage = true;
                        this.errorStyling = true;
                    }
	            }
            },
			getPortContactDetails () {
				PortService.getPortContactDetails(this.$route.params.id).then((response) => {
					this.form.telephone = response.data.telephone;
					this.form.openingTimes = response.data.opening_times;
					this.form.port_admin_id = response.data.port_admin_id;
					this.portAdmins = response.data.port_admins;
					this.port_id = response.data.id;
				});
			}
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
			this.getPortContactDetails();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
</style>
