<template>
<div ref="modalPortAdminNew" class="phone-modal phone-modal-max-height phone-modal-port-search with-nav" :style="{ opacity: isPortAdminNewModalOpen ? 1 : 0}" :class="{active: isPortAdminNewModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<ProfileIcon style="width: 30px; height: auto;" class="icon" />
			<span>Create a Port Admin</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortAdminNewModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="section-gap port-admin-new">
		<form class="form" @submit.prevent="">
			<div class="field" :class="{ 'field-error': $v.form.name.$error || errorStyling == true }">
				<label for="name-input" class="label">Name</label>
				<div class="control">
					<input id="name-input" class="input" type="text" v-model="form.name">
				</div>
			</div>
			<div class="field" :class="{ 'field-error': $v.form.email.$error || errorStyling == true }">
				<label for="email-input" class="label">Email</label>
				<div class="control">
					<input id="email-input" class="input" type="text" v-model="form.email">
				</div>
			</div>
			<b-field label-for="timezone-input" label="Port">
				<b-select id="port-select">
					<option :key="null">{{ port_name }}</option>
				</b-select>
	        </b-field>
	        <div class="field" :class="{ 'field-error': $v.form.password.$error || errorStyling == true }">
				<label for="password-input" class="label">Password</label>
				<input id="password-input" ref="password_input" class="input" type="password" v-model="form.password" enterkeyhint="next" @keydown.enter.prevent>
			</div>
			<div class="field" :class="{ 'field-error': $v.form.passwordConfirm.$error || errorStyling == true }">
				<label for="password-repeat-input" class="label">Confirm Password</label>
				<input id="password-repeat-input" ref="password_input" class="input" type="password" v-model="form.passwordConfirm" enterkeyhint="next" @keydown.enter.prevent>
			</div>
			<div class="flex-bottom">
				<Button @click.native="addPortAdmin" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Request admin"></Button>
			</div><!-- flex bottom -->
		</form>
	</div>
</div><!-- modal -->
</template>

<script>
import GreyCloseIcon from '@/assets/close-grey.svg';
import ProfileIcon from '@/assets/profile-plus.svg';
import Button from '@/components/Button.vue';
import { required, sameAs, email } from 'vuelidate/lib/validators';
import PortService from '@/services/PortService';
export default {
name: 'PortContactOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			modalPortAdminNewHeight: null,
			form: {
				name: null,
				email: null,
				port: null,
				password: null,
				passwordConfirm: null
			},
			errorMessage: false,
			errorStyling: false,
			port_name: null
		};
	},
	validations () {
    	return {
    		form: {
            	name: {
            		required
            	},
            	email: {
	                required,
	                email
	            },
	            password: {
					required
				},
				passwordConfirm: {
					required,
					sameAs: sameAs(() => this.form.password)
				}
           	}
    	};
    },
	components: {
		GreyCloseIcon,
		ProfileIcon,
		Button
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortAdminNew.clientHeight;
		},
        isPortAdminNewModalOpen () {
            return this.$store.state.isPortAdminNewModalOpen;
        }
	},
	methods: {
        closePortAdminNewModal () {
        	this.$store.commit('PORT_ADMIN_NEW_TOGGLE');
        },
        addPortAdmin () {
            var self = this;
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {
                    PortService.requestPortAdmin({
						name: this.form.name,
						email: this.form.email,
						port_id: this.$route.params.id,
						password: this.form.password,
						password_confirmation: this.form.passwordConfirm
					}).then(() => {
						this.$store.commit('PORT_ADMIN_NEW_TOGGLE');
						this.$store.commit('PORT_ADMIN_CONFIRMATION_TOGGLE');
					});
                } catch (error) {
                    console.log(error);
                    this.errorMessage = true;
                    this.errorStyling = true;
                }
            }
        },
		getPorts () {
			PortService.getPort(this.$route.params.id).then((response) => {
				this.port_name = response.data.name;
			});
		}
    },
    mounted () {
		this.modalPortAdminNewHeight = '-500';
		this.getPorts();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.phone-modal-max-height {
		pointer-events: none;
		overflow: scroll;
		&.active {
			pointer-events: auto;
			opacity: 1;
    		top: 120px;
		}
	}
	.port-admin-details {
		text-align: left;
		.col {
			margin-bottom: 20px;
		}
		.p-strong {
			color: $dark;
			font-weight: 500;
		}
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
		.field .field-error-message {
			position: relative;
		}
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $dark;
				}
				.p {
					stroke-width: 0;
				}
			}
			span {
				color: $dark;
			}
		}
		.close {
			g, path {
				stroke-width: 2.5;
				stroke: $dark;
			}
		}
	}
</style>
